import React, { Fragment, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import authAxios from "../../services/Token";
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import DatePicker from "react-datepicker";
import ApexCharts from 'react-apexcharts';
import Knob from "knob";
import ChartistChart from 'react-chartist';
import { smallchart1data, smallchart1option, smallchart2data, smallchart2option, smallchart3data, smallchart3option, smallchart4data, smallchart4option } from './chartsData/chartist-charts-data';
import { Currentlysale, Marketvalue } from './chartsData/apex-charts-data';
import { Send, Clock } from 'react-feather';
import { Dashboard, Summary, NewsUpdate, Appointment, Notification, MarketValue, Chat, New, Tomorrow, Yesterday, Daily, Weekly, Monthly, Store, Online, ReferralEarning, CashBalance, SalesForcasting, Purchase, Sales, SalesReturn, PurchaseRet, PurchaseOrderValue, ProductOrderValue, Pending, Yearly, Hot, Today, VenterLoren, Done, JohnLoren, Year, Month, Day, RightNow } from '../../constant';


const Default = () => {

  const [daytimes, setDayTimes] = useState()
  const today = new Date()
  const curHr = today.getHours()
  const curMi = today.getMinutes()
  const [meridiem, setMeridiem] = useState("AM")
  const startDate = new Date();
  const handleChange = date => {
    new Date() 
  };

  const [Series, setSerie] = React.useState([]);
  const [Total, setTotal] = React.useState([]);
  const [Stotal, setStotal] = React.useState(0);
  const [Servicios, setServicio] = React.useState(0);
  const [Vehiculos, setVehiculos] = React.useState(0);
  const [Conductores, setConductores] = React.useState(0);
  const [Clientes, setClientes] = React.useState(0);
  const [Contratos, setContratos] = React.useState(0);
  const [Activos, setActivos] = React.useState(0);
  const [Proximos, setProximos] = React.useState(0);
  const [Vencidos, setVencidos] = React.useState(0);

  const [Activos2, setActivos2] = React.useState(0);
  const [Proximos2, setProximos2] = React.useState(0);
  const [Vencidos2, setVencidos2] = React.useState(0);

  const [Activos3, setActivos3] = React.useState(0);
  const [Proximos3, setProximos3] = React.useState(0);
  const [Vencidos3, setVencidos3] = React.useState(0);

  const [Activos4, setActivos4] = React.useState(0);
  const [Proximos4, setProximos4] = React.useState(0);
  const [Vencidos4, setVencidos4] = React.useState(0);
  const ListaTabla = async () => {
    let user = localStorage.getItem('idusuario');
    authAxios.get(`dashboard/index_cliente/`+user)
        .then((datosRespuesta) => {
           
        var f =datosRespuesta.data
            var status = datosRespuesta.data.status;
            
            setSerie([{
              name: '2022',
              data: [f.m1,f.m2,f.m3,f.m4,f.m5,f.m6,f.m7,f.m8,f.m9,f.m10,f.m11, f.m12, 0]
            }]);
            setTotal(f.total)
            setStotal(f.stotal)
            setServicio(f.serv_activo)
            setVehiculos(f.veh_activio)
            setConductores(f.cond_activo)
            setClientes(f.clientes)
            setContratos(f.contratos)
            setActivos(f.doc_activo)
            setProximos(f.prox_vencer)
            setVencidos(f.doc_vencidos)

            setActivos2(f.doc_activo2)
            setProximos2(f.prox_vencer2)
            setVencidos2(f.doc_vencidos2)
            
            setActivos3(f.doc_activo3)
            setProximos3(f.prox_vencer3)
            setVencidos3(f.doc_vencidos3)

            setActivos4(f.doc_activo4)
            setProximos4(f.prox_vencer4)
            setVencidos4(f.doc_vencidos4)

             })
        .catch();
 }
  React.useEffect(() => {
          ListaTabla(); 
       },[]);

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Pagina Principal" />
      <Container fluid={true}>
        <Row className="second-chart-list third-news-update">
          <Col xl="3 xl-50" lg="12" className="morning-sec box-col-12">
            <Card className="o-hidden profile-greeting">
              <CardBody>
                <div className="media">
                  <div className="badge-groups w-100">
                    <div className="badge f-12">
                      <Clock style={{ width: "16px", height: "16px" }} className="me-1" />
                      <span id="txt" className='bg-success'>{curHr}:{curMi < 10 ? "0" + curMi : curMi} </span>
                    </div>
                    <div className="badge f-12"><i className="fa fa-spin fa-cog f-14"></i></div>
                  </div>
                </div>
                <div className="greeting-user text-center">
                  <div className="profile-vector"><img className="img-fluid" src={require("../../assets/images/dashboard/logo.png")} alt="" /></div>
                  <h4 className="f-w-600"><span id="greeting">{daytimes}</span> <span className="right-circle"><i className="fa fa-check-circle f-14 middle"></i></span></h4>
               
                  <div className="whatsnew-btn"><a className="btn btn-primary" href="https://transorientesas.com/" target={'blank'}>Visita la pagina cliente</a></div>
                
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="9 xl-100" className="dashboard-sec box-col-12">
            <Card className="earning-card">
              <CardBody className="p-0">
                <Row className="m-0">
                  <Col xl="3" className="earning-content p-0">
                    <Row className="m-0 chart-left">
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>Servicios</h5>
                        <p className="font-roboto"><div className="media-left"><i className="icofont icofont-car"></i>{Servicios} </div> </p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>En Proceso</h5>
                        <p className="font-roboto"><div className="media-left"><i className="icofont icofont-car"></i>{0} </div> </p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>Anulados</h5>
                        <p className="font-roboto"><div className="media-left"><i className="icofont icofont-car"></i>{0} </div> </p>
                      </Col>
                      <Col xl="12" className="p-0 left_side_earning">
                        <h5>Completados</h5>
                        <p className="font-roboto"><div className="media-left"><i className="icofont icofont-car"></i>{0} </div> </p>
                      </Col>
                      <Col xl="12" className="p-0 left-btn"><a className="btn btn-gradient" href="/pages/14/Dubai">Ver mas</a></Col>
                    </Row>
                  </Col>
                  <Col xl="4 xl-50" className="appointment-sec box-col-6">
            <Row>
              <Col xl="12" className="appointment">
                <Card>
                  <CardHeader className="card-no-border">
                    <div className="header-top">
                      <h5 className="m-0"> Elementos de Control</h5>
                      
                    </div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <div className="appointment-table table-responsive">
                      <table className="table table-bordernone">
                        <tbody>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/soat.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">SOAT</span><span className="font-roboto">Soat Activos</span></td>
                           
                            <td className="text-end">
                              <div className="button btn btn-primary">{Activos}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>
                          </tr>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/soat.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">SOAT</span><span className="font-roboto">Soat Proximos a vencer</span></td>
                            
                            <td className="text-end">
                              <div className="button btn btn-warning">{Proximos}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>
                            
                          </tr>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/soat.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">SOAT</span><span className="font-roboto">Soat Vencidos</span></td>
                           
                            <td className="text-end">
                              <div className="button btn btn-danger">{Vencidos}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>
                            
                          </tr>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/tecno.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">TECNOMECANICA</span><span className="font-roboto">TECNOMECANICA Activos</span></td>
                           
                            <td className="text-end">
                              <div className="button btn btn-primary">{Activos2}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>
                          </tr>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/tecno.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">TECNOMECANICA</span><span className="font-roboto">TECNOMECANICA Proximos a vencer</span></td>
                            
                            <td className="text-end">
                              <div className="button btn btn-warning">{Proximos2}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>
                            
                          </tr>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/tecno.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">TECNOMECANICA</span><span className="font-roboto">TECNOMECANICA Vencidos</span></td>
                           
                            <td className="text-end">
                              <div className="button btn btn-danger">{Vencidos2}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>
                            
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
             
            </Row>
          </Col>
          <Col xl="4 xl-50" className="notification box-col-6">
            <Card>
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <h5 className="m-0">Impuestos</h5>
                  <div className="card-header-right-icon">
                    <select className="button btn btn-primary">
                      <option>{Today}</option>
                      <option>{Tomorrow}</option>
                      <option>{Yesterday}</option>
                    </select>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
              <div className="appointment-table table-responsive">
                      <table className="table table-bordernone">
                        <tbody>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/seguro.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">SEGURO TODO RIESGO</span><span className="font-roboto">Seguros Activos</span></td>
                           
                            <td className="text-end">
                              <div className="button btn btn-primary">{Activos3}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>
                          </tr>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/seguro.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">SEGURO TODO RIESGO</span><span className="font-roboto">Seguros Proximos a vencer</span></td>
                            
                            <td className="text-end">
                              <div className="button btn btn-warning">{Proximos3}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>
                            
                          </tr>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/seguro.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">SEGURO TODO RIESGO</span><span className="font-roboto">Seguros Vencidos</span></td>
                           
                            <td className="text-end">
                              <div className="button btn btn-danger">{Vencidos3}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>
                            
                          </tr>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/impuesto.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">IMPUESTOS</span><span className="font-roboto">Impuestos Activos</span></td>
                           
                            <td className="text-end">
                              <div className="button btn btn-primary">{Activos4}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>
                          </tr>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/impuesto.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">IMPUESTOS</span><span className="font-roboto">Impuestos Proximos a vencer</span></td>
                            
                            <td className="text-end">
                              <div className="button btn btn-warning">{Proximos4}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>
                            
                          </tr>
                          <tr>
                            <td><img className="img-fluid img-40 rounded-circle mb-3" src={require("../../assets/images/appointment/impuesto.png")} alt="" />
                              <div className="status-circle bg-primary"></div>
                            </td>
                            <td className="img-content-box"><span className="d-block">IMPUESTOS</span><span className="font-roboto">Impuestos Vencidos</span></td>
                           
                            <td className="text-end">
                              <div className="button btn btn-danger">{Vencidos4}<i className="fa fa-check-circle ms-2"></i></div>
                            </td>
                            
                          </tr>
                        </tbody>
                      </table>
                    </div>
              </CardBody>
            </Card>
          </Col>
                  
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl="12 xl-100" className="chart_data_left box-col-12">
            <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{Vehiculos}</h4><span>Vehiculos</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart1 flot-chart-container"
                            data={smallchart2data}
                            options={smallchart2option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{Conductores}</h4><span>Conductores</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart2 flot-chart-container"
                            data={smallchart3data}
                            options={smallchart3option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{Clientes}</h4><span>Clientes</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="3" md="6" sm="6" className="p-0 box-col-6">
                    <div className="media border-none align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart3 flot-chart-container"
                            data={smallchart4data}
                            options={smallchart4option}
                            type={'Bar'}
                            listener={{
                              'draw': function (data) {
                                if (data.type === 'bar') {
                                  data.element.attr({
                                    style: 'stroke-width: 3px'
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{Contratos}</h4><span>Contratos</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
               
              </CardBody>
            </Card>
          </Col>
          
        </Row>
      </Container>
    </Fragment>
  );
}

export default Default;