import React from 'react';
import Swal from 'sweetalert2';
import tema from "../../services/Plantilla";
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faPlus, faTrashAlt, faClipboardCheck, faBackward, faCopy } from '@fortawesome/free-solid-svg-icons';
import { ItemBullets } from '../../constant';



class Listar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        ListaTablas: [],
        TablaMenu: [],
        Modulos: [],
        ListaCampos: [],
        Relaciones: [],
        ListaRelaciones: []
    }
    borrarRegistros = (id) => {
        Swal.fire({
            title: 'Está seguro de Eliminar este registro?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Borrar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                authAxios.get("master/borrar/" + id)
                    .then((datosRespuesta) => {
                        
                        Swal.fire('Inactivado!', datosRespuesta.data.msj, 'success')
                        this.ListarTabla(1);
                    })
                    .catch();
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };
    enviarDatos = (e) => {
        e.preventDefault();

        const tabla = document.getElementById('tabla').value;

        if (tabla === '') {
            Swal.fire('', 'Debes de digtar el nombre de la tabla', 'info');
            return false;
        }

        let form = document.getElementById('form');
        let formData = new FormData(form);
        authAxios.post("modulos/registrar", formData)
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;
                

                if (datosRespuesta.data.Status) {
                    Swal.fire(
                        'modulo!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    document.getElementById('form').reset();

                    this.ListarTabla(1);

                } else {
                    Swal.fire(
                        'modulo!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch()
    }
    ListarTabla(page) {
        var n = localStorage.getItem('Menu');
        var id = localStorage.getItem('IdMenu');
        document.getElementById('titulo').innerHTML = n;
        Swal.showLoading();
        let bname = document.getElementById('bnombre').value;
        if (bname == '') {
            bname = 0;
        }
        authAxios.get("tablas/index/" + id + "/" + bname + "?page=" + page)
            .then((datosRespuesta) => {
                
                Swal.close();
                var status = datosRespuesta.data.status;
                
                this.setState({ datosCargados: true, load: '', ListaTablas: datosRespuesta.data.data, pagina: datosRespuesta.data.total, ultimo: datosRespuesta.data.last_page, actual: datosRespuesta.data.current_page });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    Tablas(id_menu, tabla) {
        //Swal.showLoading();
        document.getElementById('t_tabla').value = tabla;
        document.getElementById('t_id_menu').value = id_menu;

        authAxios.get("modulos/tablas/" + id_menu)
            .then((datosRespuesta) => {
                //Swal.close();
                var status = datosRespuesta.data.status;
               
                
                this.setState({ TablaMenu: datosRespuesta.data });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }

    VerCampo(id_campo) {


        authAxios.get("modulos/vercampos/" + id_campo)
            .then((datosRespuesta) => {
                //Swal.close();
                var status = datosRespuesta.data.status;
               
                
                var datos = datosRespuesta.data;
                document.getElementById('t_id').value = datos.id;
                document.getElementById('t_id_menu').value = datos.id_menu;
                document.getElementById('t_campo').value = datos.nombre_campo;
                document.getElementById('t_titulo').value = datos.nombre_titulo;
                document.getElementById('t_tipo').value = datos.tipo_dato;
                document.getElementById('t_longitud').value = datos.longitud;
                document.getElementById('t_obligatorio').value = datos.obligatorio;
                document.getElementById('t_traetabla').value = datos.trae_tabla;
                document.getElementById('pestaña').value = datos.pestana;
                document.getElementById('tpestaña').value = datos.mostrar_consulta;
                document.getElementById('t_order').value = datos.orderby;
                document.getElementById('forder').value = datos.orden;
                document.getElementById('t_depende').value = datos.depende_campo;
                document.getElementById('t_notifica').value = datos.notifica;
                document.getElementById('tdisa').value = datos.campo_editable;
                if (datos.trae_tabla != '') {
                    this.llenarcamposeect(datos.trae_tabla, datos.trae_value, datos.trae_campo);
                }

            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    llenarcamposeect(tabla, value, campo) {
        authAxios.get("modulos/listacampos/" + tabla)
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;
                

                this.setState({ ListaCampos: datosRespuesta.data });
                document.getElementById('t_traevalue').value = value
                document.getElementById('t_traecampo').value = campo
            })
            .catch();
    }
    LinkEditar(ide, nombre, estado, titulo, formulario, link) {
        document.getElementById('id_tabla').value = ide;
        document.getElementById('tabla').value = nombre;
        document.getElementById('estado').value = estado;
        document.getElementById('titu').value = titulo;
        document.getElementById('tamano').value = formulario;
        document.getElementById('link').value = link;
        //window.location.href = process.env.PUBLIC_URL+'/tipo_contrato/editar/'+tema;
    }
    savecampos() {
        var campo = document.getElementById('t_campo').value;
        var tipo = document.getElementById('t_tipo').value;
        var log = document.getElementById('t_longitud').value;
        var obl = document.getElementById('t_obligatorio').value;
        var titulo = document.getElementById('t_titulo').value;
        var editable = document.getElementById('tdisa').value;
        var dato = document.getElementById('tdato').value;

        let form = document.getElementById('form_campos');

        if (campo == '') {
            Swal.fire('Campo vacio!', 'Debes de digitar el campo', 'error');
            return false;
        }
        if (tipo == '') {
            Swal.fire('Campo vacio!', 'Debes de seleccionar el tipo de dato', 'error');
            return false;
        }
        if (log == '') {
            Swal.fire('Campo vacio!', 'Debes de digitar la longitud', 'error');
            return false;
        }
        if (obl == '') {
            Swal.fire('Campo vacio!', 'Debes de seleccionar si es obligatorio o no', 'error');
            return false;
        }
        if (titulo == '') {
            Swal.fire('Campo vacio!', 'Debes de digitar el titulo', 'error');
            return false;
        }



        let formData = new FormData(form);
        

        authAxios.post("modulos/registrarcampo", formData)
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;
                

                Swal.fire(datosRespuesta.data.msj, '', 'success');
                this.Tablas(document.getElementById('t_id_menu').value, document.getElementById('t_tabla').value);
                document.getElementById('t_campo').value = '';
                document.getElementById('t_tipo').value = '';
                document.getElementById('t_longitud').value = '';
                document.getElementById('t_obligatorio').value = 'No';
                document.getElementById('t_traetabla').value = '';
                document.getElementById('t_traevalue').value = '';
                document.getElementById('t_traecampo').value = '';
                document.getElementById('t_order').value = '0';
                document.getElementById('t_titulo').value = '';
                document.getElementById('pestaña').value = '';
                document.getElementById('tpestaña').value = 'No';
                document.getElementById('forder').value = '';
                document.getElementById('t_depende').value = '';
                document.getElementById('tdisa').value = 'No'

                document.getElementById('t_id').value = '';
                document.getElementById('t_notifica').value = 0;
            })
            .catch()

    }
    modulos = () => {
        authAxios.get("modulos/listamenu")
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;
                

                this.setState({ Modulos: datosRespuesta.data });
            })
            .catch();
    }
    seleccionartabla() {
        var tabla = document.getElementById('t_traetabla').value;
        this.listacampos(tabla);
    }
    listacampos(tabla) {
        authAxios.get("modulos/listacampos/" + tabla)
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;
               

                this.setState({ ListaCampos: datosRespuesta.data });
            })
            .catch();
    }
    TablasRelacion(id, tabla) {
        document.getElementById('r_tablas').value = tabla;
        authAxios.get("modulos/relaciones/" + tabla)
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;
                

                this.setState({ Relaciones: datosRespuesta.data.tabla });
                this.ListaRelacion(tabla);
            })
            .catch();
    }

    ListaRelacion(tabla) {
        document.getElementById('r_tablas').value = tabla;
        authAxios.get("modulos/listarelaciones/" + tabla)
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;
                

                this.setState({ ListaRelaciones: datosRespuesta.data.tabla });
            })
            .catch();
    }

    borrarrelacion(id) {
        var tabla = document.getElementById('r_tablas').value;
        authAxios.get("modulos/borrarrelaciones/" + id)
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;
                

                Swal.fire('Seha quitado la relacion de la tabla!', '', 'success');
                this.ListaRelacion(tabla);
            })
            .catch();
    }
    agregarrelacion() {
        var tabla = document.getElementById('r_tablas').value;
        var foranea = document.getElementById('r_relacion').value;
        authAxios.get("modulos/agregarrelacion/" + tabla + "/" + foranea)
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;
                

                Swal.fire(datosRespuesta.data.msj, '', 'success');
                this.ListaRelacion(tabla);
            })
            .catch();
    }
    componentDidMount() {
        this.ListarTabla(1);
        this.modulos();
        document.getElementById('id_menu').value = localStorage.getItem('IdMenu');
    }
    muestracampo() {
        var fecha = document.getElementById('t_tipo').value;
        document.getElementById('t_notifica').disabled = true;
        document.getElementById('t_notifica').value = 0;
        if (fecha == 'date') {
            document.getElementById('t_notifica').disabled = false;
        }
    }
    render() {
        const { load, ListaTablas, pagina, ultimo, actual, TablaMenu, Modulos, ListaCampos, Relaciones, ListaRelaciones } = this.state;
        return (
            <div className="content">

                <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Registro de Campo</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <form id="form_campos">
                                                <div className="row g-3">
                                                    <div className="col-md-2">
                                                        <label htmlFor="cedula">Nombre Campo</label>

                                                        <input className={"form-control form-control-sm"} type="hidden" name="t_id" id="t_id" placeholder="" />
                                                        <input className={"form-control form-control-sm"} type="hidden" name="t_id_menu" id="t_id_menu" placeholder="" />
                                                        <input className={"form-control form-control-sm"} type="text" name="t_tabla" id="t_tabla" readOnly placeholder="" />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="cedula">Campo</label>
                                                        <input className={"form-control form-control-sm"} type="text" name="t_campo" id="t_campo" placeholder="" />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="cedula">Titulo del campo</label>
                                                        <input className={"form-control form-control-sm"} type="text" name="t_titulo" id="t_titulo" placeholder="" />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="cedula">Campo Unico</label>
                                                        <select className={"form-control form-control-sm"} name="t_unico" id="t_unico">
                                                            <option value="0">No</option>
                                                            <option value="1">Si</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="cedula">Tipo de Dato</label>
                                                        <select className={"form-control form-control-sm"} name="t_tipo" id="t_tipo" >
                                                            <option value="int">Numerico</option>
                                                            <option value="varchar">Caracteres</option>
                                                            <option value="date">Fecha</option>
                                                            <option value="time">Hora</option>
                                                            <option value="datetime">Fecha y hora</option>
                                                            <option value="text">Textos grandes</option>
                                                            <option value="float">Valores con decimal</option>
                                                            <option value="blob">Imagen</option>
                                                            <option value="email">Correo</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="cedula">Longitud del campo</label>
                                                        <input className={"form-control form-control-sm"} type="text" name="t_longitud" id="t_longitud" placeholder="" />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="t_notifica">Notifica fecha</label>
                                                        <select className={"form-control form-control-sm"} name="t_notifica" id="t_notifica"  >
                                                            <option value="0">No</option>
                                                            <option value="1">Si</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="cedula">Campo Obligatorio</label>
                                                        <select className={"form-control form-control-sm"} name="t_obligatorio" id="t_obligatorio">
                                                            <option value="0">No</option>
                                                            <option value="1">Si</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="cedula">Relacion tabla</label>
                                                        <select className={"form-control form-control-sm"} name="t_traetabla" id="t_traetabla" onChange={() => this.seleccionartabla()}>
                                                            <option value="">Seleccione</option>
                                                            {
                                                                Modulos.map((item) => (
                                                                    <option value={item.tabla} >{item.tabla}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="cedula">Trae value (ID)</label>
                                                        <select className={"form-control form-control-sm"} name="t_traevalue" id="t_traevalue">
                                                            <option value="">Seleccione</option>
                                                            {
                                                                ListaCampos.map((item) => (
                                                                    <option value={item.nombre_campo} >{item.nombre_campo}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="cedula">Trae Nombre campo</label>
                                                        <select className={"form-control form-control-sm"} name="t_traecampo" id="t_traecampo">
                                                            <option value="">Seleccione</option>
                                                            {
                                                                ListaCampos.map((item) => (
                                                                    <option value={item.nombre_campo} >{item.nombre_campo}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="cedula">Depende del campo</label>
                                                        <select className={"form-control form-control-sm"} name="t_depende" id="t_depende">
                                                            <option value="">Seleccione</option>

                                                            {
                                                                TablaMenu.map((item) => (
                                                                    <option value={item.nombre_campo} >{item.nombre_campo}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="cedula">Ordena Campo</label>
                                                        <select className={"form-control form-control-sm"} name="t_order" id="t_order">
                                                            <option value="No">No</option>
                                                            <option value="Si">Si</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="pestaña">Pestaña</label>
                                                        <input className={"form-control form-control-sm"} type="text" name="pestaña" id="pestaña" placeholder="" />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="tpestaña">Mostrar en tabla</label>
                                                        <select className={"form-control form-control-sm"} name="tpestaña" id="tpestaña">
                                                            <option value="No">No</option>
                                                            <option value="Si">Si</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="forder">Orden</label>
                                                        <input className={"form-control form-control-sm"} type="text" name="forder" id="forder" placeholder="orden en el formulario" />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="tdisa">Campo Editable</label>
                                                        <select className={"form-control form-control-sm"} name="tdisa" id="tdisa">

                                                            <option value="Si">Si</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label htmlFor="tdisa">Ver solo mis datos</label>
                                                        <select className={"form-control form-control-sm"} name="tdato" id="tdato">
                                                            <option value="No">No</option>
                                                            <option value="Si">Si</option>

                                                        </select>
                                                    </div>


                                                    <button type="button" className="btn btn-primary" onClick={() => this.savecampos()}>Agregar Campo</button>
                                                </div>
                                            </form>
                                        </div>
                                        <br></br>
                                        <div className="row g-3 table-responsive">
                                            <table className="table table-hover table-condensed table-sm">
                                                <thead className="thead-inverse">
                                                    <tr>
                                                        <th className="align-middle">ORDEN</th>

                                                        <th className="align-middle">OBL</th>
                                                        <th className="align-middle">TITULO</th>
                                                        <th className="align-middle">CAMPO/LONGITUD</th>


                                                        <th className="align-middle">TRAE VALUE</th>
                                                        <th className="align-middle">TRAE CAMPO</th>
                                                        <th className="align-middle">PESTAÑA</th>
                                                        <th className="align-middle">MUESTRA TABLA</th>



                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        TablaMenu.map((item) => (
                                                            <tr key={item.id}>
                                                                <td>{item.orden}</td>
                                                                <td>{item.obligatorio == '1' ? 'Si' : 'No'}</td>
                                                                <td>{item.nombre_titulo}</td>
                                                                <td><div className="btn-group" role="group" aria-label="">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary btn-xs"
                                                                        onClick={() => this.VerCampo(item.id)}

                                                                    >
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </button>
                                                                </div>{item.nombre_campo} {item.tipo_dato}({item.longitud}) <br></br>Editable: {item.campo_editable}<br></br>Ver solo mis datos?: {item.vista_usuario}</td>
                                                                <td>{item.trae_tabla == null ? '' : item.trae_tabla + '(' + item.trae_value + ')'} </td>
                                                                <td>{item.trae_campo}</td>
                                                                <td>{item.pestana}</td>
                                                                <td>{item.mostrar_consulta}</td>


                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="ModalTabla" tabIndex={-1} aria-labelledby="ModalTablaLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="ModalTablaLabel">Agregar Tabla SubMenu</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <form onSubmit={this.enviarDatos} id="form">
                                                <div className="row g-3">
                                                    <div className="col-md-6">
                                                        <label htmlFor="cedula">Nombre de la tabla</label>
                                                        <input className={"form-control form-control-sm"} type="hidden" name="id_menu" id="id_menu" placeholder="" />
                                                        <input className={"form-control form-control-sm"} type="hidden" name="id_tabla" id="id_tabla" placeholder="" />
                                                        <input className={"form-control form-control-sm"} type="text" name="tabla" id="tabla" placeholder="" />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="cedula">Titulo del menu</label>

                                                        <input className={"form-control form-control-sm"} type="text" name="titu" id="titu" placeholder="" />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="cedula">Estado</label>
                                                        <select className={"form-control form-control-sm"} name="estado" id="estado">
                                                            <option value="0">Publicado</option>
                                                            <option value="1">Sin publicar</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="cedula">Tamaño del formulario</label>
                                                        <select className={"form-control form-control-sm"} name="tamano" id="tamano">
                                                            <option value="xs">Normal</option>
                                                            <option value="sm">Pequeño</option>
                                                            <option value="lg">Largo</option>
                                                            <option value="xl">Grande</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label htmlFor="cedula">Link Manual</label>
                                                        <input className={"form-control form-control-sm"} type="text" name="link" id="link" placeholder="Ejemplo https::url.com" />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label htmlFor="cedula">Registro directo en formularios</label>
                                                        <select className={"form-select form-control-sm"} name="directo" id="directo">
                                                            <option value="0">No</option>
                                                            <option value="1">Si</option>


                                                        </select>
                                                    </div>

                                                    <button type="submit" className="btn btn-primary" >Agregar Tabla</button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>


                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="ModalRelacion" tabIndex={-1} aria-labelledby="ModalTablaLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="ModalTablaLabel">Relacionar Tablas Foraneas</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row table-responsive">

                                            <div className="row g-3">

                                                <table className="table responsive">
                                                    <thead>
                                                        <tr>
                                                            <th>PRIMARIA</th>
                                                            <th>FORANEA</th>
                                                            <th>LLAVE</th>
                                                            <th>BORRAR</th>
                                                        </tr>
                                                        <tr>
                                                            <th><input type="text" className="form-control form-control-sm" name="r_tablas" id="r_tablas" disabled /></th>
                                                            <th><select className={"form-control form-control-sm"} name="r_relacion" id="r_relacion">
                                                                <option value="">Seleccione</option>
                                                                {
                                                                    Relaciones.map((item, i) => (
                                                                        <option key={i} value={item.nombre_tabla}>{item.nombre_tabla} ({item.trae_value})</option>
                                                                    ))
                                                                }
                                                            </select></th>

                                                            <th colSpan={2}><button type="submit" className="btn btn-primary" onClick={() => this.agregarrelacion()}>Relacionar</button></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            ListaRelaciones.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td>{item.tablaprimaria}</td>
                                                                    <td>{item.tablaforanea}</td>
                                                                    <td>{item.relacion_fk}</td>
                                                                    <td><button type='button' className='btn-danger' onClick={() => this.borrarrelacion(item.id)}> <FontAwesomeIcon icon={faTrashAlt} />  </button></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>

                                    </div>


                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="container-fluid">
                    <br />
                    <div className="card">
                        <div className="card-header">
                            <Link className="btn btn-danger btn-sm" to={`${process.env.PUBLIC_URL}/configuracion/lista/${tema}`}><FontAwesomeIcon icon={faBackward} />&nbsp;&nbsp;Regresar</Link>
                            &nbsp;
                            <button className="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#ModalTabla"> <FontAwesomeIcon icon={faPlus} /> Nueva Tabla</button>
                            &nbsp;

                        </div>
                        <div className="card-body">
                            <h4>SubMenu de <span id="titulo"></span></h4>

                            <hr />
                            <div className="row">
                                <div className="col-xl-3">
                                    <input style={{ textTransform: 'uppercase' }} type="text" id="bnombre" name="bnombre" placeholder="DEPARTAMENTO" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                    <button id="bt" name="bt" className="btn btn-secondary btn-sm" onClick={() => this.ListarTabla(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br>
                            <div className="row table-responsive">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                            <th className="align-middle">ID. MENU</th>
                                            <th className="align-middle">NOMBRE MENU</th>
                                            <th className="align-middle">TABLA MENU</th>
                                            <th className="align-middle">LINK</th>
                                            <th className="align-middle">ESTADO</th>
                                            <th className="align-middle">OPCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {
                                            ListaTablas.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.titulo}</td>
                                                    <td>{item.tabla}</td>
                                                    <td>{item.id_modulo}:{item.url == '0' ? 'pages/' : item.url}</td>
                                                    <td>
                                                        {
                                                            item.estado == '1' ? (
                                                                <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-danger">INACTIVO</span>
                                                            ) : (
                                                                <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-success">ACTIVO</span>
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-xs"
                                                                onClick={() =>
                                                                    this.LinkEditar(item.id, item.tabla, item.estado, item.titulo, item.formulario, item.url)
                                                                }
                                                                data-bs-toggle="modal" data-bs-target="#ModalTabla"
                                                            >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                            {item.url != '0' ?
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger btn-xs"
                                                                    onClick={() =>
                                                                        this.borrarRegistros(item.id)
                                                                    }

                                                                >
                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                </button> : ''}
                                                            {item.url == '0' ?
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-warning btn-xs"
                                                                        data-bs-toggle="modal" data-bs-target="#ModalRelacion"
                                                                        onClick={() =>
                                                                            this.TablasRelacion(item.id, item.tabla)
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon icon={faCopy} />
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-info btn-xs"
                                                                        data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                        onClick={() =>
                                                                            this.Tablas(item.id, item.tabla)
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon icon={faPlus} />
                                                                    </button></> : ''}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarTabla(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarTabla(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                    {(() => {
                                        let p = [];
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarDepartamento(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarTabla(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarTabla(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Listar;