import React from "react";
import authAxios from "../../services/Token";

class SelectRelacion extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    SubModulos: [],
  }
  SubmodulosSELECT(id) {

    authAxios.get("master/selectrelacion/" + id)
      .then((datosRespuesta) => {
        this.setState({ SubModulos: datosRespuesta.data.data });
      })

      .catch();
  }
  CargarSelect(id) {

    if (id !== 0) {
      var campo = document.getElementById(id).value;
      var camposelector = document.getElementById(this.props.nombre_input).value;
      if (camposelector === '') {
        var tp = this.props.tablaprimaria;
        var tf = this.props.t;
        authAxios.get("master/camposelector/" + tp + "/" + tf + "/" + id + "/" + campo)
          .then((datosRespuesta) => {
            
            

            this.setState({ SubModulos: datosRespuesta.data.data });

          }).catch();
        
      }

    }


  }
  componentDidMount() {
    this.setState({ SubModulos: [] });
    this.SubmodulosSELECT(this.props.t);
    
    

  }
  render() {
    const { SubModulos } = this.state;
    return (
      <>
        <select className={"form-select form-control-sm "} name={this.props.nombre_input} id={this.props.nombre_input} onClick={() => this.CargarSelect(this.props.depende)} >
          <option value="">Seleccione</option>
          {
            SubModulos.map((item, i) => (

              <option value={item[this.props.v]} key={i}>{item[this.props.c]}</option>

            ))
          }
        </select>
      </>
    )
  }
}

export default SelectRelacion;
