import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { DefaultLayout } from '../../layout/theme-customizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faSave, faUser, faCar, faEdit, faFilter, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import tema from "../../services/Plantilla";

let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy =
  f[2].padStart(2, "0") +
  "-" +
  f[1].padStart(2, "0") +
  "-" +
  f[0].padStart(2, "0");

class Listar extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    datosCargados: false,
    ListaTabla: [],
    ListaVehiculos: [],
    ListaConductor: [],
    ListaTablaEventos: [],
    ListaTablaTurnos: [],
    ListaTablaGastos: [],
    Hoy: hoy,
    mostrarDiv: false,
    mostrarDivEjecucion: true,
    botonEjecucion: true,
    botonEventos: false,
  }
  borrarRegistros = (id) => {
    Swal.fire({
      title: 'Está seguro de Eliminar el registro?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Borrar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        authAxios.get("contrato/borrar/" + id)
          .then((datosRespuesta) => {

            Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
            this.MostrarTabla(1);
          })
          .catch();
      } else if (result.isDenied) {
        Swal.fire('Cancelado', '', 'info')
      }
    })
  };

  LinkEditar(ide) {
    localStorage.setItem('ide', ide);

    //window.location.href = process.env.PUBLIC_URL+'/tipo_contrato/editar/'+tema;
  }

  MostrarTabla() {
    Swal.showLoading();
    let bname = document.getElementById('bnombre').value;
    if (bname == '') {
      bname = 0;
    }
    let bfecha = document.getElementById('bfecha').value;
    if (bfecha == '') {
      bfecha = 0;
    }
    // authAxios.get("transporte/consultarservicios/" + bname + "/" + bfecha)
    authAxios.get("caracol-crontaps/consultar-ejecuciones")
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data.status;


        this.setState({ datosCargados: true, load: '', ListaTabla: datosRespuesta.data.ListaEjecuciones, ListaTablaEventos: datosRespuesta.data.ListaEventos });
      })
      .catch();
    //window.location.href = '/'; window.localStorage.clear();
  }
  componentDidMount() {
    this.MostrarTabla(1);
  }
  Enviar() {
    let form = document.getElementById('formulario');
    let formData = new FormData(form);

    // authAxios.post("transporte/guardarservicio", formData)
    //   .then((datosRespuesta) => {

        Swal.fire(
          'Ejecucion Aceptada!',
          // datosRespuesta.data.msj.MensajeRespuesta,
          '',
          'success'
        );

      //   document.getElementById('formulario').reset();
      //   this.MostrarTabla(1);
      // })
      // .catch()

  }
  Rechazar() {
    // var iser = document.getElementById("ser").value;

    // authAxios.get("transporte/confirmarsolicitudes/" + iser)
    //   .then((datosRespuesta) => {

        Swal.fire(
          'Ejecucion Rechaza!',
          // datosRespuesta.data.MensajeRespuesta,
          '',
          'error'
        );
      //   this.MostrarTabla(1);
      // })
      // .catch()
  }
  VerServicio(i) {
    Swal.showLoading();

    let bname = document.getElementById('bnombre').value;
    if (bname == '') {
      bname = 0;
    }
    let bfecha = document.getElementById('bfecha').value;
    if (bfecha == '') {
      bfecha = 0;
    }
    authAxios.get("transporte/consultarservicios/" + bname + "/" + bfecha)
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data.status;


        this.setState({ datosCargados: true, load: '', ListaTabla: datosRespuesta.data });
        // document.getElementById("servicio").innerHTML = datosRespuesta.data[i].datos.IdServicio;

        // document.getElementById("ser").value = datosRespuesta.data[i].datos.IdServicio;
        // document.getElementById("NumeroPedido").value = datosRespuesta.data[i].datos.NumeroPedido + '-' + datosRespuesta.data[i].datos.Posicion;
        // document.getElementById("NombresSolicitante").value = datosRespuesta.data[i].datos.NombresSolicitante;
        // document.getElementById("NumeroSolicitud").value = datosRespuesta.data[i].datos.NumeroSolicitud;
        // document.getElementById("fecha").value = datosRespuesta.data[i].fecha;
        // document.getElementById("HoraCitacion").value = datosRespuesta.data[i].datos.HoraCitacion;
        // document.getElementById("FechaDocumento").value = datosRespuesta.data[i].datos.FechaDocumento;
        // document.getElementById("IdTipoVehiculo").value = datosRespuesta.data[i].datos.IdTipoVehiculo;
        // document.getElementById("ProductoRef").value = datosRespuesta.data[i].datos.ProductoRef;
        // document.getElementById("Destino").value = datosRespuesta.data[i].datos.Destino;
        // document.getElementById("PersonasTransportar").value = datosRespuesta.data[i].datos.PersonasTransportar;
        // document.getElementById("Contacto").value = datosRespuesta.data[i].datos.Contacto;
        // document.getElementById("Direccion").value = datosRespuesta.data[i].datos.Direccion;
        // document.getElementById("Telefono").value = datosRespuesta.data[i].datos.Telefono;
        // document.getElementById("Email").value = datosRespuesta.data[i].datos.Email;
        // document.getElementById("Descripcion").value = datosRespuesta.data[i].datos.Descripcion;
        // document.getElementById("Estado").value = datosRespuesta.data[i].estadoservicio;
        // document.getElementById("CantidadPedido").value = datosRespuesta.data[i].datos.CantidadPedido;
        // document.getElementById("Proveedor").value = datosRespuesta.data[i].datos.Proveedor;
        // document.getElementById("IdSolicitante").value = datosRespuesta.data[i].datos.IdSolicitante;
        // document.getElementById("OI").value = datosRespuesta.data[i].datos.OI;
        var est = datosRespuesta.data[i].estado;
        if (est == 'Sin registrar') {
          document.getElementById("btnservicio").disabled = false;
          document.getElementById("btnservicio2").disabled = true;
          document.getElementById("btnservicio3").disabled = true;
        } else {
          document.getElementById("btnservicio").disabled = true;
          document.getElementById("btnservicio2").disabled = false;
          document.getElementById("btnservicio3").disabled = false;
        }

      })
      .catch();
    //window.location.href = '/'; window.localStorage.clear();
  }
  verEjecucion(array) {
    console.log(array);
    document.getElementById("servicioinfo").value = array.IdServicio;
    document.getElementById("nombreconductor").value = array.ConductorNombre;
    document.getElementById("kminicial").value = array.KmInicio;
    document.getElementById("kmfinal").value = array.KmFin;
    document.getElementById("estadoservicio").value = array.EstadoServicioNombre;
    document.getElementById("observaciones").value = array.Observaciones;
    document.getElementById("desayuno").value = array.TieneDesayuno ? 'Si' : 'No';
    document.getElementById("almuerzo").value = array.TieneAlmuerzo ? 'Si' : 'No';

    document.getElementById("cena").value = array.TieneCena ? 'Si' : 'No';
    document.getElementById("usuariofinal").value = array.NombreUsuarioFinal;
    document.getElementById("recorrido").value = array.Recorrido;
    document.getElementById("tiporecorrido").value = array.TipoRecorrido;
    this.setState({ListaTablaGastos: array.GastosAdicionales,ListaTablaTurnos:array.Turnos });
    
  }
  verEventos(array) {
    console.log(array);
    document.getElementById("serevento").value = array.IdServicio;
    document.getElementById("vh").value = array.IdVehiculo;
    document.getElementById("nc").value = array.NombreConductor;
    document.getElementById("desc").value = array.DescEvento;
    document.getElementById("obs").value = array.Observaciones;
    document.getElementById("vlrser").value = array.ValorServicio;
    document.getElementById("vlrserini").value = array.ValorServicioInicial;
    document.getElementById("porcdes").value = array.PorcDescuento;
    document.getElementById("totalpagar").value = array.TotalPagar;
  }
  ConsultarVehiculos() {
    authAxios.get("caracol-crontaps/consultar-ejecuciones")
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data;
        console.log(status);

        this.setState({ datosCargados2: true, ListaVehiculos: datosRespuesta.data });
      })
      .catch();

  }
  ConsultarConductores() {
    this.setState({ datosCargados3: false, ListaConductor: [] }); // Vaciar los datos y establecer datosCargados3 en false

    authAxios.get("transporte/ConsultarConductores")
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data.status;


        this.setState({ datosCargados3: true, ListaConductor: datosRespuesta.data.ListaConductor });
      })
      .catch();
  }
  InactivarVehiculo(doc) {

    authAxios.get("transporte/InactivarVehiculo/" + doc)
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data.status;

        alert(datosRespuesta.data.MensajeRespuesta);
        this.ConsultarVehiculos();
      })
      .catch();

  }
  InactivarConductor(doc) {

    authAxios.get("transporte/InactivarConductor/" + doc)
      .then((datosRespuesta) => {
        Swal.close();
        var status = datosRespuesta.data.status;

        Swal.fire({
          title: 'Conductor Desactivado',
          icon: 'success',
        });
        this.ConsultarVehiculos();
      })
      .catch();

  }
  componentDidMount() {

    this.MostrarTabla(1);

  }
  consultarEjecuciones() {
    this.setState({ mostrarDiv: false });
    this.setState({ mostrarDivEjecucion: true });
    this.setState({ botonEjecucion: true });
  }
  consultarEventos() {
    this.setState({ mostrarDiv: true });
    this.setState({ mostrarDivEjecucion: false });
    this.setState({ botonEjecucion: false });
  }
  render() {
    const { load, ListaTabla, pagina, ListaTablaGastos, ultimo, actual, Hoy, ListaVehiculos, ListaConductor, mostrarDiv, botonEjecucion, mostrarDivEjecucion, ListaTablaEventos, ListaTablaTurnos } = this.state;
    return (
      <div className="content">
        <div className="container-fluid">
          <br />
          <div className="card">

            <div className="card-body">

              {/* <h4>Lista Ejecuciones Proveedor</h4> */}
              <button className="btn btn-primary" disabled={botonEjecucion} slot='end' onClick={() => this.consultarEjecuciones()}> <FontAwesomeIcon icon={faUser} /> Ejecuciones</button>&nbsp;&nbsp;
              <button className="btn btn-primary" slot='end' onClick={() => this.consultarEventos()}> <FontAwesomeIcon icon={faCar} /> Eventos</button>
              <hr />
              <div className="row">
                <div className="col-xl-3">
                  <input style={{ textTransform: 'uppercase' }} type="text" id="bnombre" name="bnombre" placeholder="Buscar por servicio" className="form-control form-control-sm" />
                </div>
                <div className="col-xl-3">
                  <input style={{ textTransform: 'uppercase' }} type="date" id="bfecha" name="bfecha" placeholder="Buscar por fecha" className="form-control form-control-sm" defaultValue={Hoy} />
                </div>
                <div className="col-xl-3">
                  <button id="bt" name="bt" className="btn btn-primary btn-xs" onClick={() => this.MostrarTabla(1)}>&nbsp;&nbsp;Filtrar</button>
                </div>
              </div>

              <br></br>
              {mostrarDivEjecucion && (
                <>
                  <h3>EJECUCIONES</h3>
                  <div className="row table-responsive" id="ejecucionesTable">
                    <table className="table table-hover table-condensed table-sm">
                      <thead className="thead-inverse">
                        <tr>
                          <th className="align-middle">SERVICIO</th>
                          <th className="align-middle">NOMBRE CONDUCTOR</th>
                          <th className="align-middle">FECHA SERVICIO</th>
                          <th className="align-middle">KM INICIAL</th>
                          <th className="align-middle">KM FINAL</th>
                          <th className="align-middle">ESTADO SERVICIO</th>
                          <th className="align-middle">OBSERVACIONES</th>
                          <th className="align-middle">DESAYUNO</th>
                          <th className="align-middle">ALMUERZO</th>
                          <th className="align-middle">CENA</th>
                          <th className="align-middle">TURNOS</th>
                          <th className="align-middle">OPCIONES</th>
                        </tr>
                      </thead>
                      <tbody>
                        {load}
                        {
                          ListaTabla.map((item, i) => (
                            <tr key={item.IdServicio}>
                              <td>{item.IdServicio}</td>
                              <td>{item.NombreUsuarioFinal}</td>
                              <td></td>
                              <td>{item.KmInicio}</td>
                              <td>{item.KmFin}</td>
                              <td>{item.EstadoServicioNombre}</td>
                              <td>{item.Observaciones}</td>
                              <td>{item.TieneDesayuno == true ? 'Si' : 'No'}</td>
                              <td>{item.TieneAlmuerzo == true ? 'Si' : 'No'}</td>
                              <td>{item.TieneCena == true ? 'Si' : 'No'}</td>
                              <td>{item.Turnos.length}</td>
                              <td>
                                <div className="btn-group" role="group" aria-label="">

                                  <button
                                    type="button"
                                    className="btn btn-info btn-xs"
                                    data-bs-toggle='modal' data-bs-target="#ModalRelacion"
                                    onClick={() => this.verEjecucion(item)}
                                  >
                                    <FontAwesomeIcon icon={faInfo} /> Ver
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </>

              )}

              {mostrarDiv && (
                <>
                  <h3>EVENTOS</h3>
                  <div className="row table-responsive" id='eventosTable'>
                    <table className="table table-hover table-condensed table-sm">
                      <thead className="thead-inverse">
                        <tr>
                          <th className="align-middle">SERVICIO</th>
                          <th className="align-middle">VEHICULO</th>
                          <th className="align-middle">NOMBRE CONDUCTOR</th>
                          <th className="align-middle">DESCUENTO</th>
                          <th className="align-middle">OBSERVACIONES</th>
                          <th className="align-middle">OPCIONES</th>
                        </tr>
                      </thead>
                      <tbody>
                        {load}
                        {
                          ListaTablaEventos.map((item, i) => (
                            <tr key={item.IdServicio}>
                              <td>{item.IdServicio}</td>
                              <td>{item.IdVehiculo}</td>
                              <td>{item.NombreConductor}</td>
                              <td>{item.DescEvento}</td>
                              <td>{item.Observaciones}</td>
                              <td>
                                <div className="btn-group" role="group" aria-label="">

                                  <button
                                    type="button"
                                    className="btn btn-info btn-xs"
                                    data-bs-toggle='modal' data-bs-target="#ModalRelacionEventos"
                                    onClick={() => this.verEventos(item)}
                                  >
                                    <FontAwesomeIcon icon={faInfo} /> Ver
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div></>
              )}


            </div>
            <div className="card-footer text-muted">
              <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
            </div>
          </div>
        </div>
        <div className="modal fade" id="ModalRelacion" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
          <div className={"modal-dialog  modal-lg"} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="permisoRolesModalLabel">Detalle de la ejecucion <span id='servicio'></span></h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <input type="hidden" id="item" name="item" />
              </div>
              <div className="modal-body">
                <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Detalle</button>
                    <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Gastos adicionales</button>
                    <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Turnos</button>
                  </div>
                </nav>

                <div id="form_registro">

                  <div className="card-body">
                    <div className="card-body btn-showcase">
                      <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                          <form id="formulario" className='p-4'>
                            <div className="row">
                              <div className="col-xl-12">
                                <div className='row g-3'>


                                  <div className="col-md-3">
                                    <label htmlFor="nombre">Id Servicio </label>
                                    <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="ser" id="servicioinfo" aria-describedby="helpId" />
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="nombre">Nombre Conductor </label>
                                    <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="NumeroPedido" id="nombreconductor" aria-describedby="helpId" />
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="nombre">Fecha Servicio </label>
                                    <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="NombresSolicitante" id="fechaservicio" aria-describedby="helpId" />
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="nombre">KM Inicial </label>
                                    <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="NumeroSolicitud" id="kminicial" aria-describedby="helpId" />
                                  </div>

                                  <div className="col-md-3">
                                    <label htmlFor="nombre">KM Final </label>
                                    <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="fecha" id="kmfinal" aria-describedby="helpId" />
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="nombre">Estado Servicio </label>
                                    <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="HoraCitacion" id="estadoservicio" aria-describedby="helpId" />
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="nombre">Observaciones </label>
                                    <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="FechaDocumento" id="observaciones" aria-describedby="helpId" />
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="nombre">Desayuno </label>
                                    <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="IdTipoVehiculo" id="desayuno" aria-describedby="helpId" />
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="nombre">Almuerzo </label>
                                    <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="ProductoRef" id="almuerzo" aria-describedby="helpId" />
                                  </div>


                                  <div className="col-md-3">
                                    <label htmlFor="nombre">Cena </label>
                                    <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="Destino" id="cena" aria-describedby="helpId" />
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="nombre">Usuario Final </label>
                                    <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="PersonasTransportar" id="usuariofinal" aria-describedby="helpId" />
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="nombre">Recorrido </label>
                                    <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="Contacto" id="recorrido" aria-describedby="helpId" />
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="nombre">Tipo Recorrido </label>
                                    <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="Direccion" id="tiporecorrido" aria-describedby="helpId" />
                                  </div>
                                </div>

                              </div>
                            </div>
                            <hr />
                            <div className='row'>
                              <div className='col-xl-12 text-center'>
                                <div className="btn-group" role="group" aria-label="">
                                  <button type="button" onClick={this.Enviar} className="btn btn-success btn-sm" id="btnservicio"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;1. Guardar y Confirmar Ejecucion</button>&nbsp;&nbsp;

                                  <button type="button" onClick={this.Rechazar} className="btn btn-danger btn-sm" id="btnservicio3"><FontAwesomeIcon icon={faSave} disabled />&nbsp;&nbsp;2. Rechazar Ejecucion</button>&nbsp;&nbsp;

                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                          <div className="row table-responsive" id='eventosTable'>
                            <table className="table table-hover table-condensed table-sm">
                              <thead className="thead-inverse">
                                <tr>
                                  <th className="align-middle"># GASTO</th>
                                  <th className="align-middle">TIPO GASTO</th>
                                  <th className="align-middle">DESCRIPCION</th>
                                  <th className="align-middle">VALOR</th>
                                </tr>
                              </thead>
                              <tbody>
                                {load}
                                {
                                  ListaTablaGastos.map((item, i) => (
                                    <tr key={item.IdGastoAdicional}>
                                      <td>{item.IdGastoAdicional}</td>
                                      <td>{item.TipoGastoId}</td>
                                      <td>{item.DescripcionTipoGasto}</td>
                                      <td>{item.Valor}</td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                          <div className="row table-responsive" id='eventosTable'>
                            <table className="table table-hover table-condensed table-sm">
                              <thead className="thead-inverse">
                                <tr>
                                  <th className="align-middle">TURNO</th>
                                  <th className="align-middle">FECHA INICIO</th>
                                  <th className="align-middle">HORA INICIO</th>
                                  <th className="align-middle">FECHA FIN</th>
                                  <th className="align-middle">HORA FIN</th>
                                </tr>
                              </thead>
                              <tbody>
                                {load}
                                {
                                  ListaTablaTurnos.map((item, i) => (
                                    <tr key={item.IdTurno}>
                                      <td>{item.IdTurno}</td>
                                      <td>{item.FechaInicio}</td>
                                      <td>{item.HoraInicio}</td>
                                      <td>{item.FechaFin}</td>
                                      <td>{item.HoraFin}</td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>


                      <div className="modal-footer">

                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="modal fade" id="ModalRelacionEventos" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
          <div className={"modal-dialog  modal-lg"} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="permisoRolesModalLabel">Detalle del evento <span id='servicio'></span></h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <input type="hidden" id="item" name="item" />
              </div>
              <div className="modal-body">
                <div id="form_registro">

                  <div className="card-body">
                    <div className="card-body btn-showcase">
                      <form id="formulario">
                        <div className="row">
                          <div className="col-xl-12">
                            <div className='row g-3'>


                              <div className="col-md-4">
                                <label htmlFor="nombre">Servicio </label>
                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="ser" id="serevento" aria-describedby="helpId" />
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="nombre">Vehiculo </label>
                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="vh" id="vh" aria-describedby="helpId" />
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="nombre">Nombre Conductor</label>
                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="nc" id="nc" aria-describedby="helpId" />
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="nombre">Descuento</label>
                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="des" id="desc" aria-describedby="helpId" />
                              </div>

                              <div className="col-md-4">
                                <label htmlFor="nombre">Observaciones </label>
                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="fecha" id="obs" aria-describedby="helpId" />
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="nombre">Valor Servicio </label>
                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="HoraCitacion" id="vlrser" aria-describedby="helpId" />
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="nombre">Valor Servicio Inicial </label>
                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="FechaDocumento" id="vlrserini" aria-describedby="helpId" />
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="nombre">Porcentaje Descuento </label>
                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="IdTipoVehiculo" id="porcdes" aria-describedby="helpId" />
                              </div>
                              <div className="col-md-4">
                                <label htmlFor="nombre">Total a Pagar: </label>
                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="ProductoRef" id="totalpagar" aria-describedby="helpId" />
                              </div>
                            </div>

                          </div>
                        </div>
                        <hr />
                        <div className='row'>
                          <div className='col-xl-12 text-center'>
                            <div className="btn-group" role="group" aria-label="">
                              <button type="button" onClick={this.Enviar} className="btn btn-success btn-sm" id="btnservicio"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;1. Confirmar Evento</button>&nbsp;&nbsp;

                              <button type="button" onClick={this.Rechazar} className="btn btn-danger btn-sm" id="btnservicio3"><FontAwesomeIcon icon={faSave} disabled />&nbsp;&nbsp;2. Rechazar Evento</button>&nbsp;&nbsp;

                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="modal-footer">

                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="modal fade" id="ModalVehiculo" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
          <div className={"modal-dialog  modal-lg"} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="permisoRolesModalLabel">Vehiculos registrados</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <input type="hidden" id="item" name="item" />
              </div>
              <div className="modal-body">
                <div id="form_registro">

                  <div className="card-body">
                    <div className="card-body btn-showcase">
                      <form id="formulario_conductores">
                        <div className="row">
                          <table className="table table-hover table-condensed table-sm">
                            <thead className="thead-inverse">
                              <tr>

                                <th className="align-middle">PLACA</th>
                                <th className="align-middle">DOCUMENTO COND</th>
                                <th className="align-middle">PROPIETARIO</th>
                                <th className="align-middle">ESTADO</th>
                                <th className="align-middle">OPCIONES</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                ListaVehiculos.map((item, i) => (
                                  <tr key={item.Placa}>
                                    <td>{item.Placa}</td>
                                    <td>{item.DocPropietario}</td>
                                    <td>{item.Propietario}</td>
                                    <td>{item.Estado}</td>
                                    <td>
                                      <div className="btn-group" role="group" aria-label="">

                                        <button
                                          type="button"
                                          className="btn btn-danger btn-xs"
                                          data-bs-toggle='modal' data-bs-target="#ModalRelacion"
                                          onClick={() => this.InactivarVehiculo(item.Placa)}
                                        >
                                          <FontAwesomeIcon icon={faTrashAlt} /> Inactivar
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                        <hr />

                      </form>

                      <div className="modal-footer">

                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="modal fade" id="ModalConductores" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
          <div className={"modal-dialog  modal-xl"} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="permisoRolesModalLabel">Conductores registrados</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <input type="hidden" id="item" name="item" />
              </div>
              <div className="modal-body">
                <div id="form_registro">

                  <div className="card-body">
                    <div className="card-body btn-showcase">
                      <form id="formulario_conductores">
                        <div className="row">
                          <table className="table table-hover table-condensed table-xs">
                            <thead className="thead-inverse">
                              <tr>

                                <th className="align-middle">Documento</th>
                                <th className="align-middle">Conductor</th>
                                <th className="align-middle">Telefono</th>
                                <th className="align-middle">Correo</th>
                                <th className="align-middle">Estado</th>
                                <th className="align-middle">Opcion</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                ListaConductor.map((item, i) => (
                                  <tr key={item.Documento}>

                                    <td>{item.Documento}</td>
                                    <td>{item.Nombres} {item.Apellidos}</td>
                                    <td>{item.Telefono}</td>
                                    <td>{item.Correo}</td>
                                    <td>{item.Estado}</td>
                                    <td>
                                      <div className="btn-group" role="group" aria-label="">

                                        <button
                                          type="button"
                                          className="btn btn-danger btn-xs"
                                          onClick={() => this.InactivarConductor(item.Documento)}
                                        >
                                          <FontAwesomeIcon icon={faTrashAlt} />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                        <hr />

                      </form>

                      <div className="modal-footer">

                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    );
  }
}

export default Listar;