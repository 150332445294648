import React from 'react'
import Swal from 'sweetalert2';
import { Link,useNavigate   } from "react-router-dom";
import authAxios from "../../services/Token";
import tema from "../../services/Plantilla";
import { DefaultLayout } from '../../layout/theme-customizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import validator from 'validator'

  const defaultLayout = Object.keys(DefaultLayout);
  const layout = defaultLayout


class Editar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        usuario: [],
        roles: [],
        errores: []
    }
    
    cambioValor = (e) => {
        const state = this.state.usuario;
        state[e.target.name] = e.target.value;
        this.setState({ usuario: state });
    };
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    validar(pass){
        if (validator.isStrongPassword(pass, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
          })) {
            
            return 0;
          } else {
            //alert('La contraseña debe de contener minimo 8 caracteres, 1 letra minuscula, 1 mayuscul y un caracter especial!')
            return 1;
          }
    }
    cancelar(){
    
        
        //this.push('/usuarios/Dubai');
        window.location.href = `${process.env.PUBLIC_URL}/pages/usuarios/Dubai`;
        //navigate("/usuarios/Dubai");

    }
    enviarDatos = (e) => {
        e.preventDefault();
        const tipo_documento = document.getElementById('tipo_documento').value;
        const identificacion = document.getElementById('identificacion').value;
        const nombre = document.getElementById('nombre').value;
        const bloqueado = document.getElementById('bloqueado').value;
        const cargo = document.getElementById('cargo').value;
        const password1 = document.getElementById('password1').value;
        const password2 = document.getElementById('password2').value;
        const correo_electronico = document.getElementById('correo_electronico').value;

        if(password1 != ''){
            let x = this.validar(password1);
        
            if(x == 1){
                alert('La contraseña debe de contener minimo 8 caracteres, 1 letra minuscula, 1 mayuscul y un caracter especial!'+x);
                return false;
            }
            if(password1 != password2){
                alert("Las contraseñas deben ser iguales");
                return false;
            }
        }
        
       
        //return false;

        var v = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(correo_electronico)
        if(!v){
            alert("Digite un email valido");
            return false;
        }

        var errores = [];
        if (!tipo_documento) { errores.push("error_tipo_documento"); }
        if (!identificacion) { errores.push("error_identificacion"); }
        if (!nombre) { errores.push("error_nombre"); }
        if (!bloqueado) { errores.push("error_bloqueado"); }
        if (!cargo) { errores.push("error_usuario"); }
        /*  if (!password1) { errores.push("error_password1"); }
         if (!password2) { errores.push("error_password2"); } */
        if (!correo_electronico) { errores.push("error_correo_electronico"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        

        authAxios.post("usuario/editar", formData)
            .then((datosRespuesta) => {
                
                Swal.fire(
                    'Usuario!',
                    datosRespuesta.data.msj,
                    'success'
                )
                this.cancelar();
               
                
            })
            .catch()
    }
    componentDidMount() {
        Swal.showLoading();
        authAxios.get("usuario/roles")
            .then((datosRespuesta) => {
                
                this.setState({ datosCargados: true, roles: datosRespuesta.data.data, load: '' });
                Swal.close();
            })
            .catch();

        var ide = localStorage.getItem('ide');
        
        authAxios.get("usuario/ver/"+ide)
            .then((datosRespuesta) => {
                
                this.setState({ datosCargados: true, usuario: datosRespuesta.data.data, load: '' });
            })
            .catch();
        
    }
    render() {
        const { load, usuario, roles } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                {load ? (<b>{load}</b>) : (<b>EDITAR USUARIO</b>)}
                            </div>
                            <div className="card-body">
                              
                                <form onSubmit={this.enviarDatos} id="form" autoComplete="off">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-2">
                                                    <input type="hidden" name="id" defaultValue={usuario.id} />
                                                    <label htmlFor="tipo_documento">T. Identificación</label>
                                                    <select name="tipo_documento"
                                                        id="tipo_documento"
                                                        value={usuario.tipo_documento}
                                                        options={usuario.tipo_documento}
                                                        className={((this.verificarError("error_tipo_documento")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el T.I.</option>
                                                        <option value="CC">C.C.</option>
                                                        <option value="CE">C.E.</option>
                                                        <option value="NIT">NIT</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el T.I.</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="identificacion">Identificación</label>
                                                    <input type="text" name="identificacion" id="identificacion" defaultValue={usuario.identificacion}
                                                        className={((this.verificarError("error_identificacion")) ? "is-invalid" : "") + " form-control form-control-sm"} maxLength={20} placeholder="IDENTIFICACIÓN" aria-describedby="helpId"
                                                        onChange={this.cambioValor}
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite la Identificación</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="nombre">Nombre</label>
                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="nombre" id="nombre" defaultValue={usuario.nombre}
                                                        className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} placeholder="NOMBRE" aria-describedby="helpId"
                                                        onChange={this.cambioValor}
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="bloqueado">Estado</label>
                                                    <select name="bloqueado"
                                                        id="bloqueado"
                                                        value={usuario.bloqueado}
                                                        options={usuario.bloqueado}
                                                        className={((this.verificarError("error_bloqueado")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el Estado</option>
                                                        <option value="0">Activo</option>
                                                        <option value="1">Inactivo</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Estado</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="id_rol">Rol</label>
                                                    <select name="id_rol"
                                                        id="id_rol"
                                                        value={usuario.id_rol}
                                                        options={usuario.id_rol}
                                                        className={((this.verificarError("error_rol")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el Rol</option>
                                                        {
                                                            roles.map((itemRoles) => (
                                                                <option key={itemRoles.id} value={itemRoles.id}>{itemRoles.nombre}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Rol</small>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                <div className="col-md-2">
                                                    <label htmlFor="cargo">Cargo</label>
                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="cargo" id="cargo" defaultValue={usuario.cargo}
                                                        className={((this.verificarError("error_cargo")) ? "is-invalid" : "") + " form-control form-control-sm"} placeholder="Cargo" aria-describedby="helpId"
                                                        onChange={this.cambioValor} autoComplete="off"
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite el cargo</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="password1">Password</label>
                                                    <input style={{ textTransform: 'uppercase' }} type="password" name="password1" id="password1"
                                                        className="form-control form-control-sm" placeholder="PASSWORD" aria-describedby="helpId"
                                                        onChange={this.cambioValor}   autocomplete="new-password"
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite el Password</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="password2">Confirmar Password</label>
                                                    <input style={{ textTransform: 'uppercase' }} type="password" name="password2" id="password2"
                                                        className={((this.verificarError("error_password2")) ? "is-invalid" : "") + " form-control form-control-sm"} placeholder="CONFIRMAR PASS." aria-describedby="helpId"
                                                        onChange={this.cambioValor}  autocomplete="new-password"
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite el Password</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="correo_electronico">Correo Electrónico</label>
                                                    <input style={{ textTransform: 'lowercase' }} type="text" name="correo_electronico" id="correo_electronico" defaultValue={usuario.email}
                                                        className={((this.verificarError("error_correo_electronico")) ? "is-invalid" : "") + " form-control form-control-sm"} placeholder="CORREO ELECTRÓNICO" aria-describedby="helpId"
                                                        onChange={this.cambioValor}
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="submit" className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp; Editar Usuario</button>&nbsp;&nbsp;
                                                <Link className="btn btn-danger btn-sm" to={`${process.env.PUBLIC_URL}/pages/usuarios/${tema}`}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Editar;