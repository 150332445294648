import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { DefaultLayout } from '../../layout/theme-customizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faSave, faUser, faCar, faEdit, faFilter, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import tema from "../../services/Plantilla";

let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy =
    f[2].padStart(2, "0") +
    "-" +
    f[1].padStart(2, "0") +
    "-" +
    f[0].padStart(2, "0");
let fechaActual2 = new Date();
fechaActual2.setDate(fechaActual2.getDate() - 5);

let f2 = fechaActual2.toLocaleDateString().split("/");
let haceDosDias =
    f2[2].padStart(2, "0") +
    "-" +
    f2[1].padStart(2, "0") +
    "-" +
    f2[0].padStart(2, "0");

class Listar extends React.Component {
    constructor(props) {
        super(props);
        this.Enviar = this.Enviar.bind(this);
    }
    state = {
        datosCargados: false,
        ListaTabla: [],
        ListaVehiculos: [],
        ListaConductor: [],
        selectedItems: [],
        Hoy: hoy,
        haceDosDias: haceDosDias
    }
    borrarRegistros = (id) => {
        Swal.fire({
            title: 'Está seguro de Eliminar el registro?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Borrar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                authAxios.get("contrato/borrar/" + id)
                    .then((datosRespuesta) => {

                        Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                        this.MostrarTabla(1);
                    })
                    .catch();
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };
    handleCheckboxChange = (event, itemId) => {
        const { selectedItems } = this.state;
        if (event.target.checked) {
            this.setState({ selectedItems: [...selectedItems, itemId] });
        } else {
            this.setState({ selectedItems: selectedItems.filter(id => id !== itemId) });
        }
    }
    handleFinalSubmit = () => {
        console.log("Selected Items:", this.state.selectedItems);
        Swal.fire({
            title: '¿Está seguro de importar los servicios?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Importar',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                const { selectedItems } = this.state;
                console.log(selectedItems);
                if (selectedItems.length === 0) {
                    Swal.fire(
                        'Debes de seleccionar los servicios',
                        '',
                        'warning'
                    );
                    return false;
                }

                var datos = { id: selectedItems }
                Swal.showLoading();
                authAxios.post("exterior/guardarseleccionado-servicios-caracolmasivo", datos)
                    .then((datosRespuesta) => {
                        Swal.fire(
                            'Se importaron con exito',
                            '',
                            'success'
                        );
                        document.getElementById('formulario').reset();
                        this.MostrarTabla(1);
                    })
                    .catch(error => {
                        console.error('Error al importar los servicios:', error);
                    });
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info');
            }
        });
        // Aquí puedes manejar los items seleccionados, por ejemplo, enviarlos a un servidor o realizar alguna acción con ellos
    }
    LinkEditar(ide) {
        localStorage.setItem('ide', ide);

        //window.location.href = process.env.PUBLIC_URL+'/tipo_contrato/editar/'+tema;
    }

    MostrarTabla() {
        Swal.showLoading();
        let bname = document.getElementById('bnombre').value;
        if (bname == '') {
            bname = 0;
        }
        let bfecha = document.getElementById('bfecha').value;
        if (bfecha == '') {
            bfecha = 0;
        }
        let hfecha = document.getElementById('hfecha').value;
        if (hfecha == '') {
            hfecha = 0;
        }
        authAxios.get("transporte/consultarservicios/" + bname + "/" + bfecha + "/" + hfecha)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                console.log(datosRespuesta.data[0]?.datos);

                this.setState({ datosCargados: true, load: '', ListaTabla: datosRespuesta.data[0]?.datos });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    componentDidMount() {
        // this.MostrarTabla(1);
    }
    Enviar() {
        let form = document.getElementById('formulario');
        let formData = new FormData(form);
        Swal.showLoading();
        authAxios.post("transporte/guardarservicio", formData)
            .then((datosRespuesta) => {
                Swal.close();
                Swal.fire(
                    'Servicio!',
                    datosRespuesta.data.msj.MensajeRespuesta,
                    'success'
                );

                document.getElementById('formulario').reset();
                this.MostrarTabla(1);
            })
            .catch()

    }
    Rechazar() {
        var iser = document.getElementById("ser").value;

        authAxios.get("transporte/confirmarsolicitudes/" + iser)
            .then((datosRespuesta) => {

                Swal.fire(
                    'Clientes!',
                    datosRespuesta.data.MensajeRespuesta,
                    'success'
                );
                this.MostrarTabla(1);
            })
            .catch()
    }
    VerServicio(i) {
        Swal.showLoading();

        let bname = document.getElementById('bnombre').value;
        if (bname == '') {
            bname = 0;
        }
        let bfecha = document.getElementById('bfecha').value;
        if (bfecha == '') {
            bfecha = 0;
        }
        let hfecha = document.getElementById('hfecha').value;
        if (hfecha == '') {
            hfecha = 0;
        }
        authAxios.get("transporte/consultarservicios/" + i + "/" + bfecha + "/" + hfecha)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                const estadoMap = {
                    1: 'Solicitado',
                    10: 'Por confirmar',
                    14: 'Asignado',
                    19: 'Bloqueado',
                    9: 'En ejecución',
                    11: 'Ejecutado',
                    20: 'Por confirmar'
                };
                console.log(datosRespuesta.data[0].datos[0]);
                // this.setState({ datosCargados: true, load: '', ListaTabla: datosRespuesta.data[0]?.datos });
                document.getElementById("servicio").innerHTML = datosRespuesta.data[0].datos[0].IdServicio;

                document.getElementById("ser").value = datosRespuesta.data[0].datos[0].IdServicio;
                document.getElementById("NumeroPedido").value = datosRespuesta.data[0].datos[0].NumeroPedido + '-' + datosRespuesta.data[0].datos[0].Posicion;
                document.getElementById("NombresSolicitante").value = datosRespuesta.data[0].datos[0].NombresSolicitante;
                document.getElementById("NumeroSolicitud").value = datosRespuesta.data[0].datos[0].NumeroSolicitud;
                document.getElementById("fecha").value = new Date(datosRespuesta.data[0].datos[0].FechaServicio).toISOString().split('T')[0];
                document.getElementById("HoraCitacion").value = datosRespuesta.data[0].datos[0].HoraCitacion;
                document.getElementById("FechaDocumento").value = datosRespuesta.data[0].datos[0].FechaDocumento;
                document.getElementById("IdTipoVehiculo").value = datosRespuesta.data[0].datos[0].IdTipoVehiculo;
                document.getElementById("ProductoRef").value = datosRespuesta.data[0].datos[0].ProductoRef;
                document.getElementById("Destino").value = datosRespuesta.data[0].datos[0].Destino;
                document.getElementById("PersonasTransportar").value = datosRespuesta.data[0].datos[0].PersonasTransportar;
                document.getElementById("Contacto").value = datosRespuesta.data[0].datos[0].Contacto;
                document.getElementById("Direccion").value = datosRespuesta.data[0].datos[0].Direccion;
                document.getElementById("Telefono").value = datosRespuesta.data[0].datos[0].Telefono;
                document.getElementById("Email").value = datosRespuesta.data[0].datos[0].Email;
                document.getElementById("Descripcion").value = datosRespuesta.data[0].datos[0].Descripcion;



                document.getElementById("Estado").value = estadoMap[datosRespuesta.data[0].datos[0].IdEstadoServicio];



                document.getElementById("CantidadPedido").value = datosRespuesta.data[0].datos[0].CantidadPedido;
                document.getElementById("Proveedor").value = datosRespuesta.data[0].datos[0].Proveedor;
                document.getElementById("IdSolicitante").value = datosRespuesta.data[0].datos[0].IdSolicitante;
                document.getElementById("OI").value = datosRespuesta.data[0].datos[0].OI;
                var est = datosRespuesta.data[0].estado;
                // if (est == 'Sin registrar') {
                //     document.getElementById("btnservicio").disabled = false;
                //     document.getElementById("btnservicio2").disabled = true;
                //     document.getElementById("btnservicio3").disabled = true;
                // } else {
                document.getElementById("btnservicio").disabled = false;
                // document.getElementById("btnservicio2").disabled = false;
                document.getElementById("btnservicio3").disabled = false;
                // }

            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    ConsultarVehiculos() {
        authAxios.get("transporte/ConsultarVehiculos")
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;


                this.setState({ datosCargados2: true, ListaVehiculos: datosRespuesta.data.ListaVehiculos });
            })
            .catch();

    }
    ConsultarConductores() {
        this.setState({ datosCargados3: false, ListaConductor: [] }); // Vaciar los datos y establecer datosCargados3 en false

        authAxios.get("transporte/ConsultarConductores")
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;


                this.setState({ datosCargados3: true, ListaConductor: datosRespuesta.data.ListaConductor });
            })
            .catch();
    }
    InactivarVehiculo(doc) {

        authAxios.get("transporte/InactivarVehiculo/" + doc)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;

                alert(datosRespuesta.data.MensajeRespuesta);
                this.ConsultarVehiculos();
            })
            .catch();

    }
    InactivarConductor(doc) {

        authAxios.get("transporte/InactivarConductor/" + doc)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;

                Swal.fire({
                    title: 'Conductor Desactivado',
                    icon: 'success',
                });
                this.ConsultarVehiculos();
            })
            .catch();

    }
    componentDidMount() {

        this.MostrarTabla(1);

    }
    getEstadoDescripcion = (idEstado) => {
        switch (idEstado) {
            case 1:
                return 'Solicitado';
            case 10:
                return 'Por confirmar';
            case 14:
                return 'Asignado';
            case 19:
                return 'Bloqueado';
            case 9:
                return 'En ejecución';
            case 11:
                return 'Ejecutado';
            case 20:
                return 'Por confirmar';
            default:
                return 'Estado desconocido';
        }
    };
    render() {
        const { load, ListaTabla, pagina, ultimo, actual, Hoy, haceDosDias, ListaVehiculos, ListaConductor } = this.state;
        return (
            <div className="content">
                <div className="container-fluid">
                    <br />
                    <div className="card">

                        <div className="card-body">

                            <h4>Lista Servicios caracol</h4>
                            <button className="btn btn-primary" slot='end' data-bs-toggle='modal' data-bs-target="#ModalConductores" onClick={() => this.ConsultarConductores()}> <FontAwesomeIcon icon={faUser} /> Conductores</button>&nbsp;&nbsp;
                            <button className="btn btn-primary" slot='end' data-bs-toggle='modal' data-bs-target="#ModalVehiculo" onClick={() => this.ConsultarVehiculos()}> <FontAwesomeIcon icon={faCar} /> Vehiculos</button>
                            <hr />
                            <div className="row">
                                <div className="col-xl-3">
                                    <input style={{ textTransform: 'uppercase' }} type="text" id="bnombre" name="bnombre" placeholder="Buscar por expediente" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                    <input style={{ textTransform: 'uppercase' }} type="date" id="hfecha" name="hfecha" placeholder="Buscar por fecha" className="form-control form-control-sm" defaultValue={haceDosDias} />
                                </div>
                                <div className="col-xl-3">
                                    <input style={{ textTransform: 'uppercase' }} type="date" id="bfecha" name="bfecha" placeholder="Buscar por fecha" className="form-control form-control-sm" defaultValue={Hoy} />
                                </div>
                                <div className="col-xl-3">
                                    <button id="bt" name="bt" className="btn btn-primary btn-xs" onClick={() => this.MostrarTabla(1)}>&nbsp;&nbsp;Filtrar</button>&nbsp;&nbsp;&nbsp;&nbsp; <button onClick={this.handleFinalSubmit} className="btn btn-secondary ml-4 btn-xs">Importar Servicios</button>
                                </div>
                            </div>
                            <br></br>
                            <div className="row table-responsive">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                            <th className="align-middle">ID</th>
                                            <th className="align-middle">NUMERO EXP</th>
                                            <th className="align-middle">FECHA SERVICIO</th>
                                            <th className="align-middle">HORA</th>
                                            <th className="align-middle">SOLICITANTE</th>
                                            <th className="align-middle">PERSONAL</th>
                                            <th className="align-middle">ESTADO</th>
                                            <th className="align-middle">CONTROL</th>
                                            <th className="align-middle">OPCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {
                                            ListaTabla.map((item, i) => (
                                                <tr key={item.IdServicio}>
                                                    <td>{item.IdServicio}</td>
                                                    <td>{item.NumeroPedido}-{item.Posicion}</td>
                                                    <td>{new Date(item.FechaServicio).toISOString().split('T')[0]}</td>
                                                    <td>{item.HoraCitacion}</td>
                                                    <td>{item.NombresSolicitante}</td>
                                                    <td>{item.PersonasTransportar}</td>
                                                    <td>{item.IdEstadoServicio}.{this.getEstadoDescripcion(item.IdEstadoServicio)}</td>
                                                    <td>{item.estado == 'Registrado' ? <span className="badge bg-success">{item.estado}</span> : <span className="badge bg-danger">{item.estado}</span>}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <button
                                                                type="button"
                                                                className="btn btn-info btn-xs"
                                                                data-bs-toggle='modal'
                                                                data-bs-target="#ModalRelacion"
                                                                onClick={() => this.VerServicio(item.IdServicio)}
                                                            >
                                                                <FontAwesomeIcon icon={faInfo} /> Ver
                                                            </button>

                                                        </div>
                                                        {item.estado != 'Registrado' ? (
                                                            <input
                                                                type='checkbox'
                                                                name={`checkbox-${item.IdServicio}`}
                                                                value={item.IdServicio}
                                                                className='m-2'
                                                                onChange={(e) => this.handleCheckboxChange(e, item)}
                                                            />
                                                        ) : ''}

                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="ModalRelacion" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-lg"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Detalle del servicio <span id='servicio'></span></h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                <input type="hidden" id="item" name="item" />
                            </div>
                            <div className="modal-body">
                                <div id="form_registro">

                                    <div className="card-body">
                                        <div className="card-body btn-showcase">
                                            <form id="formulario">
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className='row g-3'>


                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Id Servicio </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="ser" id="ser" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Numero Expediente </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="NumeroPedido" id="NumeroPedido" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Nombres del Solicitante </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="NombresSolicitante" id="NombresSolicitante" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Numero de solicitud </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="NumeroSolicitud" id="NumeroSolicitud" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Fecha Servicio </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="fecha" id="fecha" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Hora </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="HoraCitacion" id="HoraCitacion" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Fecha de Registro </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="FechaDocumento" id="FechaDocumento" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Tipo vehiculo </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="IdTipoVehiculo" id="IdTipoVehiculo" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Producto referencia: </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="ProductoRef" id="ProductoRef" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>


                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Destino </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="Destino" id="Destino" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Personas a transportar </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="PersonasTransportar" id="PersonasTransportar" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Contacto </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="Contacto" id="Contacto" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Direccion </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="Direccion" id="Direccion" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Telefono </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="Telefono" id="Telefono" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Email </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="Email" id="Email" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Estado </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="Estado" id="Estado" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Cantidad de Pedido </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="CantidadPedido" id="CantidadPedido" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">Proveedor </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="Proveedor" id="Proveedor" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="nombre">IdSolicitante </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="IdSolicitante" id="IdSolicitante" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label htmlFor="nombre">OI </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="OI" id="OI" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <label htmlFor="nombre">Descripcion </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm"} type="text" name="Descripcion" id="Descripcion" aria-describedby="helpId" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <hr />
                                                <div className='row'>
                                                    <div className='col-xl-12 text-center'>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <button type="button" onClick={this.Enviar} className="btn btn-success btn-sm" id="btnservicio"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;1. Guardar y Confirmar Servicio</button>&nbsp;&nbsp;

                                                            <button type="button" onClick={this.Rechazar} className="btn btn-danger btn-sm" id="btnservicio3"><FontAwesomeIcon icon={faSave} disabled />&nbsp;&nbsp;2. Rechazar Servicio</button>&nbsp;&nbsp;

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                            <div className="modal-footer">

                                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="modal fade" id="ModalVehiculo" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-lg"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Vehiculos registrados</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                <input type="hidden" id="item" name="item" />
                            </div>
                            <div className="modal-body">
                                <div id="form_registro">

                                    <div className="card-body">
                                        <div className="card-body btn-showcase">
                                            <form id="formulario_conductores">
                                                <div className="row">
                                                    <table className="table table-hover table-condensed table-sm">
                                                        <thead className="thead-inverse">
                                                            <tr>

                                                                <th className="align-middle">PLACA</th>
                                                                <th className="align-middle">DOCUMENTO COND</th>
                                                                {/* <th className="align-middle">PROPIETARIO</th> */}
                                                                <th className="align-middle">ESTADO</th>
                                                                <th className="align-middle">OPCIONES</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                ListaVehiculos.map((item, i) => (
                                                                    <tr key={item.Placa}>
                                                                        <td>{item.Placa}</td>
                                                                        <td>{item.DocPropietario}</td>
                                                                        {/* <td>{item.Propietario}</td> */}
                                                                        <td>
                                                                            {item.Estado === 6 ? (
                                                                                <b className="bg-danger">Inactivo</b>
                                                                            ) : (
                                                                                <b className="bg-success">Activo</b>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <div className="btn-group" role="group" aria-label="">

                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-danger btn-xs"
                                                                                    data-bs-toggle='modal' data-bs-target="#ModalRelacion"
                                                                                    onClick={() => this.InactivarVehiculo(item.Placa)}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faTrashAlt} /> Inactivar
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <hr />

                                            </form>

                                            <div className="modal-footer">

                                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="modal fade" id="ModalConductores" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-xl"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Conductores registrados</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                <input type="hidden" id="item" name="item" />
                            </div>
                            <div className="modal-body">
                                <div id="form_registro">

                                    <div className="card-body">
                                        <div className="card-body btn-showcase">
                                            <form id="formulario_conductores">
                                                <div className="row">
                                                    <table className="table table-hover table-condensed table-xs">
                                                        <thead className="thead-inverse">
                                                            <tr>

                                                                <th className="align-middle">Documento</th>
                                                                <th className="align-middle">Conductor</th>
                                                                <th className="align-middle">Telefono</th>
                                                                <th className="align-middle">Correo</th>
                                                                <th className="align-middle">Estado</th>
                                                                <th className="align-middle">Opcion</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                ListaConductor.map((item, i) => (
                                                                    <tr key={item.Documento}>

                                                                        <td>{item.Documento}</td>
                                                                        <td>{item.Nombres} {item.Apellidos}</td>
                                                                        <td>{item.Telefono}</td>
                                                                        <td>{item.Correo}</td>
                                                                        <td>
                                                                            {item.Estado === 6 ? (
                                                                                <b className="bg-danger">Inactivo</b>
                                                                            ) : (
                                                                                <b className="bg-success">Activo</b>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <div className="btn-group" role="group" aria-label="">

                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-danger btn-xs"
                                                                                    onClick={() => this.InactivarConductor(item.Documento)}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <hr />

                                            </form>

                                            <div className="modal-footer">

                                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default Listar;