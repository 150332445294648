import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faPlus, faTrashAlt, faClipboardCheck, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons';
import tema from "../../services/Plantilla";
import Modat from "../ui-kits/modal";


class Listar extends React.Component {
    constructor(props) {
        super(props);

    }


    state = {
        load: '',
        datosCargados: false,
        roles: [],
        Modulos: [],
        Permisos: []
    }
    borrarRegistros = (id) => {
        Swal.fire({
            title: 'Está seguro de Eliminar el Rol?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Borrar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                authAxios.get("roles/delroles/" + id)
                    .then((datosRespuesta) => {
                        
                        Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                        this.ListarRoles(1);
                    })
                    .catch();
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };
    copy = (id) => {
        Swal.fire({
            title: 'Está seguro de copiar este Rol?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Copiar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                authAxios.get("roles/copyroles/" + id)
                    .then((datosRespuesta) => {
                        
                        Swal.fire('COPIAR!', datosRespuesta.data.msj, 'success')
                        this.ListarRoles(1);
                    })
                    .catch();
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };
    ListarRoles(page) {
        Swal.showLoading();
        let bname = document.getElementById('bnombre').value;
        if (bname == '') {
            bname = 0;
        }
        authAxios.get("roles/index/" + bname + "?page=" + page)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                
                
                this.setState({ datosCargados: true, load: '', roles: datosRespuesta.data.data.data, pagina: datosRespuesta.data.data.total, ultimo: datosRespuesta.data.data.last_page, actual: datosRespuesta.data.data.current_page });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    ListarPermisos(id) {
        Swal.showLoading();

        authAxios.get("roles/permisos/" + id)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                
                this.setState({ Permisos: datosRespuesta.data.data });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    pasar = (id) => {
        document.getElementById('id_rol').value = id;
        this.ListarPermisos(id);


    }
    modulos = () => {
        authAxios.get("roles/modulos")
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;
                

                this.setState({ Modulos: datosRespuesta.data.data });
            })
            .catch();
    }
    verPermiso = (id) => {

        authAxios.get("roles/verpermisos/" + id)
            .then((datosRespuesta) => {

                var status = datosRespuesta.data.status;
                
                var f = datosRespuesta.data;
                
                document.getElementById('id_modulo').value = f.id_modulo;
                document.getElementById('registrar').value = f.registrar;
                document.getElementById('editar').value = f.editar;
                document.getElementById('eliminar').value = f.eliminar;
                document.getElementById('consultar').value = f.consultar;
                document.getElementById('cargar').value = f.cargar;
                document.getElementById('id').value = id;

            })
            .catch();
    }
    borrarPermiso = (id) => {
        Swal.fire({
            title: 'Está seguro de Eliminar el Permiso?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Borrar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                authAxios.get("roles/delpermiso/" + id)
                    .then((datosRespuesta) => {
                        
                        Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                        this.ListarPermisos(document.getElementById('id_rol').value);
                    })
                    .catch();
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };

    save() {
        var datos = new FormData(document.getElementById("form_roles"));
        
        authAxios.post("roles/addpermisos", datos)
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;
                
                this.ListarPermisos(document.getElementById('id_rol').value);
                if (datosRespuesta.data.Status) {
                    Swal.fire(
                        'Permiso!',
                        datosRespuesta.data.msj,
                        'success'
                    )

                } else {
                    Swal.fire(
                        'Permiso!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch()
    }
    LinkEditar(ide) {
        localStorage.setItem('ide', ide);
        
        window.location.href = process.env.PUBLIC_URL + '/roles/editar/Dubai';
    }
    CambiarPermiso(id, campo) {
        var che = document.getElementById(campo + id);
        var valor = 0;
        if (che.checked == true) {
            valor = 1;
        }
        Swal.showLoading();

        authAxios.get("roles/uppermiso/" + id + '/' + campo + '/' + valor)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                
                this.ListarPermisos(document.getElementById('id_rol').value);
            })
            .catch();

    }

    componentDidMount() {
        this.ListarRoles(1);
        this.modulos();
    }
    render() {
        const { load, roles, pagina, ultimo, actual, Modulos, Permisos, modal } = this.state;
        return (

            <div className="content">
                <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Asignacion de permisos</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <form id="form_roles">
                                                <div className="row g-3">
                                                    <div className="col-md-3">
                                                        <label htmlFor="cedula">Modulo</label>
                                                        <input className={"form-control form-control-sm"} type="hidden" name="id_rol" id="id_rol" placeholder="" />
                                                        <input className={"form-control form-control-sm"} type="hidden" name="id" id="id" placeholder="" />
                                                        <select className={"form-control form-control-sm"} name="id_modulo" id="id_modulo">

                                                            <option value="0">Seleccione Modulo</option>
                                                            {
                                                                Modulos.map((item) => (
                                                                    <option value={item.id}>{item.titulo}</option>
                                                                ))
                                                            }

                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="cedula">Registrar</label>
                                                        <select className={"form-control form-control-sm"} name="registrar" id="registrar">
                                                            <option value="0">No</option>
                                                            <option value="1">Si</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="cedula">Editar</label>
                                                        <select className={"form-control form-control-sm"} name="editar" id="editar">
                                                            <option value="0">No</option>
                                                            <option value="1">Si</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="cedula">Eliminar</label>
                                                        <select className={"form-control form-control-sm"} name="eliminar" id="eliminar">
                                                            <option value="0">No</option>
                                                            <option value="1">Si</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="cedula">Consultar</label>
                                                        <select className={"form-control form-control-sm"} name="consultar" id="consultar">
                                                            <option value="0">No</option>
                                                            <option value="1">Si</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="cedula">Cargar Archivos</label>
                                                        <select className={"form-control form-control-sm"} name="cargar" id="cargar">
                                                            <option value="0">No</option>
                                                            <option value="1">Si</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="cedula">Configuracion</label>
                                                        <select className={"form-control form-control-sm"} name="configuracion" id="configuracion">
                                                            <option value="0">No</option>
                                                            <option value="1">Si</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="cedula">Ver solo mis datos</label>
                                                        <select className={"form-control form-control-sm"} name="datos" id="datos">
                                                            <option value="0">No</option>
                                                            <option value="1">Si</option>
                                                        </select>
                                                    </div>
                                                    <button type="button" className="btn btn-primary" onClick={() => this.save()}>Agregar Permiso</button>
                                                </div>
                                            </form>
                                        </div>
                                        <br></br>
                                        <div className="row g-3 table-responsive">
                                            <table className="table table-hover table-condensed table-sm">
                                                <thead className="thead-inverse">
                                                    <tr>

                                                        <th className="align-middle">MENU</th>
                                                        <th className="align-middle">REGISTRAR</th>
                                                        <th className="align-middle">EDITAR</th>
                                                        <th className="align-middle">BORRAR</th>
                                                        <th className="align-middle">VER</th>
                                                        <th className="align-middle">CARGAR</th>
                                                        <th className="align-middle">CONF</th>
                                                        <th className="align-middle">MIS DATOS</th>
                                                        <th className="align-middle">OPC</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        Permisos.map((item) => (
                                                            <tr key={item.id} >

                                                                <td className="align-middle">{item.titulo}<br></br><span style={{ fontSize: '10px' }}>{item.modulo}</span></td>
                                                                <td className="align-middle"> <input type="checkbox" title='Registrar datos' id={"registrar" + item.id} checked={item.registrar == '1' ? true : false} onClick={() => this.CambiarPermiso(item.id, 'registrar')} /></td>
                                                                <td className="align-middle"><input type="checkbox" title='editar datos' id={"editar" + item.id} checked={item.editar == '1' ? true : false} onClick={() => this.CambiarPermiso(item.id, 'editar')} /></td>
                                                                <td className="align-middle"><input type="checkbox" title='eliminar datos' id={"eliminar" + item.id} checked={item.eliminar == '1' ? true : false} onClick={() => this.CambiarPermiso(item.id, 'eliminar')} /></td>
                                                                <td className="align-middle"><input type="checkbox" title='consultar lista' id={"consultar" + item.id} checked={item.consultar == '1' ? true : false} onClick={() => this.CambiarPermiso(item.id, 'consultar')} /></td>
                                                                <td className="align-middle"><input type="checkbox" title='cargar archivos' id={"cargar" + item.id} checked={item.cargar == '1' ? true : false} onClick={() => this.CambiarPermiso(item.id, 'cargar')} /> </td>
                                                                <td className="align-middle"><input type="checkbox" title='Ver configuracion' id={"configuracion" + item.id} checked={item.configuracion == '1' ? true : false} onClick={() => this.CambiarPermiso(item.id, 'configuracion')} /> </td>
                                                                <td className="align-middle"><input type="checkbox" title='Ver mis datos solamente?' id={"datos" + item.id} checked={item.datos == '1' ? true : false} onClick={() => this.CambiarPermiso(item.id, 'datos')} /> </td>
                                                                <td className="align-middle">

                                                                    {/*  <button type="button" className="btn btn-primary btn-xs" onClick={() => this.verPermiso(item.id)}>
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                            </button> */}

                                                                    <button type="button" className="btn btn-danger btn-xs" onClick={() => this.borrarPermiso(item.id)}>
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </button>
                                                                </td>

                                                            </tr>

                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>

                            </div>
                        </div>
                    </div>
                </div>




                <div className="container-fluid">
                    <br />
                    <div className="card">
                        <div className="card-header">
                            <Link className="btn btn-success btn-sm" to={"/roles/crear/" + tema}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Rol</Link>
                        </div>
                        <div className="card-body">
                            <h4>Lista Roles</h4>
                            <hr />
                            <div className="row">
                                <div className="col-xl-3">
                                    <input style={{ textTransform: 'uppercase' }} type="text" id="bnombre" name="bnombre" placeholder="ROL" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                    <button id="bt" name="bt" className="btn btn-secondary btn-sm" onClick={() => this.ListarRoles(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br>
                            <div className="row table-responsive">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                            <th className="align-middle">NOMBRE</th>
                                            <th className="align-middle">DESCRIPCIÓN</th>
                                            <th className="align-middle">OPCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {
                                            roles.map((itemRoles) => (
                                                <tr key={itemRoles.id}>
                                                    <td>{itemRoles.nombre}</td>
                                                    <td>{itemRoles.descripcion}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">

                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-xs"
                                                                onClick={() =>
                                                                    this.LinkEditar(itemRoles.id)
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="btn btn-danger btn-xs"
                                                                onClick={() =>
                                                                    this.borrarRegistros(itemRoles.id)
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-warning btn-xs"

                                                                onClick={() =>
                                                                    this.copy(itemRoles.id)
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faCopy} />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-info btn-xs"
                                                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                onClick={() =>
                                                                    this.pasar(itemRoles.id)
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faPlus} />
                                                            </button>

                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarRoles(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarRoles(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                    {(() => {
                                        let p = [];
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarRol(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarRoles(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarRoles(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Listar;