import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes,faPlus } from '@fortawesome/free-solid-svg-icons';
import { DefaultLayout } from '../../layout/theme-customizer';

const id = window.location.pathname.split('/').pop()
  const defaultLayout = Object.keys(DefaultLayout);
  const layout = id ? id : defaultLayout
  const usuario = localStorage.getItem('nombre');
class Crear extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        Idfactura: localStorage.getItem('Factura'),
        Consecutivo: '',
        nombre: '',
        Idcli:'',
        Estado:1,
        Documento: '',
        TipoDoc: '',
        Telefono: '',
        Email: '',
        Direccion: '',
        Aprobado:localStorage.getItem('nombre'),
        Observaciones:'',
        Neto:0,
        Iva:0,
        Total:0,
        Listado: [],
        ListaDetalle: [],
        FechaActual:'',FechaVence:'',
        ListaCliente: [], actual: '',ultimo: '',pagina: '',
        ListaServicio: [],actual_ser: '',ultimo_ser: '',pagina_ser: '',
        ListaExtra: [],actual_ext: '',ultimo_ext: '',pagina_ext: '',total_ext: 0,
        ListaTarifa: [],actual_tar: '',ultimo_tar: '',pagina_tar: '',
    }

    enviarDatos = (e) => {
        e.preventDefault();
        const {nombre, estado } = this.state;
        var errores = [];
        
        if (!nombre) { errores.push("error_nombre"); }
        if (!estado) { errores.push("error_estado"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        var datosEnviar = {
          
            nombre: nombre,
            estado: estado
          
        }
        authAxios.post("banco/registrar", datosEnviar)
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;
               
                
                if (datosRespuesta.data.Status) {
                    Swal.fire(
                        'bancos!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    this.props.history.push("/MasterBancos");
                } else {
                    Swal.fire(
                        'bancos!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch()
    }
    MostrarCliente(page){
        //Swal.showLoading();
        let cc = document.getElementById('b_cedula').value;
        let nm = document.getElementById('b_nombre').value;
        var datos = {ced: cc,nombre: nm}
        authAxios.post("facturacion/lista_customer?page="+page, datos)
            .then((datosRespuesta) => {
                //Swal.close();
                var status = datosRespuesta.data.status;
                
                
                this.setState({ ListaCliente: datosRespuesta.data.data, pagina: datosRespuesta.data.total, ultimo: datosRespuesta.data.last_page, actual: datosRespuesta.data.current_page });
            })
        .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    MostrarTarifa(page){
        const {Idcli } = this.state;

        //Swal.showLoading();
        let tar = document.getElementById('b_tarifa').value;
        
        var datos = {tar: tar,cli: Idcli}
        authAxios.post("facturacion/lista_tarifa?page=" + page, datos)
            .then((datosRespuesta) => {
                //Swal.close();
                var status = datosRespuesta.data.status;
                
                
                this.setState({ ListaTarifa: datosRespuesta.data.data, pagina_tar: datosRespuesta.data.total, ultimo_tar: datosRespuesta.data.last_page, actual_tar: datosRespuesta.data.current_page });
            })
        .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    VerCliente(id){
        Swal.showLoading();
        var datos = {id: id}
        authAxios.post("facturacion/ver_cliente", datos)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                
                
                this.setState({
                     nombre: datosRespuesta.data.dato.nombres+' '+datosRespuesta.data.dato.apellidos,
                     Documento: datosRespuesta.data.dato.documento,
                     TipoDoc: datosRespuesta.data.dato.tip_doc,
                     Telefono: datosRespuesta.data.dato.telefono,
                     Email: datosRespuesta.data.dato.email,
                     Direccion: datosRespuesta.data.dato.direccion,
                     FechaActual: datosRespuesta.data.fecha,
                     FechaVence: datosRespuesta.data.vence,
                     Idcli:datosRespuesta.data.dato.id
                     });
            })
        .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    ListarServicios(page){
        
        let servicio = document.getElementById('b_servicio').value;
        let documento = document.getElementById('idcli').value;
        if(documento==''){
            alert('Debes de seleccionar al cliente primero!'); 
            return false;
        }
        if(servicio==''){
            Swal.showLoading();
        }
        const datos = {servicio: servicio,documento:documento};
        
        authAxios.post("control/servicios_all?page="+page, datos )
            .then((datosRespuesta) => {
               // Swal.close();
                var status = datosRespuesta.data.status;
                
                if(servicio==''){
                    Swal.close();
                }
                //this.setState({  ListaServicio: datosRespuesta.data.servicios, pagina: datosRespuesta.data.data.total, ultimo: datosRespuesta.data.data.last_page, actual: datosRespuesta.data.data.current_page });
                this.setState({  ListaServicio: datosRespuesta.data.servicios.data, pagina_ser: datosRespuesta.data.servicios.total, ultimo_ser: datosRespuesta.data.servicios.last_page, actual_ser: datosRespuesta.data.servicios.current_page });
            })
        .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    ListarDetalle(){
        const {Idfactura } = this.state;
     
        authAxios.get("facturacion/lista_detalle/"+Idfactura )
            .then((datosRespuesta) => {
               // Swal.close();
                var status = datosRespuesta.data.status;
               
              
                //this.setState({  ListaServicio: datosRespuesta.data.servicios, pagina: datosRespuesta.data.data.total, ultimo: datosRespuesta.data.data.last_page, actual: datosRespuesta.data.data.current_page });
                this.setState({  
                    Listado: datosRespuesta.data.detalles,
                    Neto:datosRespuesta.data.subtotal,
                    Iva:datosRespuesta.data.iva,
                    Total:datosRespuesta.data.total,
                });
            })
        .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    ListaExtra(id){
     
        authAxios.get("facturacion/lista_extra/"+id )
            .then((datosRespuesta) => {
               // Swal.close();
                var status = datosRespuesta.data.status;
               
              
                //this.setState({  ListaServicio: datosRespuesta.data.servicios, pagina: datosRespuesta.data.data.total, ultimo: datosRespuesta.data.data.last_page, actual: datosRespuesta.data.data.current_page });
                this.setState({  
                    ListaExtra: datosRespuesta.data.lista,
                    total_ext:datosRespuesta.data.total,
                });
            })
        .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    VerFactura(){
        const {Idfactura } = this.state;
        if(Idfactura!=0){
            authAxios.get("facturacion/ver_factura/"+Idfactura )
            .then((datosRespuesta) => {
               // Swal.close();
                var status = datosRespuesta.data.status;
                
                
                this.setState({ 
                    nombre: datosRespuesta.data.dato.nombres+' '+datosRespuesta.data.dato.apellidos,
                    Documento: datosRespuesta.data.dato.documento,
                    TipoDoc: datosRespuesta.data.dato.tip_doc,
                    Telefono: datosRespuesta.data.dato.telefono,
                    Email: datosRespuesta.data.dato.email,
                    Direccion: datosRespuesta.data.dato.direccion,
                    FechaActual: datosRespuesta.data.fecha,
                    FechaVence: datosRespuesta.data.vence,
                    Idcli:datosRespuesta.data.dato.cliente,
                    Consecutivo:datosRespuesta.data.dato.consecutivo,
                    Observaciones:datosRespuesta.data.dato.observaciones,
                    FechaActual:datosRespuesta.data.dato.fecha_emision,
                    FechaVence:datosRespuesta.data.dato.fecha_vence,
                    Aprobado:datosRespuesta.data.dato.aprobadopor,
                    Estado:datosRespuesta.data.dato.estadofactura,
                    Neto:datosRespuesta.data.dato.subtotal,
                    Iva:datosRespuesta.data.dato.iva,
                    Total:datosRespuesta.data.dato.total,
                    });
                    this.ListarDetalle();
            })
        .catch();
        }
        
        //window.location.href = '/'; window.localStorage.clear();
    }
    VerServicio(id){
        const {Idfactura } = this.state;
        if(Idfactura=='0'){
            Swal.fire({
                title: 'Deseas generar la factura?',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Generar',
                denyButtonText: `Cancelar`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.GenerarFactura(id)
                } else if (result.isDenied) {
                  Swal.fire('Proceso Cancelado', '', 'info')
                }
              })
        }else{
            Swal.showLoading();
       
            authAxios.get("facturacion/adicionar_servicio/"+id+"/"+Idfactura)
                .then((datosRespuesta) => {
                    Swal.close();
                    var status = datosRespuesta.data.status;
                    

                    
                    this.ListarServicios(1);
                    this.ListarDetalle();
                   
                })
            .catch();
        }
        
    }
    AddTarifa(id){
        var t = window.localStorage.getItem('item');
        Swal.showLoading();
       
        authAxios.get("facturacion/adicionar_servicio/"+id+"/"+t)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                

                
                this.ListarDetalle();
               
            })
        .catch();
        
    }
    VerTarifa(id){
        window.localStorage.setItem('item',id);
        this.MostrarTarifa(1);
    }
    PasarTarifa(id){
        var item = window.localStorage.getItem('item');
        const {Idfactura} = this.state;

        Swal.showLoading();
        var datos = {id:id,item:item,fac:Idfactura}
        authAxios.post("facturacion/pasar_tarifa",datos)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
              
                
                this.ListarDetalle();
            })
        .catch();
    }
    GenerarFactura(id){
        const {Idfactura,Idcli,FechaActual,FechaVence,Aprobado,Observaciones } = this.state;

        Swal.showLoading();
        var datos = {id:Idfactura,cli:Idcli,fecha:FechaActual,vence:FechaVence,usuario:Aprobado,obs:Observaciones}
        authAxios.post("facturacion/generar_factura",datos)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
               
                window.localStorage.setItem('Factura', datosRespuesta.data.id);
                
                this.setState({ Idfactura: datosRespuesta.data.id, Consecutivo: datosRespuesta.data.consecutivo});
                this.VerServicio(id);
            })
        .catch();
    }
    BorrarItem(id){
        const {Idfactura} = this.state;
        Swal.fire({
            title: 'Deseas borrar el item?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Eliminar',
            denyButtonText: `Cancelar`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Swal.showLoading();
       
                authAxios.get("facturacion/borrar_servicio/"+id)
                    .then((datosRespuesta) => {
                        Swal.close();
                        var status = datosRespuesta.data.status;
                      

                        
                        this.ListarDetalle();
                    
                    })
                .catch();
            } else if (result.isDenied) {
              Swal.fire('Proceso Cancelado', '', 'info')
            }
          })
    }
    GuardarFactura(){
        const {Idfactura,Observaciones} = this.state;
        Swal.fire({
            title: 'Deseas Congenlar la factura?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Guardar Factura',
            denyButtonText: `Cancelar`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Swal.showLoading();
                var datos = {fat:Idfactura,obs:Observaciones}
                authAxios.post("facturacion/guardar_factura",datos)
                    .then((datosRespuesta) => {
                        Swal.close();
                        var status = datosRespuesta.data.status;
                        
                        Swal.fire(datosRespuesta.data.msj, 'Genial', 'success');
                        
                        this.setState({ Estado: 2});
                    
                    })
                .catch();
            } else if (result.isDenied) {
              Swal.fire('Proceso Cancelado', '', 'info')
            }
          })
    }
    componentDidMount() {
        this.MostrarCliente(1);
        
        this.VerFactura();
        
        
    }
    handleChange(event) {
        this.setState({ Observaciones: event.target.value });
        
      }
    render() {
        const { 
            nombre,Documento,TipoDoc,Telefono,Direccion,Email,
            ListaCliente,actual,ultimo,pagina,FechaActual,FechaVence,
            ListaServicio,actual_ser,ultimo_ser,pagina_ser,
            ListaTarifa,actual_tar,ultimo_tar,pagina_tar,Estado,
            ListaExtra,actual_ext,ultimo_ext,pagina_ext,total_ext,Neto,Iva,Total,Aprobado,Observaciones,Listado,Idcli,Consecutivo,Idfactura,
            ListaDetalle
        } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                <b>MODULO DE FACTURACIÓN</b>
                            </div>
                            <div className="card-body">
                            <div className='row'>
                                        <div className='col-xl-12 text-right p-3'>
                                            <div className="btn-group" role="group" aria-label="">
                                                {
                                                Estado==1?
                                                 <>
                                                   <button type="button" className="btn btn-success btn-sm  float-right" onClick={()=>this.GuardarFactura()}><FontAwesomeIcon icon={faSave}  />&nbsp;&nbsp;Guardar Factura</button>&nbsp;&nbsp;
                                                   <button type="button" className="btn btn-success btn-sm  float-right" data-bs-toggle='modal' data-bs-target="#ModalServicio" onClick={()=>this.ListarServicios(1)}><FontAwesomeIcon icon={faPlus}  />&nbsp;&nbsp;Agregar Servicios</button>&nbsp;&nbsp;
                                                </>:'' }
                                                {
                                                    Estado==2?<button type="button" className="btn btn-secondary btn-sm  float-right" onClick={()=>this.GuardarFactura()}><FontAwesomeIcon icon={faSave}  />&nbsp;&nbsp;Radicar Factura</button>:''
                                                }
                                    <span className={Estado==1?'badge bg-danger':Estado==2?'badge bg-warning':Estado==3?'badge bg-primary':''}>{Estado==1?'EN PROCESO':Estado==2?'SIN RADICAR':Estado==3?'COMPLETADA':''}</span>
                              
                                            </div>
                                        </div>
                                    </div>
                                <form onSubmit={this.enviarDatos}>
                                    <div className="row">
                                        <div className="col-xl-12">
                                        <div className="row g-3">
                                        <div className="col-md-2">
                                            <label className="form-label" for="validationCustomUsername">DOCUMENTO</label>
                                            <div className="input-group">
                                            <input  className={"form-control form-control"} type="hidden" name="idcli" id="idcli" value={Idcli} placeholder="" aria-describedby="helpId" />
                                            <input  className={"form-control form-control"} type="text" name="documento" id="documento" value={Documento} placeholder="" aria-describedby="helpId" />
                                            <span className="input-group-text"  data-bs-toggle='modal' data-bs-target="#ModalCliente"><i className="icofont icofont-ui-search"> </i></span>
                                                <div className="invalid-feedback">Please choose a username.</div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label" for="validationCustom01">TIPO DOCUMENTO</label>
                                            <select name="estado"
                                                        id="estado"
                                                        className={" form-select form-select"}
                                                        value={TipoDoc}
                                                        options={TipoDoc}>
                                                        <option value="">Seleccione</option>
                                                        <option value="1">CC</option>
                                                        <option value="2">CE</option>
                                                        <option value="3">NIT</option>
                                                    </select>
                                            <div className="valid-feedback">Looks good!</div>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-label" for="validationCustom01">NOMBRE DEL CLIENTE</label>
                                            <input  className={"form-control form-control"} type="text" name="nombre" id="nombre" value={nombre} placeholder="" aria-describedby="helpId" />
                                            <div className="valid-feedback">Looks good!</div>
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label" for="validationCustomUsername">ID</label>
                                            <div className="input-group">
                                            <input  className={"form-control form-control"} type="text" name="idfactura" id="idfactura" value={Idfactura} placeholder="" aria-describedby="helpId" />
                                           
                                                <div className="invalid-feedback">Please choose a username.</div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label" for="validationCustomUsername">CONSECUTIVO</label>
                                            <div className="input-group">
                                            <input  className={"form-control form-control"} type="text" name="consecutivo" id="consecutivo" value={Consecutivo} placeholder="" aria-describedby="helpId" />
                                           
                                                <div className="invalid-feedback">Please choose a username.</div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label" for="validationCustomUsername">TELEFONO</label>
                                            <div className="input-group">
                                            <input  className={"form-control form-control"} type="text" name="documento" id="documento" value={Telefono} placeholder="" aria-describedby="helpId" />
                                           
                                                <div className="invalid-feedback">Please choose a username.</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-label" for="validationCustomUsername">EMAIL</label>
                                            <div className="input-group"><span className="input-group-text" id="inputGroupPrepend">@</span>
                                            <input  className={"form-control form-control"} type="text" name="documento" id="documento" value={Email} placeholder="" aria-describedby="helpId" />
                                           
                                                <div className="invalid-feedback">Please choose a username.</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label" for="validationCustomUsername">DIRECCION</label>
                                            <div className="input-group">
                                            <input  className={"form-control form-control"} type="text" name="documento" id="documento" value={Direccion} placeholder="" aria-describedby="helpId" />
                                           
                                                <div className="invalid-feedback">Please choose a username.</div>
                                            </div>
                                        </div>
                                        
                                        </div>
                                            <div className='row g-3'>
                                          
                                                <div className="col-md-2">
                                                    <label htmlFor="nombre">FECHA DE REGISTRO </label>
                                                    <input  className={"form-control form-control"} type="text" name="documento" id="documento" value={FechaActual} placeholder="" aria-describedby="helpId" />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="nombre">FECHA DE VENCIMIENTO </label>
                                                    <input  className={"form-control form-control"} type="text" name="documento" id="documento" value={FechaVence} placeholder="" aria-describedby="helpId" />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor="nombre">APROBADO POR </label>
                                                    <input  className={"form-control form-control"} type="text" name="documento" id="documento" value={Aprobado} placeholder="" aria-describedby="helpId" />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className="col-md-5">
                                                    <label htmlFor="nombre">OBSERVACIONES</label>
                                                    <input  className={"form-control form-control"} type="text" name="documento" id="documento" defaultValue={Observaciones} placeholder="" aria-describedby="helpId" onChange={this.handleChange.bind(this)}/>
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                               
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <hr />
                                   
                                    <div className="row">
                                <table className="table table-sm ">
                                    <thead className="table table-inverse table-striped">
                                        <tr>
                                            <th scope="col" className="align-middle">ITEM</th>
                                            <th className="align-middle">DESCRIPCION SERVICIO</th>
                                            <th className="align-middle">TARIFA</th>
                                            <th className="align-middle">VLR UND</th>
                                            <th className="align-middle">EXTRA</th>
                                            <th className="align-middle">CANTIDAD</th>
                                            <th className="align-middle">TOTAL</th>
                                            <th className="align-middle">OPC</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                            Listado.map((item,i) => (
                                                <tr key={i}>
                                                    <td>{item.id}</td>
                                                    <td>{item.descripcion}</td>
                                                    <td>{item.tarifa} <button
                                                                type="button"
                                                                className="btn btn-danger btn-xs"
                                                                data-bs-toggle='modal' data-bs-target="#ModalTarifa"
                                                                onClick={() =>
                                                                    this.VerTarifa(item.id) 
                                                                } 
                                                            >
                                                              [+]
                                                            </button></td>
                                                    <td>{item.unitario}</td>
                                                    <td>{item.vlr_extra}
                                                    <button
                                                                type="button"
                                                                className="btn btn-danger btn-xs"
                                                                data-bs-toggle='modal' data-bs-target="#ModalExtra"
                                                                onClick={() =>
                                                                    this.ListaExtra(item.idservicio) 
                                                                } 
                                                            >
                                                              [+]
                                                            </button>
                                                            </td>
                                                    <td>{item.cantidad}</td>
                                                    <td>{item.total_detalle}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            {Estado==1?
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger btn-xs"
                                                                data-bs-dismiss="modal" aria-label="Close"
                                                                onClick={() =>
                                                                    this.BorrarItem(item.id) 
                                                                } 
                                                            >
                                                              Borrar
                                                            </button>:'-'}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tr>
                                        <td colSpan={5}></td>
                                        <td>TOTAL NETO</td>
                                        <td><input  className={" form-control form-control"} type="text" name="nombre" id="nombre" value={Neto} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} /></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5}></td>
                                        <td>IVA</td>
                                        <td><input  className={" form-control form-control"} type="text" name="nombre" id="nombre" value={Iva} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} /></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5}></td>
                                        <td>TOTAL A PAGAR</td>
                                        <td><input  className={" form-control form-control"} type="text" name="nombre" id="nombre" value={Total} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} /></td>
                                    </tr>
                                </table>
                            </div>
                                   
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="ModalCliente" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-lg"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Lista de clientes</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            <table className="table table-sm ">
                                    <thead className="table table-inverse table-striped">
                                        <tr>
                                            <th scope="col" className="align-middle">DOCUMENTO</th>
                                            <th className="align-middle">NOMBRE DEL CLIENTE</th>
                                            <th className="align-middle">TELEFONO</th>
                                            <th className="align-middle">OPC</th>
                                        </tr>
                                    </thead>
                                    <tr>
                                            <th className="align-middle"><input type="text" name="b_cedula" id="b_cedula"  className='form-control' onChange={()=>this.MostrarCliente(1)}/></th>
                                            <th className="align-middle"><input type="text" name="b_nombre" id="b_nombre"  className='form-control' onChange={()=>this.MostrarCliente(1)}/></th>
                                            <th className="align-middle">-</th>
                                            <th className="align-middle">-</th>
                                
                                        </tr>
                                    <tbody>
                                    {
                                            ListaCliente.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.documento}</td>
                                                    <td>{item.nombres} {item.apellidos}</td>
                                                    <td>{item.telefono}</td>
                                                  
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                        
                                                           
                                                            
                                                            <button
                                                                type="button"
                                                                className="btn btn-success btn-xs"
                                                                data-bs-dismiss="modal" aria-label="Close"
                                                                onClick={() =>
                                                                    this.VerCliente(item.id) 
                                                                } 
                                                            >
                                                              Agregar
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                   
                                </table>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.MostrarCliente(1)}> {"<<"} </a></li>
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.MostrarCliente(actual - 1)}>{"<"}</a></li>
                                        {(() => {
                                            let p = [];
                                            p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.MostrarCliente(actual)}>{actual}</a></li>);
                                            return p;
                                        })()}
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.MostrarCliente(actual + 1)}>{">"}</a></li>
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.MostrarCliente(ultimo)}>{">>"} </a></li>
                                    </ul>
                                </nav>
                       
                                <div className="card-footer text-muted">
                                    <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="modal fade" id="ModalServicio" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-lg"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Lista de servicios</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            <table className="table table-sm ">
                                    <thead className="table table-inverse table-striped">
                                        <tr>
                                            <th scope="col" className="align-middle">SERVICIO</th>
                                            <th className="align-middle">DESCRIPCION</th>
                                            <th className="align-middle">FECHA SERVICIO</th>
                                            <th className="align-middle">ESTADO</th>
                                            <th className="align-middle">OPC</th>
                                        </tr>
                                    </thead>
                                    <tr>
                                            <th className="align-middle"><input type="text" name="b_servicio" id="b_servicio"  className='form-control' onChange={()=>this.ListarServicios(1)}/></th>
                                            <th className="align-middle">-</th>
                                            <th className="align-middle">-</th>
                                            <th className="align-middle">-</th>
                                            <th className="align-middle">-</th>
                                        </tr>
                                    <tbody>
                                    {
                                             ListaServicio.map((item,g) => (
                                                <tr key={g} >
                                                    <td  className="align-middle"> {item.numero_expediente}</td>      
                                                    <td  className="align-middle">{item.nombre_cliente}</td>   
                                                    <td  className="align-middle">{item.fecha} {item.hora_inicial}</td>  
                                                    <td  className="align-middle">{item.estado}</td>
                                                    <td  className="align-middle"><span className='btn btn-info'  onClick={()=>this.VerServicio(item.id)}>[+]</span> </td>  
                                                    </tr> 
                                                                                                  
                                            ))
                                           }
                                    </tbody>
                                   
                                </table>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(1)}> {"<<"} </a></li>
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(actual_ser - 1)}>{"<"}</a></li>
                                        {(() => {
                                            let p = [];
                                            p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.ListarServicios(actual_ser)}>{actual_ser}</a></li>);
                                            return p;
                                        })()}
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(actual_ser + 1)}>{">"}</a></li>
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(ultimo_ser)}>{">>"} </a></li>
                                    </ul>
                                </nav>
                       
                                <div className="card-footer text-muted">
                                    <b>Pagina</b> <span className="badge bg-primary">{actual_ser}</span> <b>de</b> <span className="badge bg-primary">{ultimo_ser}</span> | <b>Registros</b> <span className="badge bg-success">{pagina_ser}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="modal fade" id="ModalExtra" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-lg"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Registro de Extras</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            <table className="table table-sm ">
                                    <thead className="table table-inverse table-striped">
                                        <tr>
                                            <th scope="col" className="align-middle">ITEM</th>
                                            <th className="align-middle">DESCRIPCION</th>
                                            <th className="align-middle">OPC</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                            ListaExtra.map((item,i) => (
                                                <tr key={i}>
                                                    <td>{item.id}</td>
                                                    <td>{item.descripcion}</td>
                                                    <td>{item.valor_extra}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    
                                   
                                </table>
                                <div className="card-footer text-muted">
                                    <b>Total extra</b> <span className="badge bg-primary">{total_ext}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="modal fade" id="ModalTarifa" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-lg"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Lista de Tarifas</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            <table className="table table-sm ">
                                    <thead className="table table-inverse table-striped">
                                        <tr>
                                            <th scope="col" className="align-middle">COD</th>
                                            <th className="align-middle">DESCRIPCION TARIFA</th>
                              
                                            <th className="align-middle">VALOR</th>

                                            <th className="align-middle">OPC</th>
                                        </tr>
                                    </thead>
                                    <tr>
                                            <th className="align-middle">-</th>
                                            <th className="align-middle"><input type="text" name="b_tarifa" id="b_tarifa"  className='form-control' onChange={()=>this.MostrarTarifa(1)}/></th>
                                            
                                            <th className="align-middle">-</th>
                                            <th className="align-middle">-</th>
                                
                                        </tr>
                                    <tbody>
                                    {
                                            ListaTarifa.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.descripcion}</td>
                                            
                                                    <td>{item.valor} </td>
                                                    
                                                  
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                        
                                                           
                                                            {Estado==1?
                                                            <button
                                                                type="button"
                                                                className="btn btn-success btn-xs"
                                                                data-bs-dismiss="modal" aria-label="Close"
                                                                onClick={() =>
                                                                    this.PasarTarifa(item.id) 
                                                                } 
                                                            >
                                                              Agregar
                                                            </button>:'-'}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                   
                                </table>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.MostrarTarifa(1)}> {"<<"} </a></li>
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.MostrarTarifa(actual_tar - 1)}>{"<"}</a></li>
                                        {(() => {
                                            let p = [];
                                            p.push(<li className="page-item" key={actual_tar}><a className="page-link" href="#" onClick={() => this.MostrarTarifa(actual)}>{actual_tar}</a></li>);
                                            return p;
                                        })()}
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.MostrarTarifa(actual_tar + 1)}>{">"}</a></li>
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.MostrarTarifa(ultimo_tar)}>{">>"} </a></li>
                                    </ul>
                                </nav>
                       
                                <div className="card-footer text-muted">
                                    <b>Pagina</b> <span className="badge bg-primary">{actual_tar}</span> <b>de</b> <span className="badge bg-primary">{ultimo_tar}</span> | <b>Registros</b> <span className="badge bg-success">{pagina_tar}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default Crear;