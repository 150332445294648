import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { DefaultLayout } from '../../layout/theme-customizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import tema from "../../services/Plantilla";
import { Overflow } from '../../constant';
let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy = f[2].padStart(2, "0") +"-" +f[1].padStart(2, "0") +"-" +f[0].padStart(2, "0");

  let ult =f[2].padStart(2, "0") + "-" +f[1].padStart(2, "0") + "-31";

class Listar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        Pilotos: [],
        HorasConductor: [],
        Dias:[],
        Fi:hoy,
        Ff:ult,
        Conductor:0,
        Servicio:0
    }
   
    
    LinkEditar(ide){
        localStorage.setItem('ide', ide);
        
        window.location.href = process.env.PUBLIC_URL+'/pages/EditarUsuario/'+tema;
    }
    LinkCrear(){
        window.location.href = '/pages/AddUsuarios/';
    }
    Servicios(id,ser){
       alert('ola');
       this.setState({ Conductor: id, Servicio: ser});
    }
    Lista(){
        Swal.showLoading();
        let bname = document.getElementById('bnombre').value;
    
          let fi = document.getElementById('bfi').value;
        const datos = {nombre:bname,fecha:fi};
          
        authAxios.post("control/tablero",datos)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                
                
                this.setState({ datosCargados: true, load: '',Pilotos:datosRespuesta.data.pilotos,Dias:datosRespuesta.data.horas,HorasConductor:datosRespuesta.data.pilotos});
            })
        .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    componentDidMount() {
        this.Lista();
    }
    render() {
        const { load, Pilotos, HorasConductor, total, Dias, Tdias , Fi,Ff} = this.state;
        return (
            <div className="content">
                <div className="container-fluid">
                    <br />
                    <div className="card">
                        
                        <div className="card-body">
                            <h4>Tablero de Conductores</h4>
                            <hr />
                            <div className="row">
                                <div className="col-xl-3">
                                    <input style={{textTransform: 'uppercase'}} type="text" id="bnombre" name="bnombre" placeholder="Buscar Piloto" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                    <input style={{textTransform: 'uppercase'}} type="date" id="bfi" name="bfi" defaultValue={Fi} className="form-control form-control-sm" />
                                </div>
                               
                                <div className="col-xl-3">
                                    <button id="bt" name="bt" className="btn btn-primary" onClick={() => this.Lista()}>&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br> 
                            <div className="row table-responsive" style={{whiteSpace:'nowrap',Overflow:'auto'}}>
                                <table className="table-bordered">
                                    <thead className="bg-secondary">
                                        
                                       
                                        <tr>
                                            <th className="align-middle">ITEM</th>
                                            <th className="text-justify" >CONDUCTOR</th>
                                      
                                          
                                           {
                                             Dias.map((item,g) => (

                                                    <th key={g}  className="align-middle">{item}</th>                                                    
                                            ))
                                           }
                                            
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {
                                            Pilotos.map((row,i) => (
                                                <tr key={i} >
                                                    <td className="align-middle">{i+1}</td>
                                                    <td className="text-justify">{row.nombre} {row.apellidos}  <span  data-bs-toggle='modal' data-bs-target="#ModalServicio" onClick={()=>this.Servicios(row.id,0)}></span></td>
                                                 
                                                     
                                                     {
                                                        Dias.map((it,h) => (
                                                          
                                                                <td key={h} >
                                                                   
                                                                    <table  className={"align-middle"} >
                                                                        <tr>
                                                                            <td className={
                                                                                row.pintar_hora[h].seleccionar=='success'?
                                                                                     row.pintar_hora[h].minuto<=0?
                                                                                     'bg-success border border-dark':'bg-light border border-dark':
                                                                                     row.pintar_hora[h].seleccionar_fin=='success'?
                                                                                         row.pintar_hora[h].minuto>0?
                                                                                        'bg-success border border-dark':'bg-light border border-dark':
                                                                                    'bg-light border border-dark'
                                                                            } title={it+':00'}>..</td>
                                                                            <td className={
                                                                                row.pintar_hora[h].seleccionar=='success'?
                                                                                row.pintar_hora[h].minuto<=15?
                                                                                'bg-success border border-dark':'bg-light border border-dark':
                                                                                row.pintar_hora[h].seleccionar_fin=='success'?
                                                                                         row.pintar_hora[h].minuto>15?
                                                                                        'bg-success border border-dark':'bg-light border border-dark':
                                                                                    'bg-light border border-dark'
                                                                            } title={it+':15'}>..</td>
                                                                            <td className={
                                                                                row.pintar_hora[h].seleccionar=='success'?
                                                                                row.pintar_hora[h].minuto<=30?
                                                                                'bg-success border border-dark':'bg-light border border-dark':
                                                                                row.pintar_hora[h].seleccionar_fin=='success'?
                                                                                         row.pintar_hora[h].minuto>30?
                                                                                        'bg-success border border-dark':'bg-light border border-dark':
                                                                                    'bg-light border border-dark'
                                                                            } title={it+':30'}>..</td>
                                                                            <td className={
                                                                                row.pintar_hora[h].seleccionar=='success'?
                                                                                row.pintar_hora[h].minuto<=45?
                                                                                'bg-success border border-dark':'bg-light border border-dark':
                                                                                row.pintar_hora[h].seleccionar_fin=='success'?
                                                                                         row.pintar_hora[h].minuto>45?
                                                                                        'bg-success border border-dark':'bg-light border border-dark':
                                                                                    'bg-light border border-dark'
                                                                            } title={it+':45'}>..</td>
 
                                                                        </tr>
                                                                    </table>
                                                                </td>                                                    
                                                        ))
                                                    }
                                                  
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                          
                        </div>
                        <div className="card-footer text-muted">
                            <b>Registros</b> <span className="badge bg-success">{total}</span>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="ModalServicio" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-lg"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Asignacion de conductor</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                En construccion 
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default Listar;