import { Home, Airplay, Box,Folder, FolderPlus, Command, Cloud, FileText, Server, BarChart, Users, Layers, ShoppingBag, List, Mail, MessageCircle, GitPullRequest, Monitor, Heart, Clock, Zap, CheckSquare, Calendar, Image, Film, HelpCircle, Radio, Map, Edit, Sunrise, Package } from 'react-feather'
import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import Principal from "./principal";
let admin = localStorage.getItem('admin');

export const MENUITEMS = [
    
    {
        menutitle: "Inicio",
        menucontent: "Servicios de la empresa",
        Items: [

          /*   {
                title: 'MENU PRINCIPAL', icon: Server, type: 'sub', children: [
                    {
                        title: ' Servicios ', type: 'sub', children: [
                       
                            { path: `${process.env.PUBLIC_URL}/servicios`, title: 'Asignacion de Servicios', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/servicios_concluidos`, title: 'Servicios Concluidos', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/servicios/caracol`, title: 'Servicios Caracol', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/27`, title: 'Facturas', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/28`, title: 'Fuec', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/32`, title: 'Recorridos', type: 'link' },
                            
                        ]
                    },
                    {
                        title: 'Control', path: `${process.env.PUBLIC_URL}/control`, type: 'link'
                    },
                    {
                        title: 'Tablero', path: `${process.env.PUBLIC_URL}/tablero`, type: 'link'
                    },
                    {
                        title: 'PQR', path: `${process.env.PUBLIC_URL}/pages/39`, type: 'link'
                    },
                ]
            }, */

            


          /*   {
                title: 'Configuracion', icon: FileText, type: 'sub', menutitle: "Forms & Table", menucontent: "Ready to use froms & tables", active: false, children: [
                    {
                        title: ' Vehiculo ', type: 'sub', children: [
                                 { path: `${process.env.PUBLIC_URL}/pages/6`, title: 'Lista de vehiculos', type: 'link' },
                           
                            { path: `${process.env.PUBLIC_URL}/pages/1`, title: 'Carrocerias', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/2`, title: 'Marca vehiculos', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/3`, title: 'Clase vehiculos', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/4`, title: 'Tipo de caja', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/5`, title: 'Modalidad tendencia', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/7`, title: 'linea vehiculo', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/13`, title: 'Conductores ', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/15`, title: 'Documentacion', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/23`, title: 'combustible', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/31`, title: 'Ubicaciones', type: 'link' },
                        ]
                    },
                    {
                        title: 'Conductores', type: 'sub', children: [
                            { path: `${process.env.PUBLIC_URL}/pages/8`, title: 'Lista Conductores', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/9`, title: 'Tipo identificacion', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/10`, title: 'Tipo conductor', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/11`, title: 'Tipo vehiculo', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/12`, title: 'Tipo contrato', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/19`, title: 'Eps', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/20`, title: 'Afp', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/21`, title: 'Arl', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/22`, title: 'Categoria', type: 'link' },
                            

                          
                           
                        ]
                    },


                    {
                        title: 'Clientes', type: 'sub', children: [
                            { path: `${process.env.PUBLIC_URL}/pages/24`, title: 'Lista Clientes', type: 'link' },
                            
                            { path: `${process.env.PUBLIC_URL}/pages/26`, title: 'Contratos', type: 'link' }, 
                           
                        ]
                    },

                    {
                        title: 'Pqr', type: 'sub', children: [
                            { path: `${process.env.PUBLIC_URL}/pages/39`, title: 'Lista PQR', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/37`, title: 'Estados pqr', type: 'link' }, 
                            { path: `${process.env.PUBLIC_URL}/pages/38`, title: 'Tipos pqr', type: 'link' }, 
                           
                        ]
                    },
                    {
                        title: 'Conf. Servicios', type: 'sub', children: [
                            { path: `${process.env.PUBLIC_URL}/pages/14`, title: 'Lista Servicios', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/pages/40`, title: 'Estados Servicios', type: 'link' }, 
                                
                        ]
                    },
                  
                   


                
                ],
            }, */

      
        ]
    },
    
    {
        menutitle: "Inicio 2",
        menucontent: "Servicios de la empresa",
        Items: [
            {
                title: 'ADMINISTRADOR', icon: Layers, type: 'sub', badge2: true, active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/configuracion/lista`, title: 'Conf. Menu', type: 'link' },
                    
                    { path: `${process.env.PUBLIC_URL}/pages/usuarios`, title: 'Usuarios', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/roles/lista`, title: 'Roles', type: 'link' },
                     
                    { path: `${process.env.PUBLIC_URL}/pages/29`, title: 'Mi empresa', type: 'link' },
              

                ]
            }
        ]
    
}

]
