import React, { useEffect, useState } from 'react';
import Routers from './route';
import { Provider } from 'react-redux';
import store from './store';
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0 } from './data/config';
import Swal from 'sweetalert2';

function App() {
  // Estado para habilitar o deshabilitar la funcionalidad de cierre de sesión por inactividad
  const [inactivityEnabled, setInactivityEnabled] = useState(true);

  // Función para cerrar sesión del usuario
  const logoutUser = () => {
    Swal.fire('Token ha Expirado', 'Vuelva a iniciar sesión', 'info');
    window.localStorage.clear();
    window.location.href = '/';
  };

  useEffect(() => {
    const inactivityTime = 900000; // 10 segundos de inactividad

    let timer;

    // Función para restablecer el temporizador cada vez que haya actividad.
    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(logoutUser, inactivityTime);
    };
    const currentPath = window.location.pathname;
    // Escucha eventos de actividad (movimiento del mouse, pulsación de teclas, etc.) solo si la funcionalidad está habilitada.
    if (currentPath !== '/login' && currentPath !== '/recupera') {
      const activityEvents = ['mousemove', 'keydown', 'mousedown'];

      const activityListener = () => {
        resetTimer();
      };

      for (const eventName of activityEvents) {
        window.addEventListener(eventName, activityListener);
      }

      // Inicializa el temporizador al cargar la página.
      resetTimer();

      return () => {
        // Elimina los event listeners cuando el componente se desmonta.
        for (const eventName of activityEvents) {
          window.removeEventListener(eventName, activityListener);
        }
      };
    }
  }, [inactivityEnabled]);


  return (
    <div className="App">
      <Auth0Provider>
        <Provider store={store}>
          <Routers />
        </Provider>
      </Auth0Provider>
    </div>
  );
}

export default App;
