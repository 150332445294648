import React from 'react'
import Swal from 'sweetalert2';
import { Link,useNavigate } from "react-router-dom";
import authAxios from "../../services/Token";
import tema from "../../services/Plantilla";
import { DefaultLayout } from '../../layout/theme-customizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

  const defaultLayout = Object.keys(DefaultLayout);
  const layout = defaultLayout

class Editar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        usuario: [],
        errores: []
    }
    cambioValor = (e) => {
        const state = this.state.usuario;
        state[e.target.name] = e.target.value;
        this.setState({ usuario: state });
    };
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        const estado = document.getElementById('estado').value;
        const tabla = document.getElementById('tabla').value;

        const icono = document.getElementById('icono').value;
        const url = document.getElementById('url').value;
        const id_modulo = document.getElementById('id_modulo').value;
   
        var errores = [];

        if (!tabla) { errores.push("error_tabla"); }
        if (!estado) { errores.push("error_estado"); }

        if (!icono) { errores.push("error_icono"); }
        if (!url) { errores.push("error_url"); }
        if (!id_modulo) { errores.push("error_id_modulo"); }

        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        

        authAxios.post("modulos/editar", formData)
            .then((datosRespuesta) => {
                
                Swal.fire(
          
                    datosRespuesta.data.msj,
                    '',
                    'success'
                )
                
           
                
            })
            .catch()
    }
    componentDidMount() {
        Swal.showLoading();
      

        var ide = localStorage.getItem('ide');
        
        authAxios.get("modulos/ver/"+ide)
            .then((datosRespuesta) => {
                
                Swal.close();
                this.setState({ datosCargados: true, usuario: datosRespuesta.data.data, load: '' });
            })
            .catch();
        
    }
    render() {
        const { load, usuario } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                {load ? (<b>{load}</b>) : (<b>EDITAR MODULO</b>)}
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.enviarDatos} id="form">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                
                                            <input type="hidden" name="id" defaultValue={usuario.id} />
                                                <div className="col-md-3">
                                                    <label htmlFor="tabla">Nombre de la tabla</label>
                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="tabla" id="tabla" defaultValue={usuario.tabla}
                                                        className={((this.verificarError("error_tabla")) ? "is-invalid" : "") + " form-control form-control-sm"} placeholder="NOMBRE" aria-describedby="helpId"
                                                        onChange={this.cambioValor} 
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="estado">Estado</label>
                                                    <select name="estado"
                                                        id="estado"
                                                        value={usuario.estado}
                                                        options={usuario.estado}
                                                        className={((this.verificarError("error_estado")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el estado</option>
                                                        <option value="0">activo</option>
                                                        <option value="1">inactivo</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Estado</small>
                                                </div>


                                                <div className="col-md-3">
                                                    <label htmlFor="icono">Icono</label>
                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="icono" id="icono" defaultValue={usuario.icono}
                                                        className={((this.verificarError("error_icono")) ? "is-invalid" : "") + " form-control form-control-sm"} placeholder="icono" aria-describedby="helpId"
                                                        onChange={this.cambioValor}
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite el icono</small>
                                                </div>
                                                
                                                <div className="col-md-3">
                                                    <label htmlFor="url">Url</label>
                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="url" id="url" defaultValue={usuario.url}
                                                        className={((this.verificarError("error_url")) ? "is-invalid" : "") + " form-control form-control-sm"} placeholder="url" aria-describedby="helpId"
                                                        onChange={this.cambioValor}
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite el url</small>
                                                </div>


                                                <div className="col-md-2">
                                                    <label htmlFor="id_modulo">Id modulo</label>
                                                    <select name="id_modulo"
                                                        id="id_modulo"
                                                        value={usuario.id_modulo}
                                                        options={usuario.id_modulo}
                                                        className={((this.verificarError("error_id_modulo")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el modulo</option>
                                                        <option value="1">configuracion</option>
                                                        <option value="2">otros</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el modulo</small>
                                                </div>








                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="submit" className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp; Editar Modulo</button>&nbsp;&nbsp;
                                                <Link className="btn btn-danger btn-sm" to={`${process.env.PUBLIC_URL}/modulos/lista/${tema}`}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Editar;