import React from 'react'
import authAxios from "../../services/Token";
import { Home, Airplay, Box, FolderPlus, Command, Cloud, FileText, Server, BarChart, Users, Layers, ShoppingBag, List, Mail, MessageCircle, GitPullRequest, Monitor, Heart, Clock, Zap, CheckSquare, Calendar, Image, Film, HelpCircle, Radio, Map, Edit, Sunrise, Package } from 'react-feather'
import { Link } from 'react-router-dom'

class Principal extends React.Component { 
    constructor(props) {
        super(props);  
    }
    state = {
        Modulos: [],
    }
    toggletNavActive = (item) => {
        if (window.innerWidth <= 991) {
          document.querySelector(".page-header").className = "page-header close_icon";
          document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
          document.querySelector(".mega-menu-container").classList.remove("d-block")
          if (item.type === "sub") {
            document.querySelector(".page-header").className = "page-header ";
            document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper "
          }
        }
    }

    render() {
        return (
          
            <li className="sidebar-list"
            >
                       
                          <a href="javascript" className={`sidebar-link sidebar-title ${this.props.menuItem.active ? 'active' : ''}`} >
                            <Airplay />
                            <span>Menu  primario</span>
                            <div className="according-menu">
                              {this.props.menuItem.active ?
                                <i className="fa fa-angle-down"></i>
                                : <i className="fa fa-angle-right"></i>
                              }
                            </div>
                          </a>
                          
                          <ul className="sidebar-submenu" style={this.props.menuItem.active ? true ? { opacity: 1, transition: 'opacity 500ms ease-in' } : { display: "block" } : { display: "none" }}>
                            <li >
                                
                                <Link to={'test/Dubai'} className={''} onClick={() => this.toggletNavActive(true)}>  SubMenu  </Link>
                                
                            </li>
                            
                          </ul>
                      </li>
            
        );
    }
}

export default Principal;