import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { DefaultLayout } from '../../layout/theme-customizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import tema from "../../services/Plantilla";
let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let fechaActual = new Date();



let hoy = f[2].padStart(2, "0") + "-" + f[1].padStart(2, "0") + "-" + f[0].padStart(2, "0");


var today = new Date();
var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).toLocaleString().split(",");;
let ms = lastDayOfMonth[0].split("/");
let ult = f[2].padStart(2, "0") + "-" + f[1].padStart(2, "0") + "-" + ms[0].padStart(2, "0");


const getFormattedDate = (date) => {
    const options = { month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('es-ES', options).format(date);
};

const todayDate = new Date();
const tomorrowDate = new Date();
tomorrowDate.setDate(todayDate.getDate() + 1);

class Listar extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        load: '',
        datosCargados: false,
        usuarios: [],
        grupo: [],
        Dias: [],
        alertas_m: [],
        Fi: hoy,
        Ff: new Date(fechaActual.setDate(fechaActual.getDate() + 1)).toLocaleDateString(),
        hoymes: getFormattedDate(todayDate),
        mañanames: getFormattedDate(tomorrowDate),
        Eventos: [],
        FechaCalendario: ''
    }
    sumarDias(fecha, dias) {
        fecha.setDate(fecha.getDate() + dias);
        return fecha;
    }

    LinkEditar(ide) {
        localStorage.setItem('ide', ide);

        window.location.href = process.env.PUBLIC_URL + '/pages/EditarUsuario/' + tema;
    }
    LinkCrear() {
        window.location.href = '/pages/AddUsuarios/';
    }
    Lista() {
        Swal.showLoading();
        let bname = document.getElementById('bnombre').value;
        if (bname == '') {
            bname = 0;
        }
        let fi = document.getElementById('bfi').value;
        let ff = document.getElementById('bff').value;

        authAxios.get("control/index/" + bname + "/" + fi + "/" + ff)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;


                this.setState({ datosCargados: true, load: '', Tdias: datosRespuesta.data[0].td, usuarios: datosRespuesta.data, Dias: datosRespuesta.data[0].dias, grupo: datosRespuesta.data[0].grupo, alertas_m: datosRespuesta.data[0].alertas_m, total: datosRespuesta.data[0].total });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    VerCalendario(vehiculo, fecha) {

        authAxios.get("control/verdia/" + vehiculo + "/" + fecha)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;


                this.setState({ Eventos: datosRespuesta.data.pinta, FechaCalendario: fecha });
            })
            .catch();

    }
    componentDidMount() {
        // this.Lista();
    }
    render() {
        const { load, usuarios, grupo, alertas_m, total, Dias, Tdias, Fi, Ff, Eventos, FechaCalendario, hoymes, mañanames } = this.state;
        return (
            <div className="content">
                <div className="container-fluid">
                    <br />
                    <div className="card">

                        <div className="card-body">
                            <h4>Tabla de Control</h4>
                            <hr />
                            <div className="row">
                                <div className="col-xl-3">
                                    <input style={{ textTransform: 'uppercase' }} type="text" id="bnombre" name="bnombre" placeholder="Buscar Placa" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                    <input style={{ textTransform: 'uppercase' }} type="date" id="bfi" name="bfi" defaultValue={Fi} className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                    <input style={{ textTransform: 'uppercase' }} type="date" id="bff" name="bff" defaultValue={mañanames} className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                    <button id="bt" name="bt" className="btn btn-primary" onClick={() => this.Lista()}>&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br>
                            <div className="row table-responsive">
                                <table className="table table-striped table-condensed table-bordered">
                                    <thead className="thead-inverse">
                                        <tr style={{ padding: '5px' }}>

                                            <td className="text-center" colSpan={3}>Datos Basicos</td>
                                            <td className="text-center" colSpan={5}>Elementos de Control</td>

                                            {/* {
                                                Dias.map((item, g) => (

                                                    <td key={g} title={item.tipo} className={"bg-info"}>{item.mes}</td>
                                                ))
                                            } */}
                                        </tr>
                                        <tr style={{ padding: '5px' }}>
                                            <th className="text-center" style={{ width: '60px', padding: '5px', margin: '0' }}>PLACA</th>
                                            <th className="text-center" style={{ width: '60px', padding: '5px', margin: '0' }}>MARCA</th>
                                            {/* <th className="text-center" style={{ width: '60px', padding: '5px', margin: '0' }}>LINEA</th> */}
                                            <th className="text-center" style={{ width: '60px', padding: '5px', margin: '0' }}>(KM)</th>
                                            {/* <th className="text-center" style={{ width: '60px', padding: '5px', margin: '0' }}>DC</th> */}
                                            <th className="text-center" style={{ width: '60px', padding: '5px', margin: '0' }}>MTO</th>
                                            {/* <th className="text-center" colSpan={2}>ESTADOS X LAPSO</th> */}
                                            {
                                                grupo.map((item, g) => (

                                                    //<td key={item.g} >{item.tipo}</td>
                                                    <td key={g} title={item.tipo} style={{ width: '60px', padding: '5px', margin: '0' }}><img className=" img-40 rounded-circle mb-3" src={item.url} alt="" /></td>
                                                ))
                                            }
                                            {
                                                Dias.map((item, g) => (

                                                    <td key={g} title={item.tipo} style={{ background: '#84b6f4' }}>{item.dia}</td>
                                                ))
                                            }


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}


                                        {
                                            usuarios.map((itemUsuarios, i) => (
                                                <tr key={i} style={{ padding: '5px' }}>
                                                    <td style={{ padding: '5px' }}>{itemUsuarios.placa}</td>
                                                    <td style={{ padding: '5px' }}>{itemUsuarios.marca}</td>
                                                    <td style={{ padding: '5px' }}>{itemUsuarios.odo}</td>
                                                    <td style={{ padding: '5px' }}>{itemUsuarios.alertas_m.map((item, a) => (

                                                        <td style={{ padding: '5px' }} key={a} data-bs-target="#ModalDocumento" onClick={() => this.VerCalendario()} className={item.alerta} title={item.nombre+' '+' Kms: ' + item.kms}><img  className=" img-40 rounded-circle mb-3" src={'https://app.transorientesas.com/storage/'+item.url} /></td>

                                                    ))}</td>
                                                    {grupo.map((item, g) => (

                                                        <td style={{ padding: '5px' }} key={g} data-bs-target="#ModalDocumento" onClick={() => this.VerCalendario()} className={itemUsuarios.grupo[g].color} title={'Fecha vencimiento: ' + itemUsuarios.grupo[g].vence.date}>{itemUsuarios.grupo[g].vence == ''}-</td>

                                                    ))}

                                                    {
                                                        Dias.map((it, h) => (

                                                            <td style={{ padding: '5px' }} data-bs-toggle='modal' data-bs-target="#ModalServicio" onClick={() => this.VerCalendario(itemUsuarios.id_vehiculo, itemUsuarios.dias[h].fecha_calendario)} key={h} title={itemUsuarios.dias[h].nombre} style={{ backgroundColor: itemUsuarios.dias[h].pinta }}></td>
                                                        ))
                                                    }

                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div className="card-footer text-muted">
                            <b>Registros</b> <span className="badge bg-success">{total}</span>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="ModalServicio" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-lg"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Eventos del dia {FechaCalendario}</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row table-responsive">
                                    <table className="table table-hover table-condensed table-bordered table-sm">
                                        <thead className="thead-inverse">
                                            <tr>
                                                <td>HORA</td>
                                                <td>EVENTO</td>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Eventos.map((item, i) => (
                                                    <tr key={i}>
                                                        <td>{item.hora}</td>
                                                        <td>
                                                            {item.HoraServicio ?
                                                                <>
                                                                    Hora:{item.HoraServicio}<br></br>
                                                                    Cliente:{item.nombre_cliente}<br></br>
                                                                    Direccion:{item.direccion_inicial}<br></br>
                                                                    Expediente:{item.numero_expediente}<br></br>
                                                                    Conductor:{item.piloto}<br></br>
                                                                    Estado:{item.estado}<br></br>
                                                                </>
                                                                : ''}
                                                        </td>
                                                    </tr>
                                                ))

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="modal fade" id="ModalDocumento" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-lg"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Elemento de Control</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row table-responsive">
                                    <p>hola mundo</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Listar;