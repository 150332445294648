import React from 'react';
import Swal from 'sweetalert2';
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';

let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy =
    f[2].padStart(2, "0") +
    "-" +
    f[1].padStart(2, "0") +
    "-" +
    f[0].padStart(2, "0");

class Listar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            ListaTabla: [],
            ListaEstados: [],
            ListaCliente: [],
            ListaCiudades: [],
            ListaCiudadesFiltradas: [],
            ListaDepartamentos: [],
            ListaAseguradoras: [],
            ListaPrefactura: [],
            Hoy: hoy,
            seleccionarTodo: false,
            idsSeleccionados: [],
            clientesConBoton: [],
            idClienteInfo: [],
            selectedOption: 'option3', // Valor por defecto del tercer radio button
            file: null,
            Usuario: localStorage.getItem('Name')
        }
        // Enlazar los métodos al contexto de la instancia
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleOptionChange(e) {
        this.setState({ selectedOption: e.target.value });
    }

    handleFileChange(e) {
        this.setState({ file: e.target.files[0] });
    }

    handleSubmit(e) {
        e.preventDefault();
        Swal.showLoading();
        // Crear un objeto FormData
        const formData = new FormData();
        
        formData.append('archivo_excel', this.state.file);

        // Realizar la solicitud al servidor (puedes usar fetch o axios)
        authAxios.post('exterior/cargue-masivo-ejecuciones', formData)
            .then(response => {
                Swal.close();
                Swal.fire(
                    'Se cargo masivamente el archivo',
                    '',
                    'success'
                )
                this.setState({ datosCargados: true, load: '', ListaTabla: response.data.datos });
                // Manejar la respuesta del servidor
                console.log(response.data);
            })
            .catch(error => {
                Swal.close();
                Swal.fire(
                    'Algo fallo',
                    '',
                    'warning'
                )
                console.error('Error al enviar la solicitud:', error);
            });
    }
    // Agrega un manejador de eventos para el cambio en el campo de selección de departamento
    handleDepartamentoChange = (event) => {
        const departamentoSeleccionado = event.target.value;

        // Filtra la lista de ciudades en función del departamento seleccionado
        const ciudadesFiltradas = this.state.ListaCiudades.filter(
            (ciudad) => ciudad.departamento === departamentoSeleccionado
        );

        this.setState({
            ListaCiudadesFiltradas: ciudadesFiltradas,
        });
    };
    exportToExcel = () => {
        let idx = document.getElementById('idprefactura').value;
        const { ListaPrefactura } = this.state;

        const ws = XLSX.utils.json_to_sheet(ListaPrefactura);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');

        XLSX.writeFile(wb, 'Prefactura' + idx + '.xlsx');
    };
    consultarClienteId(identificacion) {
        console.log(this.state.clientesConBoton)
        Swal.showLoading();
        authAxios.post("exterior/consulta-id-cliente/" + identificacion)
            .then((datosRespuesta) => {
                Swal.close();
                var respuesta = datosRespuesta.data.datos[0];
                console.log(datosRespuesta.data);
                // document.getElementById("tipoDocumento").value = respuesta.tipoDocumento;
                document.getElementById("nombres").value = respuesta.nombre;
                document.getElementById("telefono").value = respuesta.telefono;
                // document.getElementById("departamento").value = respuesta.departamento;
                document.getElementById("documento").value = respuesta.identificacion;
                document.getElementById("email").value = respuesta.email;
                document.getElementById("apellidos").value = respuesta.apellido;
                // document.getElementById("ciudad").value = respuesta.ciudad;
                document.getElementById("direccion").value = respuesta.direccion;

                // this.setState({ idClienteInfo: datosRespuesta.data.datos });
                // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
            })
            .catch((err) => {
                console.log(err);
            });
    }
    guardarCliente = (e) => {

        e.preventDefault();
        Swal.showLoading();
        // Recopila los datos del formulario en un objeto data
        const data = {
            tipoDocumento: document.getElementById('tipoDocumento').value,
            nombres: document.getElementById('nombres').value,
            telefono: document.getElementById('telefono').value,
            departamento: document.getElementById('departamento').value,
            documento: document.getElementById('documento').value,
            email: document.getElementById('email').value,
            apellidos: document.getElementById('apellidos').value,
            ciudad: document.getElementById('ciudad').value,
            direccion: document.getElementById('direccion').value,
        };
        this.setState({ clientesConBoton: [] }, () => {
            // Código que depende del estado actualizado
            // Realiza la solicitud a la API con los datos del formulario
            authAxios.post('exterior/guardar-cliente', data)
                .then(response => {
                    Swal.close();
                    console.log(this.state.clientesConBoton)
                    this.MostrarTabla(1);
                    // Maneja la respuesta de la API si es necesario
                    console.log(response.data);
                    const miBoton = document.getElementById('miBoton');

                    // Simula un clic en el botón
                    miBoton.click();
                })
                .catch(error => {
                    // Maneja los errores si la solicitud falla
                    console.error(error);
                });
        });

    }
    MostrarTabla() {
        this.setState({ clientesConBoton: [] });
        Swal.showLoading();
        let bfecha = document.getElementById('bfecha').value;
        let bfecha2 = document.getElementById('bfecha2').value;
        let origen = document.getElementById('borigen').value;
        let estado = document.getElementById('bestado').value;
        // let ase = document.getElementById('ase').value;
        if (origen == '') {
            Swal.fire(
                'Debes de seleccionar al cliente',
                '',
                'warning'
            )
            return false;
        }
        if (bfecha == '' || bfecha2 == '') {
            Swal.fire(
                'Debes de seleccionar la fecha inicial y final',
                '',
                'warning'
            )
            return false;
        }
        var datos = { origen: origen, fechai: bfecha, fechaf: bfecha2, estado: estado }
        this.setState({ clientesConBoton: [] }, () => {
            authAxios.post("exterior/consultaservicio-internos", datos)
                .then((datosRespuesta) => {
                    Swal.close();
                    var status = datosRespuesta.data.status;

                    this.setState({ datosCargados: true, load: '', ListaTabla: datosRespuesta.data.datos, clientesConBoton: [] });
                })
                .catch(() => {
                    this.setState({ clientesConBoton: [] });
                });
        })
        //window.location.href = '/'; window.localStorage.clear();
    }
    MostrarClientes() {

        authAxios.get("exterior/consultacliente")
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                console.log(datosRespuesta.data);
                this.setState({
                    datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras, ListaCiudades: datosRespuesta.data.ciudades,
                    ListaDepartamentos: datosRespuesta.data.departamentos
                });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    Enviar = () => {
        let id = document.getElementById('idprefactura').value;
        var dati = { id: id }
        authAxios.post("exterior/consultarprefactura", dati)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                console.log(datosRespuesta.data.datos);
                this.setState({ ListaPrefactura: datosRespuesta.data.datos });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    UpValores(id) {
        const { idsSeleccionados } = this.state;
        let valor = document.getElementById('valor' + id).value;
        var datos = { id: id, valor: valor }
        console.log(idsSeleccionados);
        authAxios.post("exterior/actualizavalor", datos)
            .then((datosRespuesta) => {

                var status = datosRespuesta.data.status;

            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    GuardarValores = () => {
        Swal.fire({
            title: 'Está seguro de Generar la prefactura?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Generar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.showLoading();
                let origen = document.getElementById('borigen').value;
                const { idsSeleccionados, Usuario } = this.state;
                if (idsSeleccionados.length === 0) {
                    Swal.fire(
                        'Debes de seleccionar los servicios',
                        '',
                        'warning'
                    )
                    return false;
                }

                var datos = { id: idsSeleccionados, usuario: Usuario, origen: origen }
                console.log(idsSeleccionados);
                authAxios.post("exterior/guardarseleccionado", datos)
                    .then((datosRespuesta) => {
                        var m = datosRespuesta.data.msj;
                        var status = datosRespuesta.data.status;
                        Swal.fire({
                            title: m,
                            text: 'Facturado',
                            icon: 'success',
                            showConfirmButton: true, // Mostrar el botón "Confirmar"
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.MostrarTabla();
                            }
                        });
                    })
                    .catch();
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    }
    AnularValores = () => {
        Swal.fire({
            title: 'Está seguro de Anular los servicios seleccionados?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Generar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                let origen = document.getElementById('borigen').value;
                const { idsSeleccionados, Usuario } = this.state;
                if (idsSeleccionados.length === 0) {
                    Swal.fire(
                        'Debes de seleccionar los servicios',
                        '',
                        'warning'
                    )
                    return false;
                }

                var datos = { id: idsSeleccionados, usuario: Usuario, origen: origen }
                console.log(idsSeleccionados);
                authAxios.post("exterior/anularseleccionado", datos)
                    .then((datosRespuesta) => {
                        var m = datosRespuesta.data.msj;
                        var status = datosRespuesta.data.status;
                        Swal.fire({
                            title: m,
                            text: 'Facturado',
                            icon: 'success',
                            showConfirmButton: true, // Mostrar el botón "Confirmar"
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.MostrarTabla();
                            }
                        });

                    })
                    .catch();
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    }
    handleSelectAll = () => {
        this.setState((prevState) => {
            const { ListaTabla, seleccionarTodo, idsSeleccionados } = prevState;

            let nuevosIdsSeleccionados = [];

            if (!seleccionarTodo) {
                // Si seleccionarTodo es falso, selecciona todos los IDs
                nuevosIdsSeleccionados = ListaTabla.map((item) => item.id);
            }
            console.log(nuevosIdsSeleccionados)
            return {
                seleccionarTodo: !seleccionarTodo,
                idsSeleccionados: nuevosIdsSeleccionados,
            };

        });
    };

    handleCheckboxChange = (id) => {
        this.setState((prevState) => {
            const { idsSeleccionados } = prevState;

            const nuevosIdsSeleccionados = [...idsSeleccionados];
            const index = nuevosIdsSeleccionados.indexOf(id);

            if (index === -1) {
                nuevosIdsSeleccionados.push(id);
            } else {
                nuevosIdsSeleccionados.splice(index, 1);
            }

            return { idsSeleccionados: nuevosIdsSeleccionados };
        });
    };
    componentDidMount() {

        this.MostrarClientes(1);

    }
    render() {
        const { load, ListaCiudadesFiltradas, ListaTabla, pagina, ultimo, ListaPrefactura, actual, Hoy, ListaAseguradoras, ListaEstados, ListaCliente, seleccionarTodo, idsSeleccionados, ListaDepartamentos, ListaCiudades } = this.state;
        return (
            <div className="content">
                <div className="container-fluid">
                    <br />
                    <div className="card">

                        <div className="card-body">

                            <h4>Cargue Masivo Ejecuciones</h4>
                            <hr />
                            <div className="row text-center">
                                <h3>Seleccione el metodo de Cargue</h3>
                                <form onSubmit={this.handleSubmit}>

                                    <div className="col-md-12">
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="customFile" onChange={this.handleFileChange} />
                                        </div>
                                    </div>
                                    <button type="submit" className='mt-2 btn btn-success'>Enviar</button>
                                </form>
                            </div>
                            <br></br>
                           

                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
                {/* modal crear clinente */}
                <div className="modal fade" id="FormularioModalCreacionCliente" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-lg"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Creacion Cliente </h4>
                                <button id='miBoton' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                <input type="hidden" id="item" name="item" />
                            </div>
                            <div className="modal-body">
                                <div id="form_registro_crear_cliente">

                                    <div className="card-body">
                                        <div className="card-body btn-showcase">
                                            <div id="formulario">
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <form onSubmit={this.guardarCliente}>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="tipoDocumento">Tipo Documento</label>
                                                                        <select class="form-control" id="tipoDocumento" aria-describedby="tipoDocumentoHelp">
                                                                            <option value="1">CC</option>
                                                                            <option value="2">CE</option>
                                                                            <option value="3">NIT</option>
                                                                        </select>
                                                                        <small id="tipoDocumentoHelp" class="form-text text-muted">Selecciona el tipo de documento.</small>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="nombres">Nombres</label>
                                                                        <input type="text" class="form-control" id="nombres" aria-describedby="nombresHelp" />
                                                                        <small id="nombresHelp" class="form-text text-muted">Ingresa tu nombre.</small>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="telefono">Telefono</label>
                                                                        <input type="tel" class="form-control" id="telefono" aria-describedby="telefonoHelp" />
                                                                        <small id="telefonoHelp" class="form-text text-muted">Ingresa tu número de teléfono.</small>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="departamento">Departamento</label>
                                                                        {/* <input type="text" class="form-control" id="departamento" aria-describedby="departamentoHelp" /> */}
                                                                        <select id="departamento" onChange={this.handleDepartamentoChange} name="departamento" className="form-control form-control-sm">
                                                                            <option value={''}>Seleccione el departamento</option>
                                                                            {
                                                                                ListaDepartamentos.map((item, i) => (
                                                                                    <option value={item.id} >{item.id} - {item.nombre}</option>
                                                                                ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="documento">Documento</label>
                                                                        <input type="text" class="form-control" id="documento" aria-describedby="documentoHelp" />
                                                                        <small id="documentoHelp" class="form-text text-muted">Ingresa tu número de documento.</small>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="email">Email</label>
                                                                        <input type="email" class="form-control" id="email" aria-describedby="emailHelp" />
                                                                        <small id="emailHelp" class="form-text text-muted">Ingresa tu dirección de correo electrónico.</small>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="apellidos">Apellidos</label>
                                                                        <input type="text" class="form-control" id="apellidos" aria-describedby="apellidosHelp" />
                                                                        <small id="apellidosHelp" class="form-text text-muted">Ingresa tus apellidos.</small>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="ciudad">Ciudad</label>
                                                                        <select id="ciudad" name="ciudad" className="form-control form-control-sm">
                                                                            <option value={''}>Seleccione el ciudad</option>
                                                                            {
                                                                                ListaCiudadesFiltradas.map((item, i) => (
                                                                                    <option value={item.id} >{item.id} - {item.nombre}</option>
                                                                                ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="direccion">Direccion</label>
                                                                <input type="text" class="form-control" id="direccion" aria-describedby="direccionHelp" />
                                                                <small id="direccionHelp" class="form-text text-muted">Ingresa tu dirección.</small>
                                                            </div>
                                                            <button type="submit" class="btn btn-primary mt-4">Guardar</button>
                                                        </form>




                                                    </div>
                                                </div>

                                            </div>

                                            <div className="modal-footer">
                                                {/* <button onClick={this.exportToExcel} className="btn btn-primary">Guardar Cliente</button> */}
                                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div >


                <div className="modal fade" id="FormularioModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-lg"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Detalle del servicio <span id='servicio'></span></h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                <input type="hidden" id="item" name="item" />
                            </div>
                            <div className="modal-body">
                                <div id="form_registro">

                                    <div className="card-body">
                                        <div className="card-body btn-showcase">
                                            <div id="formulario">
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className='row g-3'>


                                                            <div className="col-md-12">
                                                                <label htmlFor="idprefactura">Id Prefactura </label>
                                                                <input style={{ textTransform: 'uppercase' }} className={" form-control form-control-sm mt-2 mb-4"} type="text" name="idprefactura" id="idprefactura" aria-describedby="helpId" />

                                                            </div>



                                                        </div>

                                                        <table className="table table-hover table-condensed table-sm">

                                                            <thead className="thead-inverse  table-success ">
                                                                <tr>
                                                                    <th className="align-middle">ITEM</th>
                                                                    <th className="align-middle">PREFACTURA</th>
                                                                    <th className="align-middle">DOCUMENTO</th>
                                                                    <th className="align-middle">NOMBRE USUARIO</th>
                                                                    <th className="align-middle">CANT DE SERVICIO</th>
                                                                    <th className="align-middle">TOTAL</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    ListaPrefactura.map((item, i) => (
                                                                        <tr key={item.id}>
                                                                            <td>{i + 1}</td>
                                                                            <td>{item.id_prefactura}</td>
                                                                            <td>{item.documento}</td>
                                                                            <td>{item.nombre_cliente}</td>
                                                                            <td>{item.servicio}</td>
                                                                            <td>{item.total}</td>


                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>

                                                        </table>

                                                    </div>
                                                </div>
                                                <hr />
                                                <div className='row'>
                                                    <div className='col-xl-12 text-center'>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <button type="button" onClick={this.Enviar} className="btn btn-success btn-sm" id="btnservicio"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Consultar Prefactura</button>&nbsp;&nbsp;


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="modal-footer">
                                                <button onClick={this.exportToExcel} className="btn btn-primary">Descargar Excel</button>
                                                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cerrar</button>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>




            </div >
        );
    }
}

export default Listar;