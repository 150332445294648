import React, { useState, useEffect } from 'react';
import man from '../assets/images/dashboard/profile.jpg';
import { Container, Row, Col, Form, Input, Label, Button, TabContent, TabPane } from 'reactstrap'
import { useAuth0 } from '@auth0/auth0-react'
import { AUTH0 } from '../constant';
import { classes } from '../data/layouts';
import axios from 'axios';
import Swal from 'sweetalert2';
import Api from "../services/Api";

const Logins = (props) => {

  const { loginWithRedirect } = useAuth0()
  const [email, setEmail] = useState("");
  const [selected, setSelected] = useState("jwt");

  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const [value, setValue] = useState(
    localStorage.getItem('profileURL' || man)
  );
  const [name, setName] = useState(
    localStorage.getItem('Name')
  );

  useEffect(() => {

    localStorage.setItem('profileURL', value);
    localStorage.setItem('Name', name);
  }, [value, name, layout]);

  const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);



  const sesion = () => {
    if (!isEmail(email)) {
      Swal.fire('Debes de escribir un email valido!', '', 'error');
      return false;
    }
    var datosEnviar = { email: email }
    Swal.showLoading();
    axios
      .post(Api + "auth/recuperar", datosEnviar)
      .then(
        (response) => {
          
          if (response.data.estado == 1) {
            Swal.fire({
              title: response.data.msj,
              icon: 'success',
              showCancelButton: false,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = `${process.env.PUBLIC_URL}/login`;
                return response;
              }
            });

          } else {
            Swal.fire(response.data.msj, '', 'error');
          }
        },
        (error) => {
          Swal.fire('Email no registrado.', '', 'error');
        }
      );
  }




  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>

              <div className="login-main login-tab">
                <div>
                  <a className="logo" href="index.html">
                    <img className="img-fluid for-light" src={require("../assets/images/logov2.png")} alt="" />
                    <img className="img-fluid for-dark" src={require("../assets/images/logov2.png")} alt="" />
                  </a>
                </div>
                <TabContent activeTab={selected} className="content-login" style={{ height: 'auto', paddingBottom: '20px' }}>
                  <TabPane className="fade show" tabId={selected === "firebase" ? "firebase" : "jwt"}>
                    <Form className="theme-form">
                      <h4>Recuperar Contraseña</h4>
                      <p>{"Recibiras un email para que puedas recuperar tu contraseña."}</p>
                      <div className="mb-3">
                        <Label className="col-form-label">Email</Label>
                        <Input className="form-control" type="email" required="" onChange={e => setEmail(e.target.value)} defaultValue={email} />
                      </div>

                      <div className="login-btn mb-0">
                        <div className="checkbox ms-3">

                        </div><a className="link" href="login">Inicio de sesión</a>

                        <Button color="primary" onClick={() => sesion(email)}>Recuperar</Button>
                      </div>


                    </Form>
                  </TabPane>
                  <TabPane className="fade show" tabId="auth0">
                    <div className="auth-content">
                      <img src={require("../assets/images/auth-img.svg")} alt="" />
                      <h4>{"Welcome to login with Auth0"}</h4>
                      <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"}</p>
                      <Button color="info" onClick={loginWithRedirect}>{AUTH0}</Button>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Logins;