import React from 'react'
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import Api from "../../services/Api";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import tema from "../../services/Plantilla";
class Editar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        rol: [],
        errores: []
    }
    cambioValor = (e) => {
        const state = this.state.rol;
        state[e.target.name] = e.target.value;
        this.setState({ rol: state });
    };
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        const nombre = document.getElementById('nombre').value;
        const descripcion = document.getElementById('descripcion').value;
        var errores = [];
        if (!nombre) { errores.push("error_nombre"); }
        if (!descripcion) { errores.push("error_descripcion"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        

        authAxios.post("roles/editar", formData)
            .then((datosRespuesta) => {
                
                Swal.fire(
                    'Roles!',
                    datosRespuesta.data.msj,
                    'success'
                )
                this.props.history.push("/MasterRoles");
            })
            .catch()
    }
    componentDidMount() {
        Swal.showLoading();
        var ide = localStorage.getItem('ide');
        authAxios.get("roles/ver/" + ide)
            .then((datosRespuesta) => {
                
                this.setState({ datosCargados: true, rol: datosRespuesta.data.data, load: '' });
                Swal.close();
            })
            .catch();
    }
    render() {
        const { load, rol } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                {load ? (<b>{load}</b>) : (<b>EDITAR ROL</b>)}
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.enviarDatos} id="form">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-3">
                                                    <input type="hidden" name="id" id='id' defaultValue={rol.id} />
                                                    <label htmlFor="nombre">Nombre</label>
                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="nombre" id="nombre" defaultValue={rol.nombre}
                                                        className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} placeholder="NOMBRE" aria-describedby="helpId"
                                                        onChange={this.cambioValor}
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="descripcion">Descripción</label>
                                                    <textarea style={{ resize: 'none', textTransform: 'uppercase' }} id="descripcion" name="descripcion" rows='3'
                                                        className={((this.verificarError("error_descripcion")) ? "is-invalid" : "") + ' form-control form-control-sm'}
                                                        onChange={this.cambioValor}
                                                        defaultValue={rol.descripcion}>
                                                    </textarea>
                                                    <small id="helpId" className="invalid-feedback">Digite la Descripción</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="submit" className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Editar Rol</button>&nbsp;&nbsp;
                                                <Link className="btn btn-danger btn-sm" to={"/roles/lista/"+tema}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Editar;