import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { DefaultLayout } from '../../layout/theme-customizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import tema from "../../services/Plantilla";


class Listar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        datosCargados: false,
        ListaTabla: []
    }
    borrarRegistros = (id) => {
        Swal.fire({
            title: 'Está seguro de Eliminar el registro?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Borrar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                authAxios.get("banco/borrar/"+id)
                    .then((datosRespuesta) => {
                        
                        Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                        this.MostrarTabla(1);
                    })
                    .catch();
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };
    
    LinkEditar(ide){
        localStorage.setItem('ide', ide);
        
        //window.location.href = process.env.PUBLIC_URL+'/tipo_contrato/editar/'+tema;
    }

    MostrarTabla(page){
        Swal.showLoading();
        let bname = document.getElementById('bnombre').value;
        if (bname == '') {
            bname = 0;
          }
          let bestado = document.getElementById('bestado').value;
      
        authAxios.get("banco/listar/"+bname+"/"+bestado+"?page=" + page)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                
                
                this.setState({ datosCargados: true, load: '', ListaTabla: datosRespuesta.data.data, pagina: datosRespuesta.data.total, ultimo: datosRespuesta.data.last_page, actual: datosRespuesta.data.current_page });
            })
        .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    componentDidMount() {
        this.MostrarTabla(1);
    }
    render() {
        const { load, ListaTabla, pagina, ultimo, actual } = this.state;
        return (
            <div className="content">
                <div className="container-fluid">
                    <br />
                    <div className="card">
                        
                        <div className="card-body">
                       
                        <Link className="btn btn-success btn-sm" to={`${process.env.PUBLIC_URL}/bancos/crear/${tema}`}>&nbsp;&nbsp;Nuevo Registro</Link>
                            <h4>BANCOS REGISTRADOS</h4>
                            <hr />
                            <div className="row">
                                <div className="col-xl-3">
                                    <input style={{textTransform: 'uppercase'}} type="text" id="bnombre" name="bnombre" placeholder="banco" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                            <select name="bestado" id="bestado" className="form-control form-control-sm"
                                            
                                              onChange={this.cambioValor}>
                                                     
                                                        <option value="0">Activo</option>
                                                        <option value="1">Inactivo</option>
                                                    </select>
                                </div>
                                <div className="col-xl-3">
                                    <button id="bt" name="bt" className="btn btn-primary btn-xs" onClick={() => this.MostrarTabla(1)}>&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                            <th className="align-middle">ID</th>
                                            <th className="align-middle">NOMBRE</th>
                                            
                                            <th className="align-middle">ESTADO</th>
                                            <th className="align-middle">OPCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {
                                            ListaTabla.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.nombre}</td>
                                                  
                                                    
                                                    <td>
                                                        {
                                                            item.estado == '1' ? (
                                                                <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-danger">INACTIVO</span>
                                                            ) : (
                                                                <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-success">ACTIVO</span>
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                        
                                                            <Link className="btn btn-primary btn-xs" to={`${process.env.PUBLIC_URL}/bancos/editar/${tema}`}
                                                            onClick={() =>
                                                                this.LinkEditar(item.id)
                                                            }>
                                                             <FontAwesomeIcon icon={faEdit} />
                                                                </Link>
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger btn-xs"
                                                                onClick={() =>
                                                                    this.borrarRegistros(item.id)
                                                                }
                                                            >
                                                              <FontAwesomeIcon icon={faTrashAlt} />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.MostrarTabla(1)}> {"<<"} </a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.MostrarTabla(actual - 1)}>{"<"}</a></li>
                                    {(() => {
                                        let p = [];
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.MostrarTabla(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.MostrarTabla(actual + 1)}>{">"}</a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.MostrarTabla(ultimo)}>{">>"} </a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Listar;