import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DefaultLayout } from '../../layout/theme-customizer';

const id = window.location.pathname.split('/').pop()
  const defaultLayout = Object.keys(DefaultLayout);
  const layout = id ? id : defaultLayout
  
class Crear extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
       
        nombre: '',
        estado: '',
        icon: '',
        orden_mp: '',
        errores: []
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        const {nombre, estado,icon,orden_mp } = this.state;
        var errores = [];
        
        if (!nombre) { errores.push("error_nombre"); }
        if (!estado) { errores.push("error_estado"); }
        if (!icon) { errores.push("error_icon"); }
        if (!orden_mp) { errores.push("error_orden_mp"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        var datosEnviar = {
          
            nombre: nombre,
            estado: estado,
            icon: icon,
            orden_mp: orden_mp,
          
        }
        authAxios.post("configuracion/registrar", datosEnviar)
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;
               
                
                if (datosRespuesta.data.Status) {
                    Swal.fire(
                        'menu!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    this.props.history.push("/MasterConfiguracion");
                } else {
                    Swal.fire(
                        'Menu!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch()
    }
    componentDidMount() {
        
    }
    render() {
        const { nombre,estado,icon,orden_mp} = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                <b>REGISTRO DE MENU</b>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.enviarDatos}>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                          
                                             
                                                <div className="col-md-3">
                                                    <label htmlFor="nombre">Nombre </label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="nombre" id="nombre" value={nombre} placeholder="escriba nombre completo" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="estado">Estado</label>
                                                    <select name="estado"
                                                        id="estado"
                                                        className={((this.verificarError("error_estado")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el Estado</option>
                                                        <option value="0">Activo</option>
                                                        <option value="1">Inactivo</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Estado</small>
                                                </div>
                                               
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                          
                                             
                                                <div className="col-md-3">
                                                    <label htmlFor="icon">Icono </label>
                                                    <select name="icon"
                                                        id="icon"
                                                        value={icon}
                                                        options={icon}
                                                        className={((this.verificarError("error_icon")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="Folder">Folder</option>
                                                        <option value="Home">Home</option>
                                                        <option value="FolderPlus">FolderPlus</option>
                                                        <option value="Cloud">Cloud</option>
                                                        <option value="FileText">FileText</option>
                                                        <option value="Server">Server</option>
                                                        <option value="Users">Users</option>
                                                        <option value="ShoppingBag">ShoppingBag</option>
                                                        <option value="List">List</option>
                                                        <option value="Mail">Mail</option>
                                                        <option value="MessageCircle">MessageCircle</option>
                                                        <option value="Monitor">Monitor</option>
                                                        <option value="Clock">Clock</option>
                                                        <option value="Calendar">Calendar</option>
                                                        <option value="CheckSquare">CheckSquare</option>
                                                        <option value="Image">Image</option>
                                                        <option value="Film">Film</option>
                                                        <option value="Radio">Radio</option>
                                                        <option value="Map">Map</option>
                                                        <option value="Edit">Edit</option>
                                                        <option value="Package">Sunrise</option>
                                                        <option value="Activity">Activity</option>
                                                        <option value="Archive">Archive</option>
                                                        <option value="BarChart">BarChart</option>
                                                        <option value="Book">Book</option>
                                                        <option value="Briefcase">Briefcase</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="orden_mp">Orden en el menu</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_orden_mp")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="orden_mp" id="orden_mp" value={orden_mp} placeholder="orden" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                               
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="submit" className="btn btn-success btn-sm"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Crear Menu</button>&nbsp;&nbsp;
                                                <Link className="btn btn-danger btn-sm" to={`${process.env.PUBLIC_URL}/configuracion/lista/${layout}`}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Crear;