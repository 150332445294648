import React, { useState, useEffect } from 'react';
import man from '../assets/images/dashboard/profile.jpg';
import { Container, Row, Col, Form, Input, Label, Button, TabContent, TabPane } from 'reactstrap'
import { useAuth0 } from '@auth0/auth0-react'
import { AUTH0 } from '../constant';
import { classes } from '../data/layouts';
import axios from 'axios';
import Swal from 'sweetalert2';
import Api from "../services/Api";
import validator from 'validator'

const Logins = (props) => {

  const { loginWithRedirect } = useAuth0()
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [selected, setSelected] = useState("jwt");
  const [Token, setToken] = useState("");
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  const [value, setValue] = useState(
    localStorage.getItem('profileURL' || man)
  );
  const [name, setName] = useState(
    localStorage.getItem('Name')
  );

  useEffect(() => {
    let urlElements = window.location.href.split('/')

    setToken(urlElements[4]);
    localStorage.setItem('profileURL', value);
    localStorage.setItem('Name', name);
  }, [value, name, layout]);

  const validar = (pass) => {
    if (validator.isStrongPassword(pass, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1, minSymbols: 1
    })) {
      Swal.fire('La contraseña debe de contener minimo 8 caracteres, 1 letra minuscula, 1 mayuscula y un caracter especial!', '', 'error');
      return 0;
    } else {
      //alert('La contraseña debe de contener minimo 8 caracteres, 1 letra minuscula, 1 mayuscul y un caracter especial!')
      Swal.fire('La contraseña debe de contener minimo 8 caracteres, 1 letra minuscula, 1 mayuscula y un caracter especial!', '', 'error');
      return 1;
    }
  }

  const sesion = () => {

    let x = validar(password);

    if (x == 1) {
      Swal.fire('La contraseña debe de contener minimo 8 caracteres, 1 letra minuscula, 1 mayuscula y un caracter especial!', '', 'error');
      return false;
    }



    if (password == "") {
      Swal.fire('Debes de digitar la contraseña', '', 'error');
      return false;

    }
    if (password2 == "") {
      Swal.fire('Debes de repetir la contraseña', '', 'error');
      return false;

    }
    if (password !== password2) {
      Swal.fire('Las contraseñas no coinciden', '', 'error');
      return false;

    }

    var datosEnviar = { password: password, token: Token }
    Swal.showLoading();
    axios
      .post(Api + "auth/reestablecer", datosEnviar)
      .then(
        (response) => {
          
          var v = response.data.estado;
          if (v == '1') {
            Swal.fire({
              title: response.data.msj,
              icon: 'success',
              showCancelButton: false,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = `${process.env.PUBLIC_URL}/login`;
                return response;
              }
            });
          } else {
            Swal.fire(response.data.msj, '', 'error');
          }


        },
        (error) => {
          Swal.fire('Error al procesar la solicitud.', '', 'error');
        }
      );
  }


  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>

              <div className="login-main login-tab">
                <div>
                  <a className="logo" href="index.html">
                    <img className="img-fluid for-light" src={require("../assets/images/logov2.png")} alt="" />
                    <img className="img-fluid for-dark" src={require("../assets/images/logov2.png")} alt="" />
                  </a>
                </div>
                <TabContent activeTab={selected} className="content-login" style={{ height: 'auto', paddingBottom: '20px' }}>
                  <TabPane className="fade show" tabId={selected === "firebase" ? "firebase" : "jwt"}>
                    <Form className="theme-form">
                      <h4>Reestablecer Contraseña</h4>
                      <p>{"Recibiras un email para que puedas recuperar tu contraseña."}</p>
                      <div className="mb-3">
                        <Label className="col-form-label">Nueva Contraseña</Label>
                        <Input className="form-control" type="password" required="" onChange={e => setPassword(e.target.value)} defaultValue={password} />
                      </div>
                      <div className="mb-3">
                        <Label className="col-form-label">Repetir Contraseña</Label>
                        <Input className="form-control" type="password" required="" onChange={e => setPassword2(e.target.value)} defaultValue={password2} />
                      </div>

                      <div className="login-btn mb-0">
                        <div className="checkbox ms-3">

                        </div><a className="link" href="login">Inicio de sesión</a>

                        <Button color="primary" onClick={() => sesion()}>Reestablecer</Button>
                      </div>


                    </Form>
                  </TabPane>
                  <TabPane className="fade show" tabId="auth0">
                    <div className="auth-content">
                      <img src={require("../assets/images/auth-img.svg")} alt="" />
                      <h4>{"Welcome to login with Auth0"}</h4>
                      <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"}</p>
                      <Button color="info" onClick={loginWithRedirect}>{AUTH0}</Button>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Logins;