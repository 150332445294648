import React, { Fragment, useState, useEffect } from 'react';
import man from '../../assets/images/dashboard/profile.jpg'
import { FileText, LogIn, Mail, User, MessageSquare, Bell, Maximize, Search, ShoppingCart, Minus, Plus, X } from 'react-feather';
import { useNavigate } from 'react-router-dom'
import { firebase_app } from '../../data/config'
import { useAuth0 } from '@auth0/auth0-react'
import Bookmark from "../../layout/bookmark"
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { English, Deutsch, Español, Français, Português, 简体中文, Notification, DeliveryProcessing, OrderComplete, TicketsGenerated, DeliveryComplete, CheckAllNotification, ViewAll, MessageBox, EricaHughes, KoriThomas, Admin, Account, Inbox, Taskboard, LogOut, AinChavez, CheckOut, ShopingBag, OrderTotal, GoToyourcart } from '../../constant'
import { InputGroup, InputGroupText } from 'reactstrap';
import { classes } from '../../data/layouts';

const Rightbar = () => {

  const history = useNavigate();
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('')
  const [searchresponsive, setSearchresponsive] = useState(false)
  const [langdropdown, setLangdropdown] = useState(false)
  const [moonlight, setMoonlight] = useState(false)
  const [cartDropdown, setCartDropDown] = useState(false)
  const [notificationDropDown, setNotificationDropDown] = useState(false)
  const [chatDropDown, setChatDropDown] = useState(false)
  const { i18n } = useTranslation();
  const [selected, setSelected] = useState('en');
  const [Administrador, setAdministrador] = useState('en');

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    setSelected(lng);
  };


  const { logout } = useAuth0()
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"))

  useEffect(() => {
    setProfile(localStorage.getItem('profileURL') || man);
    setName(localStorage.getItem('Name'))
    setAdministrador(localStorage.getItem('admin'))
    
    
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
      document.body.classList.add("dark-only");
    }
    i18n.changeLanguage('en');
  }, []);

  const Logout_From_Firebase = () => {
    
    localStorage.removeItem('profileURL')
    localStorage.removeItem('token');
    localStorage.setItem("rol", null)
    firebase_app.auth().signOut()
    history(`${process.env.PUBLIC_URL}/login`)
  }

  const Logout_From_Auth0 = () => {
    
    localStorage.removeItem("auth0_profile")
    localStorage.setItem("authenticated", false)
    localStorage.setItem("rol", null)
    history(`${process.env.PUBLIC_URL}/login`)
    logout()
  }
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
  const RedirectToChats = () => {
    history(`${process.env.PUBLIC_URL}/app/users/userProfile/${layout}`)
  }
  const RedirectToChat = () => {
    history(`${process.env.PUBLIC_URL}/app/chat-app/${layout}`)
  }

  const UserMenuRedirect = (redirect) => {
    history(redirect)
  }

  //full screen function
  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const SeacrhResposive = (searchresponsive) => {
    if (searchresponsive) {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.add("open");
      document.querySelector(".more_lang").classList.remove("active");
    } else {
      setSearchresponsive(!searchresponsive)
      document.querySelector(".search-full").classList.remove("open");
    }
  }

  const LanguageSelection = (language) => {
    if (language) {
      setLangdropdown(!language)
    } else {
      setLangdropdown(!language)
    }
  }

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light)
      document.body.classList.add("light");
      document.body.classList.remove("dark-only");
      // document.body.className = "light"
      localStorage.setItem('layout_version', 'light');
    } else {
      setMoonlight(!light)
      document.body.classList.remove("light");
      document.body.classList.add("dark-only");
      // document.body.className = "dark-only"
      localStorage.setItem('layout_version', 'dark-only');
    }
  }

  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
         
          <li><span className="header-search"><Search onClick={() => SeacrhResposive(searchresponsive)} /></span></li>
          {/* <li className="onhover-dropdown">
            <div className="notification-box" onClick={() => setNotificationDropDown(!notificationDropDown)}><Bell /><span className="badge rounded-pill badge-secondary">2</span></div>
            <div className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active" : ""}`}>
              <h6 className="f-18 mb-0 dropdown-title">{Notification}</h6>
              <ul>
                <li className="b-l-primary border-4">
                  <p>{DeliveryProcessing} <span className="font-danger">{"10 min."}</span></p>
                </li>
                <li className="b-l-success border-4">
                  <p>{OrderComplete}<span className="font-success">{"1 hr"}</span></p>
                </li>
                <li className="b-l-info border-4">
                  <p>{TicketsGenerated}<span className="font-info">{"3 hr"}</span></p>
                </li>
                <li className="b-l-warning border-4">
                  <p>{DeliveryComplete}<span className="font-warning">{"6 hr"}</span></p>
                </li>
                <li><Link className="font-primary f-w-700" to={`${process.env.PUBLIC_URL}/app/ecommerce/orderhistory/${layout}`}>{CheckAllNotification}</Link>
                </li>
              </ul>
            </div>
          </li> */}
          
          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)} >
              {moonlight ? <i className="fa fa-lightbulb-o"></i> : <i className="fa fa-moon-o"></i>}
            </div>
            {/* <div className="mode" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div> */}
          </li>
         
          {/* <li className="onhover-dropdown" onClick={() => setChatDropDown(!chatDropDown)}><MessageSquare />
            <ul className={`chat-dropdown onhover-show-div ${chatDropDown ? "active" : ""}`}>
              <h6 className="f-18 mb-0 dropdown-title">{MessageBox}</h6>
              <li onClick={RedirectToChats}>
                <div className="media"><img className="img-fluid rounded-circle me-3" src={require("../../assets/images/user/1.jpg")} alt="" />
                  <div className="media-body"><span>{EricaHughes}</span>
                    <p>{"Lorem Ipsum is simply dummy..."}</p>
                    <p className="mb-0 font-primary">58 mins ago</p>
                  </div>
                  <span className="badge rounded-circle bg-primary">2</span>
                </div>
              </li>
              <li onClick={RedirectToChats}>
                <div className="media"><img className="img-fluid rounded-circle me-3" src={require("../../assets/images/user/2.jpg")} alt="" />
                  <div className="media-body"><span>{KoriThomas}</span>
                    <p>{"Lorem Ipsum is simply dummy..."}</p>
                    <p className="mb-0 font-primary">1 hr ago</p>
                  </div>
                  <span className="badge rounded-circle bg-primary">2</span>
                </div>
              </li>
              <li onClick={RedirectToChats}>
                <div className="media"><img className="img-fluid rounded-circle me-3" src={require("../../assets/images/user/4.jpg")} alt="" />
                  <div className="media-body"><span>{AinChavez}</span>
                    <p>{"Lorem Ipsum is simply dummy..."}</p>
                    <p className="mb-0 font-primary">32 mins ago</p>
                  </div>
                  <span className="badge rounded-circle bg-primary">2</span>
                </div>
              </li>
              <li className="text-center"> <a href="#javascript" className="font-primary" onClick={RedirectToChat}>{ViewAll}</a></li>
            </ul>
          </li> */}
          <li className="maximize"><a className="text-dark" href="#javascript" onClick={goFull}><Maximize /></a></li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <img className="b-r-10" src={authenticated ? auth0_profile.picture : profile} alt="" />
              <div className="media-body"><span>{authenticated ? auth0_profile.name : name}</span>
                <p className="mb-0 font-roboto">{Administrador} <i className="middle fa fa-angle-down"></i></p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li onClick={() => UserMenuRedirect(`${process.env.PUBLIC_URL}/pages/perfil/${layout}`)}><User /><span>Mi cuenta</span></li>
         
              <li onClick={authenticated ? Logout_From_Auth0 : Logout_From_Firebase}><LogIn /><span>Salir</span></li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>

  );
}
// export default translate(Rightbar);
export default Rightbar;