import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import Option from './SelectRelacion';
class Crear extends React.Component {
    constructor(props) {
        super(props);
        
    }
    state = {
        id_departamento: '',
        nombre: '',
        errores: [],
        idmenu: this.props.menu
    }
    saverel() {
        Swal.showLoading();
        let form = document.getElementById('form_registro_rel');
        let formData = new FormData(form);
        
        let u = localStorage.getItem('idusuario');

        authAxios.post(`master/registrar/` + this.props.menu + `/` + u, formData)
            .then((datosRespuesta) => {
                
                var status = datosRespuesta.data.status;

                if (datosRespuesta.data.Status) {
                    Swal.fire(
                        'Registro!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    document.getElementById("form_registro_rel").reset();
                    
                    
                    
                    this.props.actualizarRelaciones(this.props.tabla[0].nombre_tabla, this.props.traevalor);
                    //ListaTabla(`${idmenu}`,1);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Registro!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }

            })
            .catch()
    }
    seleccionador() {
        const idd = this.props.llave;
        //document.getElementById('').value =  this.props.relacionid;
    }
    componentDidMount() {
        this.seleccionador();
        //document.getElementById(this.props.llave).value =  this.props.relacionid;
    }
    render() {
        const { id_departamento, nombre } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <form id="form_registro_rel">
                            <div>
                                <div className="card-body">
                                    <h5>Formulario de Registro </h5>
                                    <ul className="nav nav-tabs" id="icon-tab" role="tablist">
                                        {
                                            this.props.pesta.map((row, i) => (
                                                <li key={i} className="nav-item"><a className={i == 0 ? "nav-link active" : "nav-link"} id="icon-home-tab" data-bs-toggle="tab" href={"#icon-" + row.pestana} role="tab" aria-controls={"icon-" + row.pestana} aria-selected="true"><i className={i == 0 ? 'icofont icofont-ui-home' : 'icofont icofont-files'}></i>{row.pestana}</a></li>

                                            ))
                                        }
                                    </ul>

                                    <div className="tab-content" id="icon-tabContent">
                                        {
                                            this.props.pesta.map((fila, i) => (
                                                <div className={i == 0 ? "tab-pane fade show active" : "tab-pane fade"} id={"icon-" + fila.pestana} role="tabpanel" aria-labelledby={"icon-" + fila.pestana + "-tab"}>

                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="card">
                                                                <div className="row card-body">
                                                                    {
                                                                        this.props.tabla.map((row, i) => (
                                                                            fila.pestana == row.pestana ?
                                                                                row.nombre_campo != 'id' ?
                                                                                    this.props.llave == row.nombre_campo ?
                                                                                        <input className={"form-control form-control-sm"} readOnly={i != 0 ? false : true} type={'hidden'} name={row.nombre_campo} id={row.nombre_campo} value={this.props.relacionid} />
                                                                                        :
                                                                                        <div className="col-md-4" key={i}>

                                                                                            {row.tipo_dato == 'blob' ?

                                                                                                <div className="gallery my-gallery card-body row" itemscope="">
                                                                                                    <figure className="col-xl-12 col-md-12 col-12" itemprop="associatedMedia" itemscope="">
                                                                                                        <a href="" target={'_blank'} itemprop="contentUrl" data-size="1600x950"  >

                                                                                                            <img className="img-thumbnail" id={'img' + row.nombre_campo} src="http://www.losprincipios.org/images/default.jpg" itemprop="thumbnail" alt="Image description" />


                                                                                                        </a>
                                                                                                        <figcaption itemprop="caption description">{row.nombre_titulo}</figcaption>
                                                                                                    </figure>
                                                                                                </div>
                                                                                                : <label htmlFor="cedula">{row.nombre_titulo} {row.obligatorio == '1' ? <span className="text-danger">*</span> : ''}</label>}

                                                                                            {

                                                                                                row.trae_tabla === null ?

                                                                                                    <input className={"form-control form-control-sm"} readOnly={i != 0 ? false : true} type={row.tipo_dato == 'int' ? 'number' : row.tipo_dato == 'date' ? 'date' : row.tipo_dato == 'time' ? 'time' : row.tipo_dato == 'blob' ? 'file' : 'text'} name={row.nombre_campo} id={row.nombre_campo} />
                                                                                                    : <Option t={row.trae_tabla} v={row.trae_value} c={row.trae_campo} nombre_input={row.nombre_campo} depende={row.depende_campo ? row.depende_campo : 0} tablaprimaria={row.nombre_tabla} />


                                                                                            }

                                                                                        </div>
                                                                                    : <input type="hidden" name={row.nombre_campo} id={row.nombre_campo} placeholder={row.nombre_campo} />
                                                                                : ''

                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>





                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary btn-sm" onClick={() => this.saverel()}>Guardar Registro</button>

                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Crear;