import React from 'react';
import Swal from 'sweetalert2';
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import { Modal } from 'react-bootstrap';

let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy =
    f[2].padStart(2, "0") +
    "-" +
    f[1].padStart(2, "0") +
    "-" +
    f[0].padStart(2, "0");

class Listar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datosCargados: false,
            ListaTabla: [],
            ListaEstados: [],
            ListaCliente: [],
            ListaCiudades: [],
            ListaCiudadesFiltradas: [],
            ListaDepartamentos: [],
            ListaAseguradoras: [],
            ListaPrefactura: [],
            Hoy: hoy,
            seleccionarTodo: false,
            idsSeleccionados: [],
            clientesConBoton: [],
            idClienteInfo: [],
            Usuario: localStorage.getItem('Name'),
            showModal: false,
            selectedImage: '',
            showModalPdf: false,
            selectedPdfid: '',
            pdfData: ''
        }
    }

    // Agrega un manejador de eventos para el cambio en el campo de selección de departamento
    handleDepartamentoChange = (event) => {
        const departamentoSeleccionado = event.target.value;

        // Filtra la lista de ciudades en función del departamento seleccionado
        const ciudadesFiltradas = this.state.ListaCiudades.filter(
            (ciudad) => ciudad.departamento === departamentoSeleccionado
        );

        this.setState({
            ListaCiudadesFiltradas: ciudadesFiltradas,
        });
    };
    exportToExcel = () => {
        let idx = document.getElementById('idprefactura').value;
        const { ListaPrefactura } = this.state;

        const ws = XLSX.utils.json_to_sheet(ListaPrefactura);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');

        XLSX.writeFile(wb, 'Prefactura' + idx + '.xlsx');
    };
    consultarClienteId(identificacion) {
        console.log(this.state.clientesConBoton)
        Swal.showLoading();
        authAxios.post("exterior/consulta-id-cliente/" + identificacion)
            .then((datosRespuesta) => {
                Swal.close();
                var respuesta = datosRespuesta.data.datos[0];
                console.log(datosRespuesta.data);
                // document.getElementById("tipoDocumento").value = respuesta.tipoDocumento;
                document.getElementById("nombres").value = respuesta.nombre;
                document.getElementById("telefono").value = respuesta.telefono;
                // document.getElementById("departamento").value = respuesta.departamento;
                document.getElementById("documento").value = respuesta.identificacion;
                document.getElementById("email").value = respuesta.email;
                document.getElementById("apellidos").value = respuesta.apellido;
                // document.getElementById("ciudad").value = respuesta.ciudad;
                document.getElementById("direccion").value = respuesta.direccion;

                // this.setState({ idClienteInfo: datosRespuesta.data.datos });
                // this.setState({ datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras });
            })
            .catch((err) => {
                console.log(err);
            });
    }
    guardarCliente = (e) => {

        e.preventDefault();
        Swal.showLoading();
        // Recopila los datos del formulario en un objeto data
        const data = {
            tipoDocumento: document.getElementById('tipoDocumento').value,
            nombres: document.getElementById('nombres').value,
            telefono: document.getElementById('telefono').value,
            departamento: document.getElementById('departamento').value,
            documento: document.getElementById('documento').value,
            email: document.getElementById('email').value,
            apellidos: document.getElementById('apellidos').value,
            ciudad: document.getElementById('ciudad').value,
            direccion: document.getElementById('direccion').value,
        };
        this.setState({ clientesConBoton: [] }, () => {
            // Código que depende del estado actualizado
            // Realiza la solicitud a la API con los datos del formulario
            authAxios.post('exterior/guardar-cliente', data)
                .then(response => {
                    Swal.close();
                    console.log(this.state.clientesConBoton)
                    this.MostrarTabla(1);
                    // Maneja la respuesta de la API si es necesario
                    console.log(response.data);
                    const miBoton = document.getElementById('miBoton');

                    // Simula un clic en el botón
                    miBoton.click();
                })
                .catch(error => {
                    // Maneja los errores si la solicitud falla
                    console.error(error);
                });
        });

    }
    MostrarTabla() {
        this.setState({ clientesConBoton: [] });
        Swal.showLoading();
        let bfecha = document.getElementById('bfecha').value;
        let bfecha2 = document.getElementById('bfecha2').value;
        // let origen = document.getElementById('borigen').value;
        // let estado = document.getElementById('bestado').value;


        if (bfecha == '' || bfecha2 == '') {
            Swal.fire(
                'Debes de seleccionar la fecha inicial y final',
                '',
                'warning'
            )
            return false;
        }
        var datos = { fechai: bfecha, fechaf: bfecha2 }
        this.setState({ clientesConBoton: [] }, () => {
            authAxios.post("exterior/consultapreoperacional", datos)
                .then((datosRespuesta) => {
                    Swal.close();
                    var status = datosRespuesta.data.status;

                    this.setState({ datosCargados: true, load: '', ListaTabla: datosRespuesta.data.datos, clientesConBoton: [] });
                })
                .catch(() => {
                    this.setState({ clientesConBoton: [] });
                });
        })
        //window.location.href = '/'; window.localStorage.clear();
    }
    MostrarClientes() {

        authAxios.get("exterior/consultacliente")
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                console.log(datosRespuesta.data);
                this.setState({
                    datosCargados: true, load: '', ListaCliente: datosRespuesta.data.datos, ListaEstados: datosRespuesta.data.estados, ListaAseguradoras: datosRespuesta.data.asegurdoras, ListaCiudades: datosRespuesta.data.ciudades,
                    ListaDepartamentos: datosRespuesta.data.departamentos
                });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    Enviar = () => {
        let id = document.getElementById('idprefactura').value;
        var dati = { id: id }
        authAxios.post("exterior/consultarprefactura", dati)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                console.log(datosRespuesta.data.datos);
                this.setState({ ListaPrefactura: datosRespuesta.data.datos });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    UpValores(id) {
        const { idsSeleccionados } = this.state;
        let valor = document.getElementById('valor' + id).value;
        var datos = { id: id, valor: valor }
        console.log(idsSeleccionados);
        authAxios.post("exterior/actualizavalor", datos)
            .then((datosRespuesta) => {

                var status = datosRespuesta.data.status;

            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    GuardarValores = () => {
        Swal.fire({
            title: '¿Está seguro de importar los servicios?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Importar',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                let origen = document.getElementById('borigen').value;
                const { idsSeleccionados, Usuario } = this.state;
                if (idsSeleccionados.length === 0) {
                    Swal.fire(
                        'Debes de seleccionar los servicios',
                        '',
                        'warning'
                    );
                    return false;
                }

                var datos = { id: idsSeleccionados, usuario: Usuario, origen: origen }
                Swal.showLoading();
                authAxios.post("exterior/guardarseleccionado-servicios-externos", datos)
                    .then((datosRespuesta) => {
                        var m = datosRespuesta.data.msj;
                        var status = datosRespuesta.data.status;
                        var servicios = datosRespuesta.data.servicios_creados;
                        var errores = datosRespuesta.data.vh_no_creados;

                        var serviciosHtml = '';
                        servicios.forEach((servicio, index) => {
                            serviciosHtml += `
                                <tr>
                                    <th scope="row">${index + 1}</th>
                                    <td>${servicio}</td>
                                </tr>
                            `;
                        });
                        var erroresHtml = '';
                        errores.forEach((servicio, index) => {
                            erroresHtml += `
                                <tr>
                                    <th scope="row">${index + 1}</th>
                                    <td>${servicio}</td>
                                </tr>
                            `;
                        });
                        Swal.fire({
                            title: 'Servicios Importados',
                            icon: 'success',
                            allowOutsideClick: false,
                            html: `
                                <ul className="nav nav-pills mb-3" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Resueltos</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Errores</button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <h6>Servicios creados</h6>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Servicios</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    ${serviciosHtml}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <h6>Servicios no creados</h6>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">VEHICULOS NO CREADOS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                ${erroresHtml}
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            `,
                            showConfirmButton: true, // Permitir al usuario cerrar manualmente
                            confirmButtonText: 'Cerrar',
                            didClose: () => {
                                // Esta función se ejecutará cuando el usuario cierre el SweetAlert
                                this.MostrarTabla();
                            }
                        });


                    })
                    .catch(error => {
                        console.error('Error al importar los servicios:', error);
                    });
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info');
            }
        });
    }

    AnularValores = () => {
        Swal.fire({
            title: 'Está seguro de Anular los servicios seleccionados?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Generar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                let origen = document.getElementById('borigen').value;
                const { idsSeleccionados, Usuario } = this.state;
                if (idsSeleccionados.length === 0) {
                    Swal.fire(
                        'Debes de seleccionar los servicios',
                        '',
                        'warning'
                    )
                    return false;
                }

                var datos = { id: idsSeleccionados, usuario: Usuario, origen: origen }
                console.log(idsSeleccionados);
                authAxios.post("exterior/anularseleccionado", datos)
                    .then((datosRespuesta) => {
                        var m = datosRespuesta.data.msj;
                        var status = datosRespuesta.data.status;
                        Swal.fire({
                            title: m,
                            text: 'Facturado',
                            icon: 'success',
                            showConfirmButton: true, // Mostrar el botón "Confirmar"
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.MostrarTabla();
                            }
                        });

                    })
                    .catch();
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    }
    handleSelectAll = () => {
        this.setState((prevState) => {
            const { ListaTabla, seleccionarTodo, idsSeleccionados } = prevState;

            let nuevosIdsSeleccionados = [];

            if (!seleccionarTodo) {
                // Si seleccionarTodo es falso, selecciona todos los IDs
                nuevosIdsSeleccionados = ListaTabla.map((item) => item.id);
            }
            console.log(nuevosIdsSeleccionados)
            return {
                seleccionarTodo: !seleccionarTodo,
                idsSeleccionados: nuevosIdsSeleccionados,
            };

        });
    };

    handleCheckboxChange = (id) => {
        this.setState((prevState) => {
            const { idsSeleccionados } = prevState;

            const nuevosIdsSeleccionados = [...idsSeleccionados];
            const index = nuevosIdsSeleccionados.indexOf(id);

            if (index === -1) {
                nuevosIdsSeleccionados.push(id);
            } else {
                nuevosIdsSeleccionados.splice(index, 1);
            }

            return { idsSeleccionados: nuevosIdsSeleccionados };
        });
    };
    componentDidMount() {

        this.MostrarClientes(1);

    }
    handleShow = (image) => {
        this.setState({ showModal: true, selectedImage: image }, () => {
            // $('#imageModal').modal('show');
        });
    };
    handleShowpdf = (id) => {
        Swal.showLoading();
        authAxios.post("exterior/preoperacional/" + id)
            .then((datosRespuesta) => {
                Swal.close();
                const data = datosRespuesta.data.datos;
                var status = datosRespuesta.data.status;
                console.log(datosRespuesta.data);
                this.setState({ showModalPdf: true, selectedPdfid: id, pdfData: data }, () => {
                    // $('#imageModalPdf').modal('show');
                });
            })
            .catch();

    };

    handleClose = () => {
        this.setState({ showModalPdf: false, selectedPdfid: '' }, () => {
            // $('#imageModal').modal('hide');
        });
    };
    handleClosePdf = () => {
        this.setState({ showModal: false, selectedImage: '' }, () => {
            // $('#imageModal').modal('hide');
        });
    };
    render() {
        const { load, ListaCiudadesFiltradas, ListaTabla, pagina, ultimo, ListaPrefactura, actual, Hoy, ListaAseguradoras, ListaEstados, ListaCliente, seleccionarTodo, idsSeleccionados, ListaDepartamentos, ListaCiudades, showModalPdf, handleClose, showModal, handleClosePdf, pdfData } = this.state;
        return (
            <div className="content">
                <div className="container-fluid">
                    <br />
                    <div className="card">

                        <div className="card-body">

                            <h4>PREOPERACIONALES TODOS</h4>
                            <hr />
                            <div className="row">
                                {/* <div className="col-xl-2">
                                    <select id="borigen" name="borigen" className="form-control form-control-sm" >
                                        <option value={''}>Seleccione el conductor</option>
                                        {
                                            ListaCliente.map((item, i) => (
                                                <option value={item.id} >{item.id} - {item.nombre}</option>
                                            ))}
                                    </select>
                                </div>
                                <div className="col-xl-2">
                                    <select id="bestado" name="bestado" className="form-control form-control-sm" >
                                        <option value={''}>Seleccione vehiculo</option>
                                        {
                                            ListaEstados.map((ite, i) => (
                                                <option key={ite.id} value={ite.id}>{ite.id} - {ite.nombre}</option>
                                            ))}
                                    </select>
                                </div> */}
                                <div className="col-xl-2">
                                    <input style={{ textTransform: 'uppercase' }} type="date" id="bfecha" name="bfecha" placeholder="Buscar por expediente" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-2">
                                    <input style={{ textTransform: 'uppercase' }} type="date" id="bfecha2" name="bfecha2" placeholder="Buscar por fecha" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-2">
                                    <button id="bt" name="bt" className="btn btn-primary btn-sm" onClick={() => this.MostrarTabla(1)}>&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br>
                            <div className="row table-responsive">
                                <table className="table table-hover table-condensed table-sm">

                                    <thead className="thead-inverse  table-success ">
                                        <tr>
                                            <th className="align-middle">#</th>
                                            <th className="align-middle">ID</th>
                                            <th className="align-middle">VEHICULO</th>
                                            <th className="align-middle">CONDUCTOR</th>
                                            <th className="align-middle">KILOMETRAJE</th>
                                            <th className="align-middle">IMAGEN ODOMETRO</th>
                                            <th className="align-middle">FECHA</th>
                                            <th className="align-middle">PDF PREOPERACIONAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {

                                            ListaTabla.map((item, i) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{i + 1}</td>
                                                        <td>{item.id}</td>
                                                        <td>{item.placa}</td>
                                                        <td>{item.nombre} {item.apellidos}</td>
                                                        <td>{item.kilometraje}</td>
                                                        <td>
                                                            <img
                                                                src={'https://app.transorientesas.com/storage/' + item.imagen}
                                                                alt="thumbnail"
                                                                style={{ width: '50px', cursor: 'pointer' }}
                                                                onClick={() => this.handleShow(item.imagen)}
                                                            />
                                                        </td>
                                                        <td>{item.fecha}</td>
                                                        <td><button style={{ 'background': '#00AA4F' }} className='btn' onClick={() => this.handleShowpdf(item.id)}>VER PDF</button></td>
                                                    </tr>
                                                );
                                            })
                                        }


                                    </tbody>

                                </table>

                            </div>

                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
                <Modal
                    show={showModal}
                    onHide={this.handleClosePdf}
                    size="xl"
                    aria-labelledby="imageModalPdfLabel"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="imageModalPdfLabel">PREOPERACIONAL FORMATO</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body">
                            <img src={'https://app.transorientesas.com/storage/' + this.state.selectedImage} alt="large view" style={{ width: '100%' }} />
                        </div>
                    </Modal.Body>
                </Modal>



                <style>
                    {`
                    #modalbody td {
                        margin-top: 2px;
                        margin-left: 5px;
                        margin-right: 5px;
                        margin-bottom: 2px;
                        font-family: Arial, sans-serif;
                        border: 1px solid;
                        color: #000;
                    }

                    .Estilo4 {
                        font-size: 0.7em;
                    }

                    .writetd {
                        font-size: 0.7em;
                    }

                    .date_input {
                        border: 0px;
                        background-color: rgba(255, 255, 255, .5);
                        visibility: hidden;
                    }

                    .input_lists {
                        border: 0px;
                        background-color: rgba(255, 255, 255, .5);
                    }

                    .button_input {
                        border: 0px;
                        background-color: rgba(255, 255, 255, .5);
                    }

                    .button_input:hover {
                        background-color: red;
                    }

                    .theader {
                        background-color: #00AA4F !important;
                        font-weight: bold;
                        font-size: 0.8em;
                        color: #000;
                    }
                `}
                </style>

                <Modal
                    show={showModalPdf}
                    onHide={this.handleClose}
                    size="xl"
                    aria-labelledby="imageModalPdfLabel"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="imageModalPdfLabel">PREOPERACIONAL FORMATO</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="modal-body" id="modalbody">
                            <table width='100%' className='table_image' border cellspacing='0' align='center'>
                                <tr>
                                    <td width='100%' rowspan='3' align='center' valign='middle'>
                                        <img src='https://app.transorientesas.com/static/media/logov2.7e4832492b0a9b08f12e.png'
                                            border='0' className='print_image' height='50' width='300' />
                                    </td>
                                </tr>
                            </table>
                            <table border='0' cellspacing='0' cellpadding='0' width='100%' align='center'
                                className="mt-4">
                            </table>



                            <table cellspacing='0' width="100%" border='1'>

                                <tr height='24'>
                                    <td width='82' height='24' className='Estilo4 theader'>PLACA:</td>
                                    <td colspan='7' className='Estilo4'>
                                        <input className="input_lists" value={pdfData.placa || ''} id="placa_lists" disabled />
                                    </td>
                                    <td width='78' className='Estilo4 theader'>Fecha:</td>
                                    <td colspan='5' className='Estilo4' ><input className="input_lists" value={pdfData.fecha || ''}
                                        id="einput0" disabled /></td>
                                </tr>
                                <tr height='24'>
                                    <td height='24' colspan='4' className='Estilo4 theader'>Responsable inspecci&oacute;n:
                                    </td>
                                    <td colspan='10' className='Estilo4 writetd' contenteditable="true"><input value={pdfData.nombre + ' ' + pdfData.apellidos || ''}
                                        className="input_lists" id="responsable_ins" style={{ 'width': '200px' }} disabled /></td>
                                </tr>
                                <tr height='8'>
                                    <td height='8' colspan='14' className='Estilo4'>&nbsp;</td>
                                </tr>
                                <tr height='20'>
                                    <td height='38' colspan='5' rowspan='2' className='Estilo4 theader'>
                                        <div align='center'>TIPO</div>
                                    </td>
                                    <td colspan='6' rowspan='2' width="20%" className='Estilo4 theader'>
                                        <div align='center'>CRITERIO</div>
                                    </td>
                                    <td colspan='2' className='Estilo4 theader'>
                                        <div align='center'>CONFORME</div>
                                    </td>
                                    <td width='257' rowspan='2' className='Estilo4 theader'>
                                        <div align='center'>OBSERVACIONES</div>
                                    </td>
                                </tr>
                                <tr height='18'>
                                    <td width='40' height='18' className='Estilo4 theader'>
                                        <div align='center'>SI</div>
                                    </td>
                                    <td width='46' className='Estilo4 theader'>
                                        <div align='center'>NO</div>
                                    </td>
                                </tr>

                                <tr height='20'>
                                    <td height='54' colspan='2' rowspan='7' className='Estilo4 theader'>DOCUMENTOS</td>
                                    <td colspan='3' className='Estilo4'>Licencia de conduccion</td>
                                    <td colspan='6' rowspan='7' className='Estilo4'>Verificar su presencia y fecha de
                                        vigencia adecuada</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="licencia_conduccion"
                                                checked={pdfData.licencia_conduccion === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="licencia_conduccion"
                                                checked={pdfData.licencia_conduccion === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className='Estilo4 writetd' rowspan="7" contenteditable="true"
                                        id="obs_documentos">&nbsp;{pdfData.obs_documentos}</td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Tarjeta propiedad</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="tarjeta_propiedad"
                                                checked={pdfData.tarjeta_propiedad === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="tarjeta_propiedad"
                                                checked={pdfData.tarjeta_propiedad === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>


                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>SOAT</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="soat"
                                                checked={pdfData.soat === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="soat"
                                                checked={pdfData.soat === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>


                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Poliza RCE y RCC</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="poliza_rce_rcc"
                                                checked={pdfData.poliza_rce_rcc === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="poliza_rce_rcc"
                                                checked={pdfData.poliza_rce_rcc === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>


                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Tarjeta Operacion</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="tarjeta_op"
                                                checked={pdfData.tarjeta_op === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="tarjeta_op"
                                                checked={pdfData.tarjeta_op === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>


                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Preventiva</td>
                                    <td className='Estilo4'>
                                        <div style={{ 'textAlign': 'center' }}><input type='radio' name='colilla_mantenimiento' />
                                        </div>
                                    </td>
                                    <td className='Estilo4'>
                                        <div style={{ 'textAlign': 'center' }}><input type='radio' name='colilla_mantenimientoNo' />
                                        </div>
                                    </td>

                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Revision Tecnomecanica</td>
                                    <td className='Estilo4'>
                                        <div style={{ 'textAlign': 'center' }}><input type='radio' name='colilla_mantenimiento' />
                                        </div>
                                    </td>
                                    <td className='Estilo4'>
                                        <div style={{ 'textAlign': 'center' }}><input type='radio' name='colilla_mantenimientoNo' />
                                        </div>
                                    </td>

                                </tr>

                                <tr height='20'>
                                    <td height='37' colspan='2' rowspan='7' className='Estilo4 theader'>
                                        LOGOS REGLAMENTARIOS</td>
                                    <td colspan='3' className='Estilo4'>Velocidad Maxima</td>
                                    <td colspan='6' rowspan='7' className='Estilo4'>Verificar su presencia, fijacion e informacion legible.</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="velocidad_maxima"
                                                checked={pdfData.velocidad_maxima === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="velocidad_maxima"
                                                checked={pdfData.velocidad_maxima === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className='Estilo4 writetd' rowspan="7" contenteditable="true"
                                        id="obs_calcomanias">&nbsp;{pdfData.obs_calcomanias}</td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Servicio Especial</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="servicio_especial"
                                                checked={pdfData.servicio_especial === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="servicio_especial"
                                                checked={pdfData.servicio_especial === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Operacion Nacional</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="operacion_nacional"
                                                checked={pdfData.operacion_nacional === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="operacion_nacional"
                                                checked={pdfData.operacion_nacional === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Placas a los costados y techo</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="placas_costado"
                                                checked={pdfData.placas_costado === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="placas_costado"
                                                checked={pdfData.placas_costado === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Como Conduzco</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="como_conduzco"
                                                checked={pdfData.como_conduzco === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="como_conduzco"
                                                checked={pdfData.como_conduzco === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Logos Transoriente</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="logo_to"
                                                checked={pdfData.logo_to === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="logo_to"
                                                checked={pdfData.logo_to === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Logo Movil / Tarjeta Operacion</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="logo_movil"
                                                checked={pdfData.logo_movil === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="logo_movil"
                                                checked={pdfData.logo_movil === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='20'>
                                    <td height='88' colspan='2' rowspan='5' className='Estilo4 theader'>LUCES</td>
                                    <td colspan='3' className='Estilo4'>Altas</td>
                                    <td colspan='6' rowspan='5' className='Estilo4'>Funcionamiento de bombillos, cubiertas
                                        sin roturas, respuesta
                                        inmediata</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="altas"
                                                checked={pdfData.altas === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="altas"
                                                checked={pdfData.altas === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className='Estilo4 writetd' rowspan='5' contenteditable="true"
                                        id="obs_luces">&nbsp;{pdfData.obs_luces}</td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Bajas</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="bajas"
                                                checked={pdfData.bajas === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="bajas"
                                                checked={pdfData.bajas === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Stops</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="stops"
                                                checked={pdfData.stops === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="stops"
                                                checked={pdfData.stops === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Reversa</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="reversa"
                                                checked={pdfData.reversa === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="reversa"
                                                checked={pdfData.reversa === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Parqueo</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="parqueo"
                                                checked={pdfData.parqueo === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="parqueo"
                                                checked={pdfData.parqueo === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>

                                <tr height='17'>
                                    <td height='68' colspan='2' rowspan='3' className='Estilo4 theader'>LIMPIABRISAS</td>
                                    <td colspan='3' className='Estilo4'>Derecho</td>
                                    <td colspan='6' rowSpan='3' className='Estilo4'>Plumina funcional, en buen estado de estructura y limpieza.</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="derecho"
                                                checked={pdfData.derecho === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="derecho"
                                                checked={pdfData.derecho === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className='Estilo4 writetd' rowspan='3' contenteditable="true">&nbsp;{pdfData.obs_limpiabrisas}</td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Izquierdo</td>
                                    {/* <td colspan='6' className='Estilo4'>Coincide la placa y est&aacute; en buen estado</td> */}
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="izquierdo"
                                                checked={pdfData.izquierdo === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="izquierdo"
                                                checked={pdfData.izquierdo === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Trasero</td>
                                    {/* <td colspan='6' className='Estilo4'>Funcionando y tapada</td> */}
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="trasero"
                                                checked={pdfData.trasero === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="trasero"
                                                checked={pdfData.trasero === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>


                                <tr height='20'>
                                    <td height='37' colspan='2' rowspan='2' className='Estilo4 theader'>FRENOS</td>
                                    <td colspan='3' className='Estilo4'>Principal</td>
                                    <td colspan='6' rowspan='2' className='Estilo4'>Verificar antes de iniciar un servicio
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="principal"
                                                checked={pdfData.principal === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="principal"
                                                checked={pdfData.principal === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className='Estilo4 writetd' rowspan='2' contenteditable="true">&nbsp;{pdfData.obs_frenos}</td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Emergencia</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="emergencia"
                                                checked={pdfData.emergencia === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="emergencia"
                                                checked={pdfData.emergencia === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='20'>
                                    <td height='37' colspan='2' rowspan='3' className='Estilo4 theader'>LLANTAS</td>
                                    <td colspan='3' className='Estilo4'>Delanteras</td>
                                    <td colspan='6' rowspan='3' className='Estilo4'>Verificar presi&oacute;n y profundidad
                                        de labrado</td>
                                        <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="delanteros_1"
                                                checked={pdfData.delanteros_1 === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="delanteros_1"
                                                checked={pdfData.delanteros_1 === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className='Estilo4 writetd' rowspan='3' contenteditable="true">&nbsp;{pdfData.obs_llantas}</td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Traseras</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="traseros_1"
                                                checked={pdfData.traseros_1 === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="traseros_1"
                                                checked={pdfData.traseros_1 === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Repuestos</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="repuesto"
                                                checked={pdfData.repuesto === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="repuesto"
                                                checked={pdfData.repuesto === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>

                                <tr height='20'>
                                    <td height='37' colspan='2' rowspan='2' className='Estilo4 theader'>ESPEJOS</td>
                                    <td colspan='3' className='Estilo4'>Laterales</td>
                                    <td colspan='6' rowspan='2' className='Estilo4'>Verificar cubiertas sin roturas y
                                        limpieza</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="laterales"
                                                checked={pdfData.laterales === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="laterales"
                                                checked={pdfData.laterales === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className='Estilo4 writetd' rowspan='2' contenteditable="true">&nbsp;{pdfData.obs_espejos}</td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Retrovisor</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="retrovisor"
                                                checked={pdfData.retrovisor === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="retrovisor"
                                                checked={pdfData.retrovisor === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>

                                <tr height='20'>
                                    <td height='54' colspan='2' rowspan='2' className='Estilo4 theader'>VIDRIOS</td>
                                    <td colspan='3' className='Estilo4'>Delanteros</td>
                                    <td colspan='6' rowspan='2' className='Estilo4'>Suban y bajan de manera correcta al accionar los controles.</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="delanteros_2"
                                                checked={pdfData.delanteros_2 === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="delanteros_2"
                                                checked={pdfData.delanteros_2 === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className='Estilo4 writetd' rowspan='2' contenteditable="true">&nbsp;{pdfData.obs_vidrios}</td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Traseros</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="traseros_2"
                                                checked={pdfData.traseros_2 === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="traseros_2"
                                                checked={pdfData.traseros_2 === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>

                                <tr height='20'>
                                    <td height='37' colspan='2' rowspan='2' className='Estilo4 theader'>CINTURONES DE SEGURIDAD</td>
                                    <td colspan='3' className='Estilo4'>Delanteros</td>
                                    <td colspan='6' rowspan='2' className='Estilo4'>Verificar su presencia, estado y funcionalidad</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="delanteros_4"
                                                checked={pdfData.delanteros_4 === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="delanteros_4"
                                                checked={pdfData.delanteros_4 === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className='Estilo4 writetd' rowspan='2' contenteditable="true">&nbsp;{pdfData.obs_cinturon}</td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Traseros</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="traseros_4"
                                                checked={pdfData.traseros_4 === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="traseros_4"
                                                checked={pdfData.traseros_4 === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='20'>
                                    <td height='37' colspan='2' rowspan='6' className='Estilo4 theader'>FLUIDOS</td>
                                    <td colspan='3' className='Estilo4'>Frenos</td>
                                    <td colspan='6' rowspan='6' className='Estilo4'>Verificar niveles sin que se presentes fugas.</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="frenos"
                                                checked={pdfData.frenos === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="frenos"
                                                checked={pdfData.frenos === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className='Estilo4 writetd' rowspan='6' contenteditable="true">&nbsp;{pdfData.obs_fluidos}</td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Aceite</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="aceite"
                                                checked={pdfData.aceite === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="aceite"
                                                checked={pdfData.aceite === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>hidraulico</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="hidraulico"
                                                checked={pdfData.hidraulico === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="hidraulico"
                                                checked={pdfData.hidraulico === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Limpia brisas</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="limpia_brisas"
                                                checked={pdfData.limpia_brisas === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="limpia_brisas"
                                                checked={pdfData.limpia_brisas === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Refrigerante o agua</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="refrigerante"
                                                checked={pdfData.refrigerante === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="refrigerante"
                                                checked={pdfData.refrigerante === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='3' className='Estilo4'>Estado de la bateria</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="estado_bateria"
                                                checked={pdfData.estado_bateria === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="estado_bateria"
                                                checked={pdfData.estado_bateria === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='20'>
                                    <td height='37' colspan='2' rowspan='8' className='Estilo4 theader'>KIT DE CARRETERA</td>
                                    <td colspan='9' className='Estilo4'>Minimo dos llaves, una expansiva, alicantes y destornilladores de pala y estrella.</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="herramientas"
                                                checked={pdfData.herramientas === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="herramientas"
                                                checked={pdfData.herramientas === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className='Estilo4 writetd' rowspan='8' contenteditable="true">&nbsp;{pdfData.obs_kit}</td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>Estado del extintor (presurizado)</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="extintor"
                                                checked={pdfData.extintor === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="extintor"
                                                checked={pdfData.extintor === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>Fecha de vencimiento del extintor</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="extintor_fecha"
                                                checked={pdfData.extintor_fecha === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="extintor_fecha"
                                                checked={pdfData.extintor_fecha === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>Cruceta o palanca</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="cruceta"
                                                checked={pdfData.cruceta === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="cruceta"
                                                checked={pdfData.cruceta === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>Linterna - presente y con bateria.</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="linterna"
                                                checked={pdfData.linterna === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="linterna"
                                                checked={pdfData.linterna === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>Gato - Que soporte el peso.</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="gato"
                                                checked={pdfData.gato === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="gato"
                                                checked={pdfData.gato === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>Dos tacos para bloquear el vehiculo.</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="tacos"
                                                checked={pdfData.tacos === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="tacos"
                                                checked={pdfData.tacos === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>Dos señales reflectivas de carretera (Triangulos,conos,etc...)</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="senales"
                                                checked={pdfData.senales === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="senales"
                                                checked={pdfData.senales === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                {/* --------------------------------------  */}
                                <tr height='20'>
                                    <td height='37' colspan='2' rowspan='11' className='Estilo4 theader'>BOTIQUIN</td>
                                    <td colspan='9' className='Estilo4'>¿Tiene guantes?</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="guantes_t"
                                                checked={pdfData.guantes_t === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="guantes_t"
                                                checked={pdfData.guantes_t === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className='Estilo4 writetd' rowspan='11' contenteditable="true">&nbsp;</td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>¿Tiene gasa esteril?</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="gasa_t"
                                                checked={pdfData.gasa_t === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="gasa_t"
                                                checked={pdfData.gasa_t === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>¿Tiene elementos de corte (bisturi o tijeras)?</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="bisturi_t"
                                                checked={pdfData.bisturi_t === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="bisturi_t"
                                                checked={pdfData.bisturi_t === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>¿Tiene bajalenguas?</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="bajalenguas_t"
                                                checked={pdfData.bajalenguas_t === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="bajalenguas_t"
                                                checked={pdfData.bajalenguas_t === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>¿Tiene vendas elasticas para sujetar compresiones?</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="vendas_t"
                                                checked={pdfData.vendas_t === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="vendas_t"
                                                checked={pdfData.vendas_t === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>¿Tiene algodon?</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="algodon_t"
                                                checked={pdfData.algodon_t === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="algodon_t"
                                                checked={pdfData.algodon_t === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>¿Tiene curitas,cinta microporo o esparadrapo?</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="curitas_t"
                                                checked={pdfData.curitas_t === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="curitas_t"
                                                checked={pdfData.curitas_t === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>¿Tiene agua oxigenada?</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="agua_t"
                                                checked={pdfData.agua_t === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="agua_t"
                                                checked={pdfData.agua_t === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>¿Tiene alcohol antiseptico?</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="alcohol_t"
                                                checked={pdfData.alcohol_t === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="alcohol_t"
                                                checked={pdfData.alcohol_t === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>¿Tiene suero fisiologico?</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="suero_t"
                                                checked={pdfData.suero_t === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="suero_t"
                                                checked={pdfData.suero_t === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr height='17'>
                                    <td height='17' colspan='9' className='Estilo4'>¿Tiene yodopovidona (Isodine para heridas en la piel)?</td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="yodo_t"
                                                checked={pdfData.yodo_t === "Si"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td className="Estilo4">
                                        <div style={{ textAlign: 'center' }}>
                                            <input
                                                type="radio"
                                                name="yodo_t"
                                                checked={pdfData.yodo_t === "No"}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="14" className="theader"><b>OBSERVACIONES GENERALES</b></td>
                                </tr>
                                <tr>
                                    <td colspan="14" rowSpan={30} >{pdfData.obs_general}</td>
                                </tr>
                            </table>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>







        );
    }
}

export default Listar;