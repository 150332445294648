import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { DefaultLayout } from '../../layout/theme-customizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import tema from "../../services/Plantilla";


class Listar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        usuarios: []
    }
    borrarRegistros = (id) => {
        Swal.fire({
            title: 'Está seguro de Eliminar el Usuario?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Borrar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                authAxios.get("usuario/delusuario/" + id)
                    .then((datosRespuesta) => {
                        
                        Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                        this.ListarUsuarios(1);
                    })
                    .catch();
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };

    LinkEditar(ide) {
        localStorage.setItem('ide', ide);
        
        window.location.href = process.env.PUBLIC_URL + '/pages/EditarUsuario/Dubai';
    }
    LinkCrear() {
        window.location.href = '/pages/AddUsuarios/';
    }
    ListarUsuarios(page) {
        Swal.showLoading();
        let bname = document.getElementById('bnombre').value;
        if (bname == '') {
            bname = 0;
        }
        authAxios.get("usuario/index/" + bname + "?page=" + page)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;

                
                this.setState({ datosCargados: true, load: '', usuarios: datosRespuesta.data.data.data, pagina: datosRespuesta.data.data.total, ultimo: datosRespuesta.data.data.last_page, actual: datosRespuesta.data.data.current_page });
            })
            .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    componentDidMount() {
        this.ListarUsuarios(1);
    }
    render() {
        const { load, usuarios, pagina, ultimo, actual } = this.state;
        return (
            <div className="content">
                <div className="content-wrapper">
                    <div className="content">
                        <div className="container-fluid">
                            <br />
                            <div className="card">

                                <div className="card-body">

                                    <Link className="btn btn-success btn-sm" to={`${process.env.PUBLIC_URL}/pages/AddUsuarios/${tema}`}>&nbsp;&nbsp;Nuevo Usuario</Link>
                                    <h4>Lista Usuarios</h4>
                                    <hr />
                                    <div className="row">
                                        <div className="col-xl-3">
                                            <input style={{ textTransform: 'uppercase' }} type="text" id="bnombre" name="bnombre" placeholder="USUARIO" className="form-control form-control-sm" />
                                        </div>
                                        <div className="col-xl-3">
                                            <button id="bt" name="bt" className="btn btn-primary btn-xs" onClick={() => this.ListarUsuarios(1)}>&nbsp;&nbsp;Filtrar</button>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row table-responsive">
                                        <table className="table table-hover table-condensed table-sm">
                                            <thead className="thead-inverse">
                                                <tr>
                                                    <th className="align-middle">NOMBRE</th>
                                                    <th className="align-middle">IDENTIFICACIÓN</th>
                                                    <th className="align-middle">CORREO ELECTRÓNICO</th>
                                                    <th className="align-middle">CARGO</th>
                                                    <th className="align-middle">ROL</th>
                                                    <th className="align-middle">ESTADO</th>
                                                    <th className="align-middle">OPCIONES</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {load}
                                                {
                                                    usuarios.map((itemUsuarios) => (
                                                        <tr key={itemUsuarios.id}>
                                                            <td>{itemUsuarios.nombre}</td>
                                                            <td>{itemUsuarios.identificacion}</td>
                                                            <td>{itemUsuarios.email}</td>
                                                            <td>{itemUsuarios.cargo}</td>
                                                            <td>{itemUsuarios.rolnombre}</td>
                                                            <td>
                                                                {
                                                                    itemUsuarios.bloqueado === 1 ? (
                                                                        <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-danger">INACTIVO</span>
                                                                    ) : (
                                                                        <span style={{ fontSize: 11, textTransform: 'uppercase' }} className="badge bg-success">ACTIVO</span>
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="btn-group" role="group" aria-label="">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary btn-xs"
                                                                        onClick={() =>
                                                                            this.LinkEditar(itemUsuarios.id)
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger btn-xs"
                                                                        onClick={() =>
                                                                            this.borrarRegistros(itemUsuarios.id)
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination">
                                            <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarUsuarios(1)}> {"<<"} </a></li>
                                            <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarUsuarios(actual - 1)}>{"<"}</a></li>
                                            {(() => {
                                                let p = [];
                                                p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarUsuario(actual)}>{actual}</a></li>);
                                                return p;
                                            })()}
                                            <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarUsuarios(actual + 1)}>{">>"}</a></li>
                                            <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarUsuarios(ultimo)}>{">"} </a></li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="card-footer text-muted">
                                    <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Listar;