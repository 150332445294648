import React, { Fragment, useEffect, useState } from 'react';
import Dashboard from './default';
import DashboardCliente from './defaultCliente';

const Index = () => {
    let a = localStorage.getItem("admin")
    const [Administrator, setAdministrator] = React.useState(a);

        return (
            <div>
                <div className="content">
                    <div className="content-wrapper">
                        {
                        Administrator=='ADMINISTRADOR'?
                        <Dashboard />:<DashboardCliente />
                        }
                    </div>
                </div>
            </div>
        );
    
}

export default Index;