import React from 'react'
import Swal from 'sweetalert2';
import { Link,useNavigate } from "react-router-dom";
import authAxios from "../../services/Token";
import tema from "../../services/Plantilla";
import { DefaultLayout } from '../../layout/theme-customizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

  const defaultLayout = Object.keys(DefaultLayout);
  const layout = defaultLayout

class Editar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        clientes: [],
        roles: [],
        errores: []
    }
    cambioValor = (e) => {
        const state = this.state.clientes;
        state[e.target.name] = e.target.value;
        this.setState({ clientes: state });
    };
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        const tipo = document.getElementById('tipo').value;
        const numero = document.getElementById('numero').value;
        const nombre = document.getElementById('nombre').value;
        const email = document.getElementById('email').value;
        const telefono = document.getElementById('telefono').value;
        const celular = document.getElementById('celular').value;
        const direccion = document.getElementById('direccion').value;
        const codigop = document.getElementById('codigop').value;
        const contrato = document.getElementById('contrato').value;
        const cupo = document.getElementById('cupo').value;
        const finicial = document.getElementById('finicial').value;
        const ffinal = document.getElementById('ffinal').value;
        const estado = document.getElementById('estado').value;
        var errores = [];
        if (!tipo) { errores.push("error_tipo"); }
        if (!numero) { errores.push("error_numero"); }
        if (!nombre) { errores.push("error_nombre"); }
        if (!email) { errores.push("error_email"); }
        if (!telefono) { errores.push("error_telefono"); }
        if (!celular) { errores.push("error_celular"); }
        if (!direccion) { errores.push("error_direccion"); }
        if (!codigop) { errores.push("error_codigop"); }
        if (!contrato) { errores.push("error_contrato"); }
        if (!cupo) { errores.push("error_cupo"); }
        if (!finicial) { errores.push("error_finicial"); }
        if (!ffinal) { errores.push("error_ffinal"); }
        if (!estado) { errores.push("error_estado"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        

        authAxios.post("clientes/editar", formData)
            .then((datosRespuesta) => {
                
                Swal.fire(
                    'Clientes!',
                    datosRespuesta.data.msj,
                    'success'
                )
                this.props.history.push("/MasterClientes");
            })
            .catch()
    }
    componentDidMount() {
        Swal.showLoading();
       

        var ide = localStorage.getItem('ide');
        
        authAxios.get("clientes/ver/"+ide)
            .then((datosRespuesta) => {
                
                Swal.close();
                this.setState({ datosCargados: true, clientes: datosRespuesta.data.data, load: '' });
            })
            .catch();
        
    }
    render() {
        const { clientes,tipo, numero, email, nombre, telefono, celular, direccion, codigop, contrato, cupo, finicial, ffinal, estado, municipio, departamento } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                <b>REGISTRO DE CLIENTES</b>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.enviarDatos}  id="form">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-6">
                                                    <label htmlFor="tipo">Tipo de documentos.....</label>
                                                    <input type="hidden" name="id" id="id"  defaultValue={clientes.id} />
                                                    <select name="tipo" id="tipo" defaultValue={clientes.tipo} className={((this.verificarError("error_tipo")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}> 
                                                        <option value="">Seleccione el T.I.</option>
                                                        <option value="CC">C.C.</option>
                                                        <option value="CE">C.E.</option>
                                                        <option value="NIT">NIT</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="numero">Numero</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_numero")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="numero" id="numero"  defaultValue={clientes.numero} placeholder="digite el numero"  onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback"></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-6">
                                                    <label htmlFor="email">Email</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_email")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="email" id="email" defaultValue={clientes.email} placeholder="ingrese el email" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback"></small>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="nombre">Nombre completo</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="nombre" id="nombre" defaultValue={clientes.nombre} placeholder="digite el nombre" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback"></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-6">
                                                    <label htmlFor="telefono">Telefono</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_telefono")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="telefono" id="telefono" defaultValue={clientes.telefono} placeholder="telefono" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback"></small>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="celular">Celular</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_celular")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="celular" id="celular" defaultValue={clientes.celular} placeholder="digite el celular" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback"></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-6">
                                                    <label htmlFor="direccion">Direccion</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_direccion")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="direccion" id="direccion" defaultValue={clientes.direccion} placeholder="digite direccion" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback"></small>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="codigop">Codigo de seguridad</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_codigop")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="codigop" id="codigop" defaultValue={clientes.codigop} placeholder="codigo de seguridad" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback"></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-6">
                                                    <label htmlFor="contrato">Contrato</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_contrato")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="contrato" id="contrato" defaultValue={clientes.contrato} placeholder="digite el numero de contrato" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback"></small>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="cupo">cupo en pesos</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_cupo")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="cupo" id="cupo" defaultValue={clientes.cupo} placeholder="digite el cupo" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback"></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-6">
                                                    <label htmlFor="finicial">Fecha inicial</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_finicial")) ? "is-invalid" : "") + " form-control form-control-sm"} type="date" name="finicial" id="finicial" defaultValue={clientes.finicial} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback"></small>
                                                </div>
                                                <div className="col-md-6">
                                                     <label htmlFor="ffinal">Fecha final</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_ffinal")) ? "is-invalid" : "") + " form-control form-control-sm"} type="date" name="ffinal" id="ffinal" defaultValue={clientes.ffinal} placeholder="digite el numero" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback"></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'> 
                                                <div className="col-md-6">
                                                    <label htmlFor="estado">Estado</label>
                                                    <select name="estado" id="estado" defaultValue={clientes.estado}
                                                        className={((this.verificarError("error_estado")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el Estado</option>
                                                        <option value="0">Activo</option>
                                                        <option value="1">Inactivo</option>
                                                    </select>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-6">
                                                    <label htmlFor="municipio">municipio</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_finicial")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="municipio" id="municipio" defaultValue={municipio} placeholder="" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback"></small>
                                                </div>
                                                <div className="col-md-6">
                                                     <label htmlFor="departamento">departamento</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_ffinal")) ? "is-invalid" : "") + " form-control form-control-sm"} type="text" name="departamento" id="departamento" defaultValue={departamento} placeholder="digite el numero" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback"></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="submit" className="btn btn-success btn-sm"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Crear Cliente</button>&nbsp;&nbsp;
                                                <Link className="btn btn-danger btn-sm" to={`${process.env.PUBLIC_URL}/clientes/lista/${tema}`}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Editar;