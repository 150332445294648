import React from 'react'
import Swal from 'sweetalert2';
import { Link,useNavigate } from "react-router-dom";
import authAxios from "../../services/Token";
import tema from "../../services/Plantilla";
import { DefaultLayout } from '../../layout/theme-customizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

  const defaultLayout = Object.keys(DefaultLayout);
  const layout = defaultLayout

class Editar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        usuario: [],
        errores: []
    }
    cambioValor = (e) => {
        const state = this.state.usuario;
        state[e.target.name] = e.target.value;
        this.setState({ usuario: state });
    };
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        const estado = document.getElementById('estado').value;
        const nombre = document.getElementById('nombre').value;
   
        var errores = [];

        if (!nombre) { errores.push("error_nombre"); }
        if (!estado) { errores.push("error_estado"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        

        authAxios.post("lineas/editar", formData)
            .then((datosRespuesta) => {
                
                Swal.fire(
          
                    datosRespuesta.data.msj,
                    '',
                    'success'
                )
                
           
                
            })
            .catch()
    }
    componentDidMount() {
        Swal.showLoading();
      

        var ide = localStorage.getItem('ide');
        
        authAxios.get("lineas/ver/"+ide)
            .then((datosRespuesta) => {
                
                Swal.close();
                this.setState({ datosCargados: true, usuario: datosRespuesta.data.data, load: '' });
            })
            .catch();
        
    }
    render() {
        const { load, usuario } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                {load ? (<b>{load}</b>) : (<b>EDITAR LINEA</b>)}
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.enviarDatos} id="form">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                
                                            <input type="hidden" name="id" defaultValue={usuario.id} />
                                                <div className="col-md-3">
                                                    <label htmlFor="nombre">Nombre</label>
                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="nombre" id="nombre" defaultValue={usuario.nombre}
                                                        className={((this.verificarError("error_nombre")) ? "is-invalid" : "") + " form-control form-control-sm"} placeholder="NOMBRE" aria-describedby="helpId"
                                                        onChange={this.cambioValor}
                                                    />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className="col-md-2">
                                                    <label htmlFor="estado">Estado</label>
                                                    <select name="estado"
                                                        id="estado"
                                                        value={usuario.estado}
                                                        options={usuario.estado}
                                                        className={((this.verificarError("error_estado")) ? "is-invalid" : "") + " form-select form-select-sm"}
                                                        onChange={this.cambioValor}>
                                                        <option value="">Seleccione el Estado</option>
                                                        <option value="0">Activo</option>
                                                        <option value="1">Inactivo</option>
                                                    </select>
                                                    <small id="helpId" className="invalid-feedback">Seleccione el Estado</small>
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="submit" className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp; Editar Contrato</button>&nbsp;&nbsp;
                                                <Link className="btn btn-danger btn-sm" to={`${process.env.PUBLIC_URL}/lineas/lista/${tema}`}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Editar;