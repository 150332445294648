import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { DefaultLayout } from '../../layout/theme-customizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import tema from "../../services/Plantilla";
import { Overflow } from '../../constant';
let fechaactual = new Date().toLocaleString().split(",");
let f = fechaactual[0].split("/");
let hoy = f[2].padStart(2, "0") +"-" +f[1].padStart(2, "0") +"-" +f[0].padStart(2, "0");

  let ult =f[2].padStart(2, "0") + "-" +f[1].padStart(2, "0") + "-31";

class Listar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        Pilotos: [],
        HorasConductor: [],
        Dias:[],
        Fi:'',
        Ff:ult,
        Conductor:0,
        Servicio:0,
        ListaServicio:[],
        Detalle:[],
        CargarDetalle:0,
        ServicioId:0,
        pagina:0,
        actual:1,
        ultimo:0,
        Ho:''
    }
   
    
    LinkEditar(ide){
        localStorage.setItem('ide', ide);
        
        window.location.href = process.env.PUBLIC_URL+'/pages/EditarUsuario/'+tema;
    }
    LinkCrear(){
        window.location.href = '/pages/AddUsuarios/';
    }
    Servicios(conductor,ser){
        //Swal.showLoading();
       
        authAxios.get("control/addservicio/"+ser+"/"+conductor)
            .then((datosRespuesta) => {
                //Swal.close();
                var status = datosRespuesta.data.status;
               
                Swal.fire('Asignacion de servicio!', datosRespuesta.data.msj, 'success');
                
                this.ListarServicios(1);
                //this.setState({ CargarDetalle: 1, ServicioId: '',Detalle:datosRespuesta.data.servicio,Fi:datosRespuesta.data.servicio.fecha,Ho:datosRespuesta.data.servicio.hora_inicial});
            })
        .catch();
    }
    VerServicio(id){
        Swal.showLoading();
       
        authAxios.get("control/verservicio/"+id)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
               
                
                this.setState({ CargarDetalle: 1, ServicioId: id,Detalle:datosRespuesta.data.servicio,Fi:datosRespuesta.data.servicio.fecha,Ho:datosRespuesta.data.servicio.hora_inicial});
                this.Lista();
            })
        .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    Lista(){
        Swal.showLoading();
        let bname = document.getElementById('bnombre').value;
    
          let fi = document.getElementById('bfi').value;
        const datos = {nombre:bname,fecha:fi};
          
        authAxios.post("control/tablero",datos)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                
                
                this.setState({  load: '',Pilotos:datosRespuesta.data.pilotos,Dias:datosRespuesta.data.horas,HorasConductor:datosRespuesta.data.pilotos});
            })
        .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    ListarServicios(page){
        //Swal.showLoading();
        let servicio = document.getElementById('bservicio').value;
        const datos = {servicio: servicio};
        authAxios.post("control/serviciosCocluidos?page="+page, datos )
            .then((datosRespuesta) => {
               // Swal.close();
                var status = datosRespuesta.data.status;
                
                
                //this.setState({  ListaServicio: datosRespuesta.data.servicios, pagina: datosRespuesta.data.data.total, ultimo: datosRespuesta.data.data.last_page, actual: datosRespuesta.data.data.current_page });
                this.setState({  ListaServicio: datosRespuesta.data.servicios.data, pagina: datosRespuesta.data.servicios.total, ultimo: datosRespuesta.data.servicios.last_page, actual: datosRespuesta.data.servicios.current_page });
            })
        .catch();
        //window.location.href = '/'; window.localStorage.clear();
    }
    AddServicios(id){
        this.setState({  ServicioId: id});
    }
    componentDidMount() {
        
        this.ListarServicios(1);
    }
    render() {
        const { load, Pilotos, HorasConductor, ServicioId, Dias, CargarDetalle , Fi,ListaServicio,Detalle, pagina, ultimo, actual,Ho} = this.state;
        return (
            <div className="content">
                <div className="container-fluid">
                    <br />
                    <div className="card">
                        <div className='row'>
                        <div className='col-xl-8'>
                             <div className="card-body">
                                 <h4>Lista de Servicios Concluidos</h4>
                                  <hr />
                            
                            <div className="row">
                                <div className="col-xl-3">
                                    <input style={{textTransform: 'uppercase'}} type="text" id="bservicio" name="bservicio" placeholder="Buscar Servicio" className="form-control form-control-sm" />
                                </div>
                               
                                <div className="col-xl-3">
                                    <button id="bt2" name="bt2" className="btn btn-primary" onClick={() => this.ListarServicios(1)}>&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br> 
                            <div className="row table-responsive" style={{whiteSpace:'nowrap',Overflow:'auto'}}>
                            <nav aria-label="Page navigation example float-right">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                    {(() => {
                                        let p = [];
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarRol(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                </ul>
                            </nav>
                                <table className="table-bordered">
                                    <thead className="bg-secondary">
                                          <th className="align-middle">Numero Expediente</th>
                                          <th className="align-middle">Fecha Servicio</th>
                                          <th className="align-middle">Hora</th>
                                          <th className="align-middle">Direccion</th>
                                          <th className="align-middle">Ver</th>
                                    </thead>
                                    <tbody>
                                    {
                                             ListaServicio.map((item,g) => (
                                                <tr key={g} >
                                                    <td  className="align-middle"> {item.numero_expediente}</td>      
                                                    <td  className="align-middle">{item.fecha}</td>   
                                                    <td  className="align-middle">{item.hora_inicial}</td>  
                                                    <td  className="align-middle">{item.direccion_inicial}</td>  
                                                    <td  className="align-middle"><span className='btn btn-info'  onClick={()=>this.VerServicio(item.id)}>[+]</span> </td>  
                                                    </tr> 
                                                                                                  
                                            ))
                                           }
                                    </tbody>
                               </table>
                               <nav aria-label="Page navigation example float-right">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                    {(() => {
                                        let p = [];
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarRol(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarServicios(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                </ul>
                            </nav>
                            Total de Registros : {pagina}
                            </div>
                            </div>
                         </div>

                            <div className='col-xl-4'>
                            <div className="card-body">
                            <h4>Detalle del servicio {Detalle.id}</h4>
                            <hr />
                            
                            <div className="row" >
                                <div className="card">
                                     <div className="card-body">
                                     Numero de Expediente: {Detalle.numero_expediente}
                                     </div>
                                </div>

                            <table className="table-bordered">
                                   
                                        <tr>
                                            <td className="align-right">Nombre del cliente</td>
                                            <td className="text-justify" > {Detalle.nombre_cliente} </td>
                                        </tr>
                                        <tr>
                                            <td className="align-right">Correo Electronico</td>
                                            <td className="text-justify" > {Detalle.email_cliente}   </td>
                                        </tr>
                                        <tr>
                                            <td className="align-right">Fecha de Servicio</td>
                                            <td className="text-justify" > {Detalle.fecha} </td>
                                        </tr>
                                        <tr>
                                            <td className="align-right">Hora de Servicio</td>
                                            <td className="text-justify" >  {Detalle.hora_inicial}  </td>
                                        </tr>
                                        <tr>
                                            <td className="align-right">Telefono del contacto</td>
                                            <td className="text-justify" > {Detalle.numero_contacto}   </td>
                                        </tr>
                                        <tr>
                                            <td className="align-right">Direccion Inicial</td>
                                            <td className="text-justify" > {Detalle.direccion_inicial}   </td>
                                        </tr>
                                        <tr>
                                            <td className="align-right">Direccion destino</td>
                                            <td className="text-justify" > {Detalle.direccion_final}   </td>
                                        </tr>
                                        <tr>
                                            <td className="align-right">Placa</td>
                                            <td className="text-justify" > {Detalle.placa}   </td>
                                        </tr>

                                        
                                        <tr>
                                            <td className="align-right">Observaciones</td>
                                            <td className="text-justify" > {Detalle.observaciones}   </td>
                                        </tr>
                                        
                                        <tr>
                                            <td className="align-right">Estado del Servicio</td>
                                            <td className="text-justify" > {Detalle.estado_servicio}   </td>
                                        </tr>
                                      
                                       
                                    
                                  
                                </table>
                            </div>
                            {CargarDetalle=='1'?
                               <button type="button" className="btn btn-secondary btn-lg btn-block" data-bs-toggle='modal'  data-bs-target="#ModalServicio"  onClick={()=>this.AddServicios(Detalle.id)}>Asignar</button>
                            :''}
                          
                        </div>
                            </div>
                            
                        </div>
                        
                       
                    </div>
                </div>
                <div className="modal fade" id="ModalServicio" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="nuevoPacienteSubLabel" aria-hidden="true">
                    <div className={"modal-dialog  modal-xl"} role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="permisoRolesModalLabel">Asignacion de conductor al Servicio {Detalle.numero_expediente}</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            <div className="row">
                                 
                                <div className="col-xl-3">
                                <label>Piloto</label>
                                    <input style={{textTransform: 'uppercase'}} type="text" id="bnombre" name="bnombre" placeholder="Buscar Piloto" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                <label>Fecha de servicio</label>
                                    <input style={{textTransform: 'uppercase'}} type="date" id="bfi" name="bfi" defaultValue={Fi} className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                    <label>Hora de servicio</label>
                                    <input style={{textTransform: 'uppercase'}} type="time" id="bho" name="bho" defaultValue={Ho} className="form-control form-control-sm" />
                                </div>
                               
                                <div className="col-xl-3">
                                    <button id="bt" name="bt" className="btn btn-primary" onClick={() => this.Lista()}>&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br>
                            <div className="row table-responsive" style={{whiteSpace:'nowrap',Overflow:'auto'}}>
                            <table className="table-bordered">
                                    <thead className="bg-secondary">
                                        
                                       
                                        <tr>
                                            <th className="align-middle">ITEM</th>
                                            <th className="text-justify" >CONDUCTOR</th>
                                      
                                          
                                           {
                                             Dias.map((item,g) => (

                                                    <th key={g}  className="align-middle">{item}</th>                                                    
                                            ))
                                           }
                                            
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {
                                            Pilotos.map((row,i) => (
                                                <tr key={i} >
                                                    <td className="align-middle"> <span  data-bs-toggle='modal' data-bs-target="#ModalServicio" onClick={()=>this.Servicios(row.id,ServicioId)}>[+]</span> {row.id}</td>
                                                    <td className="text-justify">{row.nombre} {row.apellidos} </td>
                                                 
                                                     
                                                     {
                                                        Dias.map((it,h) => (
                                                          
                                                                <td key={h} >
                                                                
                                                                    <table  className={"align-middle"} >
                                                                        <tr>
                                                                            <td className={
                                                                                row.pintar_hora[h].seleccionar=='success'?
                                                                                     row.pintar_hora[h].minuto<=0?
                                                                                     'bg-success border border-dark':'bg-light border border-dark':
                                                                                     row.pintar_hora[h].seleccionar_fin=='success'?
                                                                                         row.pintar_hora[h].minuto>0?
                                                                                        'bg-success border border-dark':'bg-light border border-dark':
                                                                                    'bg-light border border-dark'
                                                                            } title={it+':00'}>..</td>
                                                                            <td className={
                                                                                row.pintar_hora[h].seleccionar=='success'?
                                                                                row.pintar_hora[h].minuto<=15?
                                                                                'bg-success border border-dark':'bg-light border border-dark':
                                                                                row.pintar_hora[h].seleccionar_fin=='success'?
                                                                                         row.pintar_hora[h].minuto>15?
                                                                                        'bg-success border border-dark':'bg-light border border-dark':
                                                                                    'bg-light border border-dark'
                                                                            } title={it+':15'}>..</td>
                                                                            <td className={
                                                                                row.pintar_hora[h].seleccionar=='success'?
                                                                                row.pintar_hora[h].minuto<=30?
                                                                                'bg-success border border-dark':'bg-light border border-dark':
                                                                                row.pintar_hora[h].seleccionar_fin=='success'?
                                                                                         row.pintar_hora[h].minuto>30?
                                                                                        'bg-success border border-dark':'bg-light border border-dark':
                                                                                    'bg-light border border-dark'
                                                                            } title={it+':30'}>..</td>
                                                                            <td className={
                                                                                row.pintar_hora[h].seleccionar=='success'?
                                                                                row.pintar_hora[h].minuto<=45?
                                                                                'bg-success border border-dark':'bg-light border border-dark':
                                                                                row.pintar_hora[h].seleccionar_fin=='success'?
                                                                                         row.pintar_hora[h].minuto>45?
                                                                                        'bg-success border border-dark':'bg-light border border-dark':
                                                                                    'bg-light border border-dark'
                                                                            } title={it+':45'}>..</td>
 
                                                                        </tr>
                                                                    </table>
                                                                </td>                                                    
                                                        ))
                                                    }
                                                  
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default Listar;